import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Badge, IconButton, Slide } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import { Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { WithStyles, WithTheme } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Alerts, { AlertScope } from 'components/alert/Alerts';
import MyFavouritesComponent from 'components/favourites/MyFavouritesComponent';
import FeatureToggle from 'components/feature/FeatureToggle';
import { InitializationPhase } from 'components/initialization';
import InitialisationAssurance from 'components/initialization/InitialisationAssurance';
import TradeTableComponent from 'components/marketplace/tradetable/TradeTableComponent';
import ConfirmationDialog from 'components/messaging/chat/messagefactory/infocards/shared/ConfirmationDialog';
import NearbuySnackbarProvider from 'components/notification/NearbuySnackbarProvider';
import NotificationAlert from 'components/notification/NotificationAlert';
import OrderComponent from 'components/order/OrderComponent';
import LocalisationProviderWrapper from 'components/routing/LocalisationProviderWrapper';
import ToolbarButton from 'components/routing/ToolbarButton';
import UpdateManager from 'components/shared/InstantSave/FormUpdateManager';
import { NearbuyLoadingIndicator } from 'components/shared/NearbuyLoadingIndicator';
import SystemMessageComponent from 'components/systemmessage/SystemMessageComponent';
import SystemMessageSnackbarComponent from 'components/systemmessage/SystemMessageSnackbarComponent';
import LanguageSwitcher from 'i18n/LanguageSwitcher';
import { AddressType } from 'model/Address';
import { Company } from 'model/Company';
import { Feature } from 'model/Feature';
import { ImageInfo } from 'model/Image';
import { Membership } from 'model/Membership';
import { MessageTermsStatus } from 'model/Person';
import { SearchArea } from 'model/SearchArea';
import { Verification } from 'model/Verification';
import React, { ReactElement, Suspense } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { ComponentBase } from 'resub';
import {
    AuthStore,
    CompanyMembershipsStore,
    CompanyRolesStore,
    CompanyStore,
    MembershipStore,
    MessagesUnseenStore,
    PersonImagesStore,
    PersonStore,
} from 'store';
import FeatureStore from 'store/FeatureStore';
import NavStore from 'store/NavStore';
import UserStore from 'store/UserStore';
import { getFlavouredPublicImport } from 'style/flavour-helper';
import { getUuidFromString, isUserManager } from 'util/helpers';
import { CompanyRoles } from 'model/NearbuyRole';
import CurrentCompanySwitch from 'components/company/CurrentCompanySwitch';
import NewCompanyPresentation from 'components/company/view/CompanyPresentationComponent';
import AcceptInvitation from 'components/invitation/AcceptInvitation';
import DeclineInvitation from 'components/invitation/DeclineInvitation';
import MessageComponent from 'components/messaging/MessageComponent';
import Registration from 'components/registration/Registration';
import { LoadingIndicator } from 'components/shared/LoadingIndicator';
import Placeholder from 'components/shared/Placeholder';
import { PostHogProvider } from 'posthog-js/react';
import posthog, { PostHogConfig } from 'posthog-js';
import { captureWebEvent } from 'util/AnalyticUtils';
import RecurringOrderTableComponent from 'components/marketplace/recurring-order/RecurringOrderTableComponent';

const Dashboard = React.lazy(() => import('components/dashboard/Dashboard'));
const ResultList = React.lazy(() => import('components/search/ResultList'));
const CompanyEditComponent = React.lazy(() => import('components/company/edit/CompanyEditComponent'));
const ProfileNavComponent = React.lazy(() => import('components/profile/ProfileNavComponent'));
const CompanyComponent = React.lazy(() => import('components/company/view/CompanyComponent'));
const MarketPlaceComponent = React.lazy(() => import('components/marketplace/MarketPlaceComponent'));
const SubscriptionNavComponent = React.lazy(() => import('components/subscription/SubscriptionNavComponent'));
const SubscriptionComponent = React.lazy(() => import('components/subscription/SubscriptionComponent'));
const AdminRouter = React.lazy(() => import('components/admin/AdminRouter'));

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'start',
            minHeight: '100vh',
        },
        primaryAppBar: {
            zIndex: theme.zIndex.drawer + 2,
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 5px 8px 0px rgba(0, 0, 0, 0.07)',
        },
        secondaryAppBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.background.default,
            top: '64px',
            paddingLeft: '2%',
            boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.1)',
        },
        primaryToolBar: {
            padding: 0,
            paddingLeft: '16px',
            margin: 0,
            display: 'flex',
            flexWrap: 'nowrap',
        },
        secondaryToolBar: {
            display: 'flex',
            gap: '2%',
            paddingLeft: '100px',
            paddingTop: '3',
        },
        searchBarContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginLeft: '3%',
            gap: '1%',
        },
        toolbar: theme.mixins.toolbar,
        drawer: {
            width: 240,
            flexShrink: 0,
            transition: 'width 0.3s ease',
        },
        drawerPaper: {
            padding: theme.spacing(2),
            width: 240,
            flexShrink: 0,
            transition: 'width 0.3s ease',
        },
        '@media (max-width: 1200px)': {
            drawer: {
                maxWidth: '20%',
            },
            drawerPaper: {
                maxWidth: '20%',
            },
            secondaryToolBar: {
                paddingLeft: '0',
            },
        },
        '@media (min-width: 1200px, max-width: 1600px)': {
            secondaryToolBar: {
                paddingLeft: '24%',
            },
        },
        '@media (min-width: 1400px)': {
            drawer: {
                minWidth: '8%',
            },
            drawerPaper: {
                minWidth: '8%',
            },
        },
        '@media (min-width: 1600px)': {
            secondaryToolBar: {
                paddingLeft: '7%',
            },
        },
        '@media (min-width: 2000px)': {
            secondaryToolBar: {
                paddingLeft: '6%',
            },
        },
        '@media (min-width: 2700px)': {
            secondaryToolBar: {
                paddingLeft: '5.2%',
            },
        },
        '@media (min-width: 3800px)': {
            secondaryToolBar: {
                paddingLeft: '4.2%',
            },
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(4),
            paddingTop: theme.spacing(4),
            width: '100%',
            minWidth: '50%',
        },
        logo: {
            minHeight: 30,
            maxHeight: 50,
            maxWidth: 125,
            minWidth: '125px',
            padding: theme.spacing(0.5),
            display: 'block',
        },
        logoButton: {
            marginLeft: '.5%',
            padding: 0,
        },
        rightSideButtons: {
            marginRight: '.5%',
            display: 'flex',
            flexWrap: 'nowrap',
        },
        center: {
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
        },
        rightSideButtonColor: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.primary,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.dark,
            },
        },
        buttonPadding: {
            padding: 0,
            flexWrap: 'nowrap',
            margin: theme.spacing(0.5),
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        powerButton: {
            backgroundColor: 'transparent',
            color: theme.palette.primary.dark,
            border: '2px solid',
            borderColor: theme.palette.primary.dark,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.dark,
            },
        },
    });

export interface RouterProps extends WithStyles<typeof styles>, WithTheme, WithTranslation {}

interface RouterState {
    showDrawer: boolean;
    drawerContent?: React.ReactNode;
    userExists?: boolean;
    currentRoute: string;
    personPortrait?: ImageInfo;
    unreadMessagesCount?: number;
    hideMessagesUnseenBadge: boolean;
    ownCompany?: Company;
    userId?: string;
    isTrackingEnabled?: boolean;
    canAccessMarket: boolean;
    showSubscription: boolean;
    showNewCompanyPresentation: boolean;
    isSecondaryToolBarOpen: boolean;
    isManager: boolean;
    isLeavePrevented: boolean;
    memberships: Membership[];
    saveLeavePreventionTitle: string;
    saveLeavePreventionContent: string;
    backToButtonText: string;
    areMessageTermsPending: boolean;
    roles?: CompanyRoles;
}

class NearbuyRouter extends ComponentBase<RouterProps, RouterState> {
    protected _buildState(
        props: RouterProps,
        initialBuild: boolean,
        incomingState?: Readonly<RouterState>,
    ): Partial<RouterState> | undefined {
        const authId = AuthStore.getId();
        const user = PersonStore.getSelected();
        const company = CompanyStore.getSelected();
        const userId = getUuidFromString(user?.links.self);
        const companyRoles = company && CompanyRolesStore.getOne(company.links.self);

        if (userId && user?.trackingEnabled) {
            posthog.identify(user?.email, {
                userEmail: user?.email,
                autocapture: true,
                companyName: company?.name,
                companyId: company?.id,
            });
        }

        return {
            roles: companyRoles,
            areMessageTermsPending: user?.messageTermsStatus === MessageTermsStatus.PENDING,
            showDrawer: NavStore.isShowDrawer(),
            drawerContent: NavStore.getDrawerContent(),
            userExists: UserStore.isUserExisting(),
            currentRoute: NavStore.getCurrentRoute(),
            personPortrait: authId ? PersonImagesStore.getOne(authId)?.getLatestImage() || undefined : undefined,
            unreadMessagesCount: company
                ? MessagesUnseenStore.getOne(company.links.self)?.messagesUnseenCount
                : undefined,
            hideMessagesUnseenBadge: company
                ? MessagesUnseenStore.getOne(company.links.self)?.hideMessagesUnseenBadge
                : undefined,
            ownCompany: company,
            userId: userId,
            isTrackingEnabled: user?.trackingEnabled,
            canAccessMarket: FeatureStore.hasPermission(
                new Set([Feature.SEARCH_OFFERS_AND_REQUESTS, Feature.BE_ADMIN]),
                'oneOf',
            ),
            showSubscription: process.env.REACT_APP_NEARBUY_FEATURE_SHOW_SUBSCRIPTION === 'true',
            showNewCompanyPresentation: process.env.REACT_APP_NEARBUY_FEATURE_SHOW_NEW_COMPANY_PRESENTATION === 'true',
            memberships:
                company && company.links.self
                    ? (CompanyMembershipsStore.getOne(company.links.self)
                          ?.memberships.map((membershipRef: string) => {
                              const membership = MembershipStore.getOne(membershipRef);
                              if (membership) {
                                  return membership;
                              }
                          })
                          .filter((element) => element !== undefined) as Readonly<Membership>[])
                    : [],
            isManager: isUserManager(),
        };
    }

    logout(): void {
        const adapter = AuthStore.getKeycloakAdapter();
        posthog.reset();
        // Logout user and redirect to dashboard on next login
        const dashboardUri =
            window.location.pathname != '/'
                ? window.location.href.replace(window.location.pathname, '')
                : window.location.href;
        adapter?.logout({ redirectUri: dashboardUri });
        posthog.reset(true);
    }

    getProfileImage(): ReactElement {
        if (this.state.personPortrait) {
            return <Avatar src={this.state.personPortrait.source} />;
        }
        return (
            <Avatar className={this.props.classes.rightSideButtonColor}>
                <PersonIcon />
            </Avatar>
        );
    }

    getLogo(): ReactElement {
        if (this.state.currentRoute === '/registration') {
            return (
                <div className={this.props.classes.logoButton}>
                    <img
                        src={'/logo/' + getFlavouredPublicImport() + '.svg'}
                        className={this.props.classes.logo}
                        alt={'logo'}
                    />
                </div>
            );
        } else {
            return (
                <Button
                    className={this.props.classes.logoButton}
                    component={Link}
                    onClick={() => captureWebEvent('nearbuy-logo')}
                    to="/"
                >
                    <img
                        src={'/logo/' + getFlavouredPublicImport() + '.svg'}
                        className={this.props.classes.logo}
                        alt={'logo'}
                    />
                </Button>
            );
        }
    }

    enableCompanySwitch(): React.ReactElement | null {
        if ('true' === process.env.REACT_APP_NEARBUY_FEATURE_COMPANYTOGGLE) {
            return (
                <Placeholder>
                    <CurrentCompanySwitch />
                </Placeholder>
            );
        } else {
            return null;
        }
    }

    getPrimaryToolBar(): ReactElement {
        return (
            <AppBar position="fixed" className={this.props.classes.primaryAppBar}>
                <Toolbar className={this.props.classes.primaryToolBar}>
                    {this.getLogo()}
                    {this.state.currentRoute !== '/registration' && (
                        <Box className={this.props.classes.searchBarContainer}>
                            <InitialisationAssurance phase={InitializationPhase.AUTHORIZED}>
                                <>
                                    {this.state.ownCompany && (
                                        <ToolbarButton
                                            label={this.props.t('dashboard:my_nearbuy')}
                                            route={'/'}
                                            onClick={() => {
                                                captureWebEvent('toolbar-my_nearbuy-button');
                                            }}
                                            onMouseEnter={() => {
                                                captureWebEvent('toolbar-my_nearbuy-button');
                                                this.setState({
                                                    isSecondaryToolBarOpen: true,
                                                });
                                            }}
                                        />
                                    )}
                                    <ToolbarButton
                                        label={this.props.t('search:companies')}
                                        route={'/results/companies'}
                                        onClick={() => {
                                            captureWebEvent('toolbar-company-button');
                                            this.setState({
                                                isSecondaryToolBarOpen: false,
                                            });
                                        }}
                                    />
                                    <ToolbarButton
                                        label={this.props.t('search:marketplace')}
                                        route={
                                            this.state.ownCompany?.verified
                                                ? this.state.canAccessMarket
                                                    ? '/results/marketplace'
                                                    : '/subscription'
                                                : '/'
                                        }
                                        disabled={!this.state.ownCompany?.verified}
                                        onClick={() => {
                                            captureWebEvent('toolbar-marketplace-button');
                                            this.setState({
                                                isSecondaryToolBarOpen: false,
                                            });
                                        }}
                                    />
                                </>
                            </InitialisationAssurance>
                        </Box>
                    )}
                    {this.state.userExists && (
                        <Box className={this.props.classes.rightSideButtons}>
                            <InitialisationAssurance phase={InitializationPhase.AUTHORIZED}>
                                {this.enableCompanySwitch()}
                            </InitialisationAssurance>
                            <FeatureToggle requiredFeatures={[Feature.USE_MESSAGING]} type={'allOf'}>
                                <IconButton
                                    component={Link}
                                    to={'/messages'}
                                    onClick={() =>
                                        this.state.unreadMessagesCount
                                            ? captureWebEvent('toolbar-message-button-with-unread-message')
                                            : captureWebEvent('toolbar-message-button')
                                    }
                                    className={this.props.classes.buttonPadding}
                                >
                                    <Badge
                                        color="error"
                                        badgeContent={
                                            this.state.areMessageTermsPending ? (
                                                <Typography fontWeight={'bold'} fontSize={13}>
                                                    {'!'}
                                                </Typography>
                                            ) : this.state.hideMessagesUnseenBadge ? undefined : (
                                                this.state.unreadMessagesCount
                                            )
                                        }
                                        overlap="circular"
                                    >
                                        <Avatar className={this.props.classes.rightSideButtonColor}>
                                            <ChatBubbleIcon />
                                        </Avatar>
                                    </Badge>
                                </IconButton>
                            </FeatureToggle>
                            <SystemMessageComponent key={this.state.currentRoute} />
                            <IconButton
                                component={Link}
                                to="/profile/detail"
                                className={this.props.classes.buttonPadding}
                            >
                                {this.getProfileImage()}
                            </IconButton>
                            <IconButton
                                data-testid={'logout'}
                                onClick={() => {
                                    captureWebEvent('logout-button');
                                    this.logoutSaveLeavePrevention();
                                }}
                                className={this.props.classes.buttonPadding}
                            >
                                <Avatar className={this.props.classes.powerButton}>
                                    <PowerSettingsNewIcon fontSize="medium" />
                                </Avatar>
                            </IconButton>
                            <InitialisationAssurance phase={InitializationPhase.AUTHORIZED}>
                                <LanguageSwitcher />
                            </InitialisationAssurance>
                        </Box>
                    )}
                </Toolbar>
            </AppBar>
        );
    }

    getContent(): ReactElement {
        if (!NavStore.isCurrentRoutePublic() && this.state.userExists === undefined) {
            return (
                <div className={this.props.classes.center}>
                    <LoadingIndicator />
                </div>
            );
        } else if (
            !this.state.userExists &&
            this.state.currentRoute !== '/registration' &&
            !NavStore.isCurrentRoutePublic()
        ) {
            return <Redirect to="/registration" />;
        } else {
            return (
                <>
                    <div className={this.props.classes.toolbar} />
                    <Alerts
                        alertScope={
                            window.location.pathname.includes('/messages')
                                ? AlertScope.MESSAGING
                                : window.location.pathname.includes('/edit/company')
                                  ? AlertScope.COMPANY
                                  : AlertScope.GLOBAL
                        }
                    />
                    <NotificationAlert />
                    <Switch>
                        <Route path="/registration" component={Registration} />
                        <Route path="/invitation/accept" component={AcceptInvitation} />
                        <Route path="/invitation/decline" component={DeclineInvitation} />
                        <InitialisationAssurance phase={InitializationPhase.AUTHORIZED}>
                            <LocalisationProviderWrapper>
                                <Suspense fallback={<NearbuyLoadingIndicator />}>
                                    <SystemMessageSnackbarComponent />
                                    <Route path="/" exact component={Dashboard} />
                                    <FeatureToggle
                                        requiredFeatures={[Feature.SEARCH_INTERNAL_COMPANIES, Feature.BE_ADMIN]}
                                        type="oneOf"
                                    >
                                        <Route
                                            path="/results/companies"
                                            render={(props) => (
                                                <ResultList {...props} searchArea={SearchArea.COMPANIES} />
                                            )}
                                        />
                                        <FeatureToggle
                                            requiredFeatures={[Feature.SEARCH_OFFERS_AND_REQUESTS, Feature.BE_ADMIN]}
                                            type="oneOf"
                                        >
                                            <Route
                                                path="/results/marketplace"
                                                render={(props) => (
                                                    <ResultList {...props} searchArea={SearchArea.MARKETPLACE} />
                                                )}
                                            />
                                        </FeatureToggle>
                                    </FeatureToggle>
                                    <Route path="/edit/company/:companyId" component={CompanyEditComponent} />
                                    <Route path="/profile" component={ProfileNavComponent} />
                                    <Route path="/company/:companyId" component={CompanyComponent} />
                                    <Route path="/marketplace/:companyId" component={MarketPlaceComponent} />
                                    <Route path="/admin" component={AdminRouter} />
                                    <Route path="/myFavourites" component={MyFavouritesComponent} />
                                    <Route path={'/messages/:formerEmployment?'} component={MessageComponent} />
                                    {this.state.showSubscription && (
                                        <>
                                            <Route path="/subscription" component={SubscriptionNavComponent} />
                                            <Route path="/subscribe" component={SubscriptionComponent} />
                                        </>
                                    )}
                                    <FeatureToggle requiredFeatures={[Feature.CAN_BUY_AND_SELL]} type={'allOf'}>
                                        <Route path="/order" component={OrderComponent} />
                                        <Route
                                            path="/marketplace/:companyId/tradeTable"
                                            component={TradeTableComponent}
                                        />
                                        <Route
                                            path="/marketplace/:companyId/recurringOrderTable/receivedRecurringOrders"
                                            component={RecurringOrderTableComponent}
                                        />
                                        <Route
                                            path="/marketplace/:companyId/recurringOrderTable/sentRecurringOrders"
                                            component={RecurringOrderTableComponent}
                                        />
                                    </FeatureToggle>
                                    {this.state.showNewCompanyPresentation && (
                                        <Route
                                            path="/company-presentation/:companyId"
                                            component={NewCompanyPresentation}
                                        />
                                    )}
                                </Suspense>
                            </LocalisationProviderWrapper>
                        </InitialisationAssurance>
                        <Redirect to="/" />
                    </Switch>
                </>
            );
        }
    }

    getSecondaryToolBar() {
        return (
            <Slide direction={'down'} in={this.state.isSecondaryToolBarOpen} mountOnEnter unmountOnExit>
                <AppBar position="fixed" className={this.props.classes.secondaryAppBar}>
                    <Toolbar
                        onMouseLeave={() => {
                            this.setState({
                                isSecondaryToolBarOpen: false,
                            });
                        }}
                        className={this.props.classes.secondaryToolBar}
                    >
                        <ToolbarButton
                            label={this.props.t('dashboard:my_company')}
                            route={`/edit/company/${this.state.ownCompany?.id}/detail`}
                            onClick={() => {
                                captureWebEvent('toolbar-my_company-button');
                                this.setState({
                                    isSecondaryToolBarOpen: false,
                                });
                            }}
                        />
                        <FeatureToggle requiredFeatures={[Feature.CAN_BUY_AND_SELL]} type={'allOf'}>
                            <ToolbarButton
                                label={this.props.t('dashboard:my_marketplace')}
                                route={`/marketplace/${this.state.ownCompany?.id}`}
                                onClick={() => {
                                    captureWebEvent('toolbar-my_marketplace-button');
                                    this.setState({
                                        isSecondaryToolBarOpen: false,
                                    });
                                }}
                            />
                        </FeatureToggle>
                        {process.env.REACT_APP_NEARBUY_FEATURE_SHOW_SUBSCRIPTION === 'true' &&
                            (this.state.isManager ? (
                                <ToolbarButton
                                    label={this.props.t('dashboard:my_subscription')}
                                    route={'/subscription'}
                                    onClick={() => {
                                        captureWebEvent('toolbar-my_subscription-button');
                                        this.setState({
                                            isSecondaryToolBarOpen: false,
                                        });
                                    }}
                                />
                            ) : null)}
                    </Toolbar>
                </AppBar>
            </Slide>
        );
    }

    checkIfCompanyEditViewHasUpdates(company: Company) {
        return (
            UpdateManager.hasChanges(this.state.ownCompany) ||
            //  only interested in verificationFile, if verificationType needs a file
            UpdateManager.hasChanges<Verification>(company.id + '_verification') ||
            UpdateManager.hasChanges(company.links.self + 'roles') ||
            UpdateManager.hasChanges(company.links.self + 'addresses', AddressType.INVOICE) ||
            UpdateManager.hasChanges(company.links.self + 'addresses', AddressType.DELIVERY) ||
            UpdateManager.hasChanges(company.links.self + 'addresses' + AddressType.MAIN) ||
            this.state.memberships.some(
                (membership) =>
                    UpdateManager.hasChanges(membership.id) || UpdateManager.hasChanges(membership.id + 'links'),
            )
        );
    }

    logoutSaveLeavePrevention(): void {
        if (
            this.state.ownCompany &&
            location.pathname.includes('edit/company') &&
            this.checkIfCompanyEditViewHasUpdates(this.state.ownCompany)
        )
            this.setState({
                isLeavePrevented: true,
                saveLeavePreventionTitle: this.props.t('dialogs:savePreventionDialogHeading'),
                saveLeavePreventionContent: this.props.t('dialogs:savePreventionDialogContentWithoutSaving'),
                backToButtonText: this.props.t('dialogs:backToEdit'),
            });
        else if (location.pathname.includes('/order'))
            this.setState({
                isLeavePrevented: true,
                saveLeavePreventionTitle: this.props.t('payment:leaveOrderPreventionDialogHeading'),
                saveLeavePreventionContent: this.props.t('payment:leaveOrderPreventionDialogContent'),
                backToButtonText: this.props.t('payment:backToOrder'),
            });
        else this.logout();
    }

    discardCompanyEditView(): void {
        UpdateManager.clearUpdates();
        this.logout();
    }

    onClose(): void {
        this.setState({
            isLeavePrevented: false,
        });
    }

    showSaveLeavePrevention(): React.ReactFragment {
        return (
            <ConfirmationDialog
                title={this.state.saveLeavePreventionTitle}
                content={this.state.saveLeavePreventionContent}
                defaultButtonText={this.state.backToButtonText}
                isOpen={this.state.isLeavePrevented}
                onClose={() => {
                    this.onClose();
                }}
                buttonText1={this.props.t('company:logout')}
                buttonAction1={() => this.discardCompanyEditView()}
                calledFrom={'NearbuyRouter'}
            />
        );
    }

    withPostHogProvider(mainContent: React.ReactNode, options?: Partial<PostHogConfig> | undefined): React.ReactNode {
        const postHogApiKey = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;
        const posthogOptions = {
            ...{
                api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
            },
            ...options,
        };

        return (
            <PostHogProvider apiKey={postHogApiKey} options={posthogOptions}>
                {mainContent}
            </PostHogProvider>
        );
    }
    render() {
        const mainContent = (
            <>
                <CssBaseline />
                {this.getPrimaryToolBar()}
                {this.getSecondaryToolBar()}
                {this.showSaveLeavePrevention()}
                {this.state.showDrawer && (
                    <Drawer
                        className={this.props.classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: this.props.classes.drawerPaper,
                        }}
                        anchor="left"
                    >
                        <div className={this.props.classes.toolbar} />
                        {this.state.drawerContent}
                    </Drawer>
                )}
                <main className={this.props.classes.content}>
                    <NearbuySnackbarProvider>{this.getContent()}</NearbuySnackbarProvider>
                </main>
            </>
        );
        const isEnablePostHog = process.env.REACT_APP_ENABLE_POSTHOG === 'true';

        return (
            <div className={this.props.classes.root}>
                {isEnablePostHog
                    ? this.withPostHogProvider(mainContent, {
                          persistence: this.state.isTrackingEnabled ? 'localStorage+cookie' : 'memory',
                      })
                    : mainContent}
            </div>
        );
    }
}

export default withTranslation(['search', 'dialogs', 'dashboard', 'payment', 'company'])(
    withStyles(styles, { withTheme: true })(NearbuyRouter),
);
