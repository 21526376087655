import { OfferDialogProps, OfferDialogState } from 'components/marketplace/offers/dialog/OfferDialog';
import OfferDialogHelpers from 'components/marketplace/offers/dialog/OfferDialogHelpers';
import { structure } from 'components/productdatasheet/ProductDataSheetStructure';
import OfferService from 'api/OfferService';
import { Offer } from 'model/Offer';
import { OfferStore } from 'store';

export default class OfferDialogHandlers {
    constructor(
        private props: OfferDialogProps,
        private state: OfferDialogState,
        private setState: <K extends keyof OfferDialogState>(
            state:
                | ((
                      prevState: Readonly<OfferDialogState>,
                      props: Readonly<OfferDialogProps>,
                  ) => Pick<OfferDialogState, K> | OfferDialogState | null) // Removed OfferDialogProps here
                | Pick<OfferDialogState, K>
                | OfferDialogState
                | null,
            callback?: () => void,
        ) => void,
        private helpers: OfferDialogHelpers,
    ) {}

    handleSaveClick() {
        if (this.helpers.hasChanges()) {
            if (this.helpers.hasOfferFormErrors()) {
                this.setState({ showAllFormErrors: true });
            } else {
                this.handleSave();
            }
        }
    }

    handleSave() {
        const afterSave = (offerRef?: string) => {
            if (offerRef) {
                if (this.props.isOfferProvided && this.props.offerRef === null) {
                    OfferStore.setSelected(offerRef);
                }
            }
            this.props.onClose?.();
            this.setState({
                isSaveLeavePreventionOpen: false,
            });
        };

        if (!this.state.offerWriteView) return;

        const offerWriteView = {
            ...this.state.offerWriteView,
            pricePerUnit: this.state.offerWriteView.pricePerUnit === 0 ? null : this.state.offerWriteView.pricePerUnit,
        };

        if (this.state.offer) {
            OfferService.updateOffer(offerWriteView, this.state.offer.id, this.props.companyRef).subscribe(
                (offer: Offer) => afterSave(offer.links.self),
            );
        } else {
            OfferService.addOffer(offerWriteView, this.props.companyRef).subscribe((offer: Offer) =>
                afterSave(offer.links.self),
            );
        }
    }

    onDialogClose = (event: any, reason: string) => {
        if (event && reason === 'backdropClick') {
            this.onClose();
        }
    };

    onClose = () => {
        if (this.helpers.hasChanges()) {
            this.setState({
                isSaveLeavePreventionOpen: true,
            });
        } else {
            this.props.onClose?.();
        }
    };

    onContinueButtonClick = () => {
        this.setState(
            (prevState) => {
                const activeDataBlock =
                    prevState.activeDataStep + 1 >= structure.dataBlocks[prevState.activeDataBlock].dataSteps.length
                        ? prevState.activeDataBlock + 1
                        : prevState.activeDataBlock;
                const activeDataStep =
                    prevState.activeDataStep + 1 >= structure.dataBlocks[prevState.activeDataBlock].dataSteps.length
                        ? 0
                        : prevState.activeDataStep + 1;
                return { activeDataBlock, activeDataStep };
            },
            () => {
                const dataBlockDiv = document.getElementById(`datablock-${this.state.activeDataBlock}`);
                if (dataBlockDiv) dataBlockDiv.scrollIntoView({ behavior: 'smooth' });
            },
        );
    };

    onBackButtonClick = () => {
        this.setState(
            (prevState) => {
                return {
                    activeDataBlock:
                        prevState.activeDataStep - 1 < 0 ? prevState.activeDataBlock - 1 : prevState.activeDataBlock,
                    activeDataStep:
                        prevState.activeDataStep - 1 < 0
                            ? structure.dataBlocks[prevState.activeDataBlock - 1].dataSteps.length - 1
                            : prevState.activeDataStep - 1,
                };
            },
            () => {
                const dataBlockDiv = document.getElementById(`datablock-${this.state.activeDataBlock}`);
                if (dataBlockDiv) dataBlockDiv.scrollIntoView({ behavior: 'smooth' });
            },
        );
    };

    handlePublishOnMarketToggle() {
        this.setState((prevState) => {
            return { isActive: !prevState.isActive };
        });
    }
}
