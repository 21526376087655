import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { withStyles, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import * as React from 'react';
import { ReactElement } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { PersonStore } from 'store';
import { theme } from 'style/NearbuyStyle';
import { Person } from 'model/Person';
import { SendRounded } from '@mui/icons-material';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = () =>
    createStyles({
        topicField: {
            width: '100%',
        },
        disabledTopicField: {
            width: '100%',
            background: theme.palette.action.disabledBackground,
        },
        messageField: {
            width: '100%',
        },
        disabledMessageField: {
            width: '100%',
            background: theme.palette.action.disabledBackground,
        },
    });

interface ChatInputProps extends WithStyles<typeof _styles>, WithTranslation {
    inactiveEmployeeRef?: string;
    isDisabled: boolean;
    isQuoting: boolean;
    onSend: (content: string, topic: string | undefined) => void;
    setTopic: (topic: string) => void;
    topic: string | undefined;
    dialogId?: string;
}

interface ChatInputState {
    topic: string | undefined;
    content: string;
    inactiveEmployee?: Person;
    dialogId: string;
}

class ChatInput extends ComponentBase<ChatInputProps, ChatInputState> {
    protected _buildState(
        props: ChatInputProps,
        initialBuild: boolean,
        incomingState?: Readonly<ChatInputState>,
    ): Partial<ChatInputState> | undefined {
        const newState: Partial<ChatInputState> = {};

        if (props.inactiveEmployeeRef) {
            newState.inactiveEmployee = PersonStore.getOne(props.inactiveEmployeeRef);
        }

        newState.dialogId = props.dialogId ?? '';
        newState.content = incomingState?.content;
        newState.topic = incomingState?.topic;

        // Clear stuff if dialog changed
        if (newState.dialogId !== incomingState?.dialogId) {
            newState.topic = props.topic;
            newState.content = '';
        }

        return newState;
    }

    showTopicInput(): ReactElement | null {
        if (this.props.isQuoting) return null;

        return (
            <TextField
                className={
                    this.props.isDisabled ? this.props.classes.disabledTopicField : this.props.classes.topicField
                }
                value={this.state.topic || ''}
                onChange={(evt) => {
                    this.setState({
                        topic: evt.target.value,
                    });
                }}
                label={this.props.t('messaging:topic')}
                disabled={this.props.isDisabled}
            />
        );
    }

    isSendKeyCombo(event: React.KeyboardEvent<HTMLDivElement>): boolean {
        return event.ctrlKey && event.code === 'Enter';
    }

    isSendingAllowed(): boolean {
        return !this.props.isDisabled && this.state.content !== '' && this.state.content !== undefined;
    }

    showContentInput(): ReactElement {
        return (
            <TextField
                className={
                    this.props.isDisabled ? this.props.classes.disabledMessageField : this.props.classes.messageField
                }
                value={
                    this.props.isDisabled && this.state.inactiveEmployee
                        ? this.props.t('messaging:noInputPossible', {
                              firstname: this.state.inactiveEmployee.firstname,
                              lastname: this.state.inactiveEmployee.lastname,
                          })
                        : this.state.content || ''
                }
                onChange={(event) =>
                    this.setState({
                        content: event.target.value,
                    })
                }
                onKeyPress={(event) => {
                    if (this.isSendingAllowed() && this.isSendKeyCombo(event)) {
                        if (this.state.topic) this.props.setTopic(this.state.topic);
                        this.send();
                    }
                }}
                disabled={this.props.isDisabled}
                multiline
                minRows={2}
                maxRows={6}
            />
        );
    }

    showSendButton(): ReactElement {
        return (
            <Button
                variant="contained"
                onClick={() => {
                    captureWebEvent('send-message-from-chat-button');
                    this.send();
                }}
                disabled={!this.isSendingAllowed()}
                sx={{ width: '100%', borderRadius: '12px', height: '90%', textAlign: 'center' }}
            >
                <Grid container direction={'column'}>
                    <Grid item>
                        <SendRounded />
                    </Grid>
                    <Grid item>{this.props.t('dialogs:SEND')}</Grid>
                </Grid>
            </Button>
        );
    }

    send() {
        this.props.onSend(this.state.content, this.state.topic);
        this.setState({
            content: '',
        });
    }

    render(): React.ReactElement | undefined {
        return (
            <Grid container direction={'row'} gap={2}>
                <Grid container item direction={'column'} lg={10.5} md={10} xl={11}>
                    {this.showTopicInput()}
                    {this.showContentInput()}
                </Grid>
                <Grid container item alignItems="center" lg={1.2} md={1.6} xl={0.8}>
                    {this.showSendButton()}
                </Grid>
            </Grid>
        );
    }
}

export default withTranslation(['messaging', 'dialogs'])(withStyles(_styles, { withTheme: true })(ChatInput));
