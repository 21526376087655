import { RequestDialogProperties, RequestDialogState } from 'components/marketplace/requests/dialog/RequestDialog';
import RequestService from 'api/RequestService';
import { RequestWriteView } from 'model/Request';
import { NumberFormatValues } from 'react-number-format';
import * as React from 'react';
import moment, { Moment } from 'moment/moment';
import { RequestDialogHelpers } from 'components/marketplace/requests/dialog/RequestDialogHelpers';
import { Unit } from 'model/Unit';
import { captureMultipleEvents, captureWebEvent, EventParam, getDifferingKeys } from 'util/AnalyticUtils';

export default class RequestDialogHandlers {
    constructor(
        private props: RequestDialogProperties,
        private state: RequestDialogState,
        private setState: <K extends keyof RequestDialogState>(
            state:
                | ((
                      prevState: Readonly<RequestDialogState>,
                      props: Readonly<RequestDialogProperties>,
                  ) => Pick<RequestDialogState, K> | RequestDialogState | null)
                | Pick<RequestDialogState, K>
                | RequestDialogState
                | null,
            callback?: () => void,
        ) => void,
        private helpers: RequestDialogHelpers,
    ) {}

    onClose = (event: any, reason: string) => {
        if (event && reason === 'backdropClick') {
            if (this.state.hasChanges) {
                this.setState({
                    isSaveLeavePreventionOpen: true,
                });
            } else {
                this.props.onClose?.();
            }
        }
    };

    onCancel = () => {
        this.props.onClose?.();
    };

    onSave = (): void => {
        this.captureRequestDetails();
        const afterSave = () => {
            this.props.onClose?.();
            this.setState({
                isSaveLeavePreventionOpen: false,
            });
        };

        if (!this.state.requestWriteView) return;
        if (this.state.request) {
            RequestService.updateRequest(this.state.requestWriteView, this.state.request.links.self).subscribe(() =>
                afterSave(),
            );
        } else {
            RequestService.addRequest(this.state.requestWriteView, this.props.companyRef).subscribe(() => afterSave());
        }
    };

    captureRequestEvent = (button: 'save' | 'cancel'): void => {
        const { requestRef, calledFrom } = this.props;
        const eventPrefix = button === 'save' ? 'marketplace-edit-and-save' : 'marketplace-cancel-edit';

        const captureEvent = (type: string, isNew: boolean) => {
            const status = isNew ? 'new' : 'existing';
            captureWebEvent(`${eventPrefix}-${status}-${type}-calledFrom-${calledFrom}`);
        };

        const isNew = (ref: any) => this.state.isCreateNew && ref === null;

        if (requestRef || isNew(requestRef)) {
            captureEvent('request', isNew(requestRef));
        }
    };

    captureRequestDetails = (): void => {
        const { initialRequestWriteView, requestWriteView } = this.state;

        const originsRequest = ['MyRequestsDashlet', 'RequestDashletItem', 'RequestTableRow', 'RequestsComponent'];

        const shouldTrackRequest = originsRequest.includes(this.props.calledFrom);

        const captureEvents = (eventName: string, paramsList: EventParam[]) => {
            if (paramsList.length > 0) {
                captureMultipleEvents({ params: paramsList, eventName });
            }
        };

        const trackRequest = () => {
            if (initialRequestWriteView && requestWriteView) {
                const requestWriteViewDiff = getDifferingKeys(
                    [requestWriteView, initialRequestWriteView],
                    ['description', 'dateFrom', 'dateEnd', 'levelsOfProcessing', 'totalAmount'],
                );
                const eventName = this.props.requestRef ? 'marketplace-existing-request' : 'marketplace-new-request';
                captureEvents(eventName, requestWriteViewDiff);
            }
        };

        if (shouldTrackRequest) trackRequest();
    };

    updateRequestWriteView = (partialRequestWriteView: Partial<RequestWriteView>) => {
        if (!this.state.requestWriteView) return;
        const requestWriteView: RequestWriteView = {
            ...this.state.requestWriteView,
            ...partialRequestWriteView,
        };
        this.setState({ requestWriteView });
    };

    updateLevelsOfProcessing = (levelsOfProcessing: string[] | undefined) => {
        if (!levelsOfProcessing) return;
        this.updateRequestWriteView({ levelsOfProcessing });
    };

    onSelectedContactRefChange = (contactRef: string | null | undefined, automatic?: boolean) => {
        const contact = contactRef !== undefined && contactRef !== '' ? contactRef : null;
        if (automatic) {
            this.setState({
                automaticallySetContact: contactRef ?? null,
            });
        }
        this.updateRequestWriteView({ contact });
    };

    removeLevelOfProcessing = (toRemoveLopRef: string) => {
        if (!this.state.requestWriteView?.levelsOfProcessing.length) return;
        this.updateRequestWriteView({
            levelsOfProcessing: this.state.requestWriteView.levelsOfProcessing.filter(
                (lopRef) => lopRef !== toRemoveLopRef,
            ),
        });
    };

    handleTotalAmountAmountChange = (numbers: NumberFormatValues) => {
        const totalAmount = this.helpers.getTotalAmount();
        if (!totalAmount) return;
        this.updateRequestWriteView({
            totalAmount: {
                ...totalAmount,
                amount: numbers.floatValue !== undefined ? numbers.floatValue : 0,
            },
        });
    };

    handleTotalAmountUnitChange = (unit: Unit) => {
        const totalAmount = this.helpers.getTotalAmount();

        if (totalAmount) {
            this.updateRequestWriteView({
                totalAmount: {
                    ...totalAmount,
                    unit: unit.unit,
                },
            });
        }
    };

    onProductDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        this.updateRequestWriteView({
            description: event.target.value,
        });
    };

    onDateEndChange = (value: any) => {
        const moment = value as Moment;
        const isInputValid = moment && moment.isValid();

        this.updateRequestWriteView({
            dateEnd: isInputValid ? moment.toDate() : undefined,
        });
    };

    onDateFromChange = (value: any) => {
        const momentFromValue = value as Moment;
        const yesterday = moment().subtract(1, 'days').startOf('day');
        const isInputValid = momentFromValue && momentFromValue.isValid() && yesterday.isBefore(momentFromValue);

        this.updateRequestWriteView({
            dateFrom: isInputValid ? momentFromValue.toDate() : undefined,
        });
    };

    onOntofoodSelectChange = (productRef: string | undefined) => {
        this.updateRequestWriteView({
            category: productRef,
        });
    };

    onConfirmationDialogDiscardClick = () => {
        this.setState({
            isSaveLeavePreventionOpen: false,
        });
        this.props.onClose?.();
    };
}
