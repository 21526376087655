import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import * as React from 'react';
import { ReactNode } from 'react';
import { StepOfferComponentProps } from 'components/marketplace/offers/dialog/OfferDialogStructure';
import { useTranslation } from 'react-i18next';
import PriceEditFieldsComponent from 'components/marketplace/shared/PriceEditFieldsComponent';
import { Amount } from 'model/Amount';
import { GraduatedPrice } from 'model/Offer';
import ContactSelectComponent from 'components/marketplace/shared/ContactSelectComponent';
import { ContactType } from 'model/Contact';
import HintComponent from 'components/hint/HintComponent';
import { NumberFormatValues } from 'react-number-format';
import { createFormError } from 'util/FormErrors';
import { OfferDialogFormErrors } from 'components/marketplace/offers/dialog/OfferDialog';

export default interface OfferStepPricingAndContactDetailsProps {
    pricePerUnit?: number | null | undefined;
    graduatedPrices?: GraduatedPrice[] | undefined;
    totalAmount?: Amount | undefined;
    contactRef?: string | null | undefined;
    companyRef?: string;
    offerRef?: string;
    automaticallyContact?: string | undefined | null;
    isNoMessagingInUseHintShown?: boolean;
    formErrors?: OfferDialogFormErrors;
}

export interface OfferStepPriceEditFieldsHandlers {
    addGraduatedPrices(): void;

    removeGraduatedPrice(graduatedPrice: GraduatedPrice): void;

    updateGraduatedPrice(graduatedPrice: GraduatedPrice): void;

    onPricePerUnitChange(numbers: NumberFormatValues): void;

    priceOnRequestChange(_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void;
}

export const OfferStepPricingAndContactDetails = (
    props: StepOfferComponentProps<OfferStepPricingAndContactDetailsProps>,
) => {
    const theme = useTheme();
    const { t } = useTranslation(['offerDialog', 'offer']);

    const showTitleAndMandatoryHint = (): ReactNode => (
        <Grid container sx={{ paddingBottom: theme.spacing(4) }} justifyContent="space-between">
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('offerDialog:OFFER_STEP_PRICING_AND_CONTACT_DETAILS')}
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>{t('offerDialog:mandatory')}</Typography>
        </Grid>
    );

    const showContactMissingHint = () => {
        const isContactNotAvailable = !props.data.contactRef?.length;
        return (
            isContactNotAvailable && (
                <HintComponent isInformationSymbolShown={true} text={t('offerDialog:employeeContactNotSelected')} />
            )
        );
    };

    const priceEditFieldsHandlers = {
        addGraduatedPrices: () => {
            const oldGraduatedPrices = props.data.graduatedPrices ?? [];
            props.setData('OfferStepPricingAndContactDetails', {
                graduatedPrices: [...oldGraduatedPrices, new GraduatedPrice(1, 1)],
            });
        },
        removeGraduatedPrice: (graduatedPrice: GraduatedPrice) => {
            props.setData('OfferStepPricingAndContactDetails', {
                graduatedPrices: props.data.graduatedPrices?.filter((gp) => gp !== graduatedPrice),
            });
        },
        updateGraduatedPrice: (graduatedPrice: GraduatedPrice) => {
            const oldGraduatedPrices = props.data.graduatedPrices ?? [];
            const graduatedPrices = [...oldGraduatedPrices];
            const index = graduatedPrices.findIndex((gp) => gp === graduatedPrice);
            if (index != -1) {
                graduatedPrices[index] = graduatedPrice;
                props.setData('OfferStepPricingAndContactDetails', { graduatedPrices: graduatedPrices });
            }
        },
        onPricePerUnitChange: (numbers: NumberFormatValues) => {
            props.setData('OfferStepPricingAndContactDetails', { pricePerUnit: numbers.floatValue ?? 0 });
        },
        priceOnRequestChange: (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            props.setData('OfferStepPricingAndContactDetails', {
                pricePerUnit: checked ? null : 1,
            });
        },
    };

    const contactSelectHandlers = {
        onSelectedContactRefChange: (contactRef: string | null | undefined, automatic?: boolean) => {
            const contact = contactRef !== undefined && contactRef !== '' ? contactRef : null;
            if (automatic) {
                props.setData('OfferStepPricingAndContactDetails', { automaticallyContact: contactRef });
            }
            // TODO NEARBUY-4736
            props.setData('OfferStepPricingAndContactDetails', { contactRef: contact }, () =>
                props.setData('OfferStepPricingAndContactDetails', { contactRef: contact }),
            );
        },
    };

    const showDoesNotUseMessagingHint = (): React.ReactFragment | null => {
        if (props.data.isNoMessagingInUseHintShown) {
            return <HintComponent isWarningSymbolShown={true} text={t('dialogs:doesNotUseMessagingHint')} />;
        } else return null;
    };

    const showPriceInput = (): ReactNode => {
        const { formErrors } = props.data;
        const { helperText: priceHelperText, showError: priceShowError } = formErrors?.pricePerUnit || {};
        const priceError = priceShowError ? createFormError(priceHelperText) : undefined;

        if (props.data.totalAmount !== undefined && !props.data.totalAmount.unit) {
            return (
                <Grid item marginBottom={3} marginRight={3}>
                    <Typography sx={{ fontWeight: 600 }}>{t('offerDialog:priceDetails')}</Typography>
                    <HintComponent
                        isInformationSymbolShown={true}
                        text={t('offerDialog:noAmountAndNoUnitSelectedHint')}
                    />
                </Grid>
            );
        }

        return (
            <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
                <PriceEditFieldsComponent
                    totalAmount={props.data.totalAmount ?? new Amount(0, '')}
                    pricePerUnit={props.data.pricePerUnit ?? null}
                    graduatedPrices={props.data.graduatedPrices ?? []}
                    pricePerUnitFormError={priceError}
                    isPriceHintVisible={props.data.pricePerUnit == null}
                    offerDialogHandlers={priceEditFieldsHandlers}
                />
            </Grid>
        );
    };

    const showContactTitleAndDescription = (): ReactNode => {
        return (
            <Grid item container>
                <Grid item md={12} sx={{}}>
                    <Typography sx={{ fontWeight: 600 }}>{t('offerDialog:contactTitle')}</Typography>
                </Grid>
                <Grid item md={12} sx={{}}>
                    <Typography
                        sx={{
                            marginTop: theme.spacing(0.5),
                            paddingBottom: 1.5,
                        }}
                    >
                        {t('offerDialog:contactDescription')}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const showContactSelection = (): ReactNode => {
        const contact = props.data.contactRef;

        return (
            <ContactSelectComponent
                type={ContactType.SALES}
                preSelectedContactRef={
                    !props.data.offerRef
                        ? contact != props.data.automaticallyContact
                            ? contact ?? null
                            : undefined
                        : contact ?? null
                }
                companyRef={props.data.companyRef ?? ''}
                onChange={contactSelectHandlers.onSelectedContactRefChange}
            />
        );
    };

    return (
        <Grid container direction={'column'} sx={{ paddingLeft: theme.spacing(5) }}>
            {showTitleAndMandatoryHint()}
            {showPriceInput()}
            {showContactTitleAndDescription()}
            <Grid item xs={12}>
                {showContactSelection()}
                {showContactMissingHint()}
            </Grid>
            {showDoesNotUseMessagingHint()}
        </Grid>
    );
};
