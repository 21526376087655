import { autoSubscribe, AutoSubscribeStore, StoreBase } from 'resub';
import { RecurringOrderListView } from 'model/RecurringOrder';
import RecurringOrderService from 'api/RecurringOrderService';

@AutoSubscribeStore
class RecurringOrderListStoreClass extends StoreBase {
    protected roMap: Map<string, RecurringOrderListView[]> = new Map<string, RecurringOrderListView[]>();
    protected isLoading = false;

    @autoSubscribe
    get(companyRef: string): RecurringOrderListView[] | undefined {
        const recurringOrders = this.roMap.get(companyRef);
        if (recurringOrders === undefined) this.search(companyRef);
        return recurringOrders;
    }

    private search(companyRef: string) {
        if (!this.isLoading) {
            this.isLoading = true;
            RecurringOrderService.listRecurringOrders(companyRef).subscribe((recurringOrders) => {
                this.roMap.set(companyRef, recurringOrders);
                this.isLoading = false;
                this.trigger();
            });
        }
    }

    invalidateCache(companyRef?: string) {
        if (companyRef !== undefined) this.roMap.delete(companyRef);
        else this.clear();
        this.trigger();
    }

    clear() {
        this.roMap = new Map<string, RecurringOrderListView[]>();
        this.trigger();
    }
}

export const RecurringOrderListStore = new RecurringOrderListStoreClass();
export default RecurringOrderListStore;
