import * as React from 'react';
import { ComponentBase } from 'resub';
import Grid from '@mui/material/Grid';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withStyles, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';
import Chip from '@mui/material/Chip';
import { LevelOfProcessing } from 'model/LevelOfProcessing';
import OfferDetailViewDialog from 'components/marketplace/offers/OfferDetailViewDialog';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = (theme: Theme) =>
    createStyles({
        offerDot: {
            color: theme.palette.secondary.dark,
            fontSize: 27,
            verticalAlign: 'middle',
        },
        requestDot: {
            color: theme.palette.primary.main,
            fontSize: 27,
            verticalAlign: 'middle',
        },
        category: {
            textAlign: 'left',
            //paddingLeft: '8px',
            fontWeight: 'bold',
            fontSize: 20,
            color: theme.palette.primary.dark,
        },
        text: {
            textAlign: 'left',
        },
        gridContainer: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        message: {
            borderRadius: '3px',
            padding: '0.5rem',
        },
        messageForeign: {
            border: '1px solid rgba(0, 0, 0, .12)',
        },
        messageOwn: {
            backgroundColor: theme.palette.primary.light,
        },
        messageRoot: {
            maxWidth: '75%',
            minWidth: '50%',
        },
        messageText: {
            overflowWrap: 'break-word',
            whiteSpace: 'pre-line',
        },
        button: {
            margin: theme.spacing(1),
            color: theme.palette.common.black,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.common.white,
            },
        },
        declineButton: {
            marginRight: theme.spacing(0.5),
            color: theme.palette.primary.dark,
        },
        chip: {
            margin: theme.spacing(0.5),
        },
    });

interface MarketItemTitleProps extends WithTranslation, WithStyles<typeof _styles> {
    offerRef?: string;
    purchaseIntentRef?: string;
    priceRequestRef?: string;
    requestRef?: string;
    productLabel: string;
    levelsOfProcessing?: LevelOfProcessing[];
    hasButton?: boolean;
    isOrderDetailsButtonShown?: boolean;
    calledFrom: string;
}

interface MarketItemTitleState {
    isOfferDetailViewDialogOpen: boolean;
    isOrderDetailsDialogOpen: boolean;
}

class MarketItemTitle extends ComponentBase<MarketItemTitleProps, MarketItemTitleState> {
    protected _buildState(
        _props: MarketItemTitleProps,
        initialBuild: boolean,
        _incomingState?: Readonly<MarketItemTitleState>,
    ): Partial<MarketItemTitleState> | undefined {
        const newState: Partial<MarketItemTitleState> = {};
        if (initialBuild) {
            newState.isOfferDetailViewDialogOpen = false;
            newState.isOrderDetailsDialogOpen = false;
        }
        return newState;
    }

    showLopInTitle(): string | undefined {
        if (this.props.levelsOfProcessing && this.props.levelsOfProcessing.length == 1) {
            return ', ' + this.props.t('levelsOfProcessing:' + this.props.levelsOfProcessing[0].label);
        }
    }

    showLops(): React.ReactNode {
        if (this.props.levelsOfProcessing && this.props.levelsOfProcessing.length > 1)
            return (
                <Grid container item>
                    <Grid xs={0.9} />
                    <Grid item container xs={11} alignItems={'center'}>
                        {this.props.levelsOfProcessing.map((lop) => {
                            return (
                                <Chip
                                    key={lop.links.self}
                                    label={this.props.t('levelsOfProcessing:' + lop.label)}
                                    color="primary"
                                    className={this.props.classes.chip}
                                />
                            );
                        })}
                    </Grid>
                </Grid>
            );
    }

    getButton(): React.ReactElement | undefined {
        return (
            <>
                {this.props.hasButton && !this.props.isOrderDetailsButtonShown && (
                    <Button
                        onClick={() => {
                            captureWebEvent(`messenger-show-offer-from-${this.props.calledFrom}`);
                            this.setState({
                                isOfferDetailViewDialogOpen: true,
                            });
                        }}
                        variant="outlined"
                        className={this.props.classes.declineButton}
                    >
                        {this.props.t('messaging:showOffer')}
                    </Button>
                )}
                {this.props.hasButton && this.props.isOrderDetailsButtonShown && (
                    <Button
                        onClick={() =>
                            this.setState({
                                isOrderDetailsDialogOpen: true,
                            })
                        }
                        variant="outlined"
                        className={this.props.classes.declineButton}
                    >
                        {this.props.t('order:showDetails')}
                    </Button>
                )}
            </>
        );
    }

    getOfferFullDetailDialog(): React.ReactElement | undefined {
        if (!this.props.offerRef) return;

        return (
            <>
                {this.state.isOfferDetailViewDialogOpen ? (
                    <OfferDetailViewDialog
                        offerRef={this.props.offerRef}
                        isOpen
                        onClose={() => {
                            this.setState({ isOfferDetailViewDialogOpen: false });
                        }}
                    />
                ) : null}
            </>
        );
    }

    getOrderDetailsDialog(): React.ReactElement | undefined {
        return; // not implemented yet (no linkable story found)
    }

    render(): React.ReactElement {
        const productLabel = this.props.t('ontofood:' + this.props.productLabel) + (this.showLopInTitle() ?? '');
        return (
            <>
                {this.getOfferFullDetailDialog()}
                {this.getOrderDetailsDialog()}
                <Grid container className={this.props.classes.gridContainer} direction={'column'}>
                    <Grid container item direction={'row'} spacing={this.props.hasButton ? 0 : 2}>
                        <Grid item xs={1}>
                            <FiberManualRecordIcon
                                className={
                                    this.props.requestRef ? this.props.classes.requestDot : this.props.classes.offerDot
                                }
                            />
                        </Grid>
                        <Grid item xs={this.props.hasButton ? 5 : 10}>
                            <Typography className={this.props.classes.category}>{productLabel}</Typography>
                        </Grid>
                        {this.props.hasButton && (
                            <Grid item xs={5} container justifyContent={'end'}>
                                {this.getButton()}
                            </Grid>
                        )}
                    </Grid>
                    {this.showLops()}
                </Grid>
            </>
        );
    }
}

export default withTranslation(['messaging', 'ontofood', 'levelsOfProcessing', 'order'])(
    withStyles(_styles, { withTheme: true })(MarketItemTitle),
);
