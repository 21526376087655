import TradeInfoDialog from 'components/messaging/chat/messagefactory/messageitemdata/TradeDataDialog';
import { MessageDirection } from 'model/Message';
import { t as TFunction } from 'i18next';
import * as React from 'react';
import { ReactFragment } from 'react';
import TradeActionButtonsComponent from 'components/messaging/chat/TradeActionButtonsComponent';
import { Order } from 'model/Order';
import OrderInfoCard from 'components/messaging/chat/messagefactory/infocards/OrderInfoCard';
import { CloudDownload, CloudUpload } from '@mui/icons-material';
import { Invoice } from 'model/Invoice';
import { Button, Tooltip } from '@mui/material';
import { theme } from 'style/NearbuyStyle';
import OrderService from 'api/OrderService';
import Grid from '@mui/material/Grid';
import { NotificationStore, PersonStore } from 'store';
import {
    NotificationInfo,
    OrderMarkedAsActiveNotification,
    OrderMarkedAsCompleteNotification,
} from 'model/NearbuyNotification';
import { now } from 'moment/moment';
import { MessageItemData } from 'components/messaging/chat/messagefactory/MessageItemData';
import { OrderTranslationMapping } from 'components/messaging/chat/messagefactory/OrderTranslationMapping';
import { captureWebEvent } from 'util/AnalyticUtils';

export function addOrderMessageItemData(
    baseData: MessageItemData,
    order: Order,
    t: typeof TFunction,
    mapping: OrderTranslationMapping,
    invoice: Invoice | undefined,
    invoiceFileInputOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    isFileSizeErrorShown: boolean,
    onShowTradeInfoDialog: (value: boolean) => void,
    isTradeInfoOpen: boolean,
): MessageItemData {
    const data: MessageItemData = {
        ...baseData,
        infoBox: {},
    };

    const currentUser = PersonStore.getSelected();
    const sellerRef = order.links.sellingPerson;
    const isSeller = currentUser !== undefined && sellerRef !== undefined && currentUser.links.self === sellerRef;

    const contextStatus = baseData.message.contextType.toString().split('__')[1];

    switch (contextStatus) {
        case 'PENDING':
            return addPendingData();
        case 'INVOICE_ADDED':
            return addInvoiceAddedData();
        case 'INVOICE_EXCHANGED':
            return addExchangedInvoiceData();
        default:
            return data;
    }

    function showMessageItemButtonComponent(invoice: Invoice) {
        return (
            <Grid
                container
                direction={'row'}
                justifyContent={'flex-end'}
                spacing={1}
                sx={{ marginTop: theme.spacing(0.5) }}
            >
                <Grid item>{showCompleteButton()}</Grid>
                <Grid item>
                    <TradeActionButtonsComponent
                        buttons={[
                            {
                                text: t('order:downloadInvoice'),
                                type: 'primaryLight',
                                iconStart: <CloudDownload />,
                                onClick: () => {
                                    captureWebEvent('download-invoice-from-chat');
                                    const link = document.createElement('a');
                                    link.download = invoice.fileName as string;
                                    link.href = invoice.downloadLink?.url as string;
                                    link.click();
                                },
                            },
                            {
                                text: t('purchaseIntent:toDetails'),
                                type: 'primaryLight',
                                onClick: () => onShowTradeInfoDialog(true),
                                tooltip: t('tooltips:toTradeDetails'),
                            },
                        ]}
                        justifyHorizontal={'end'}
                    />
                </Grid>
            </Grid>
        );
    }

    function addPendingData(): MessageItemData {
        switch (data.messageDirection) {
            case MessageDirection.INBOUND:
                if (baseData.originalSender) {
                    mapping.buyer = `${baseData.originalSender.firstname} ${baseData.originalSender.lastname}`;
                }
                data.infoBox!.topic = t('order:incomingOrder');
                data.infoBox!.content = t('order:receivedContent', mapping);
                data.infoBox!.card = <OrderInfoCard orderRef={order.links.self} />;
                data.infoBox!.error = isFileSizeErrorShown ? t('error:pdfBiggerThan5MbError') : undefined;
                data.infoBox!.buttons =
                    order.status == 'PENDING' ? (
                        <Grid
                            container
                            sx={{ marginTop: theme.spacing(0.5) }}
                            direction={'row'}
                            spacing={1}
                            justifyContent={'flex-start'}
                        >
                            <Grid item>{showCompleteButton()}</Grid>
                            <Grid item>
                                <TradeActionButtonsComponent
                                    buttons={[
                                        {
                                            onClick: () => {
                                                captureWebEvent('upload-invoice-from-OrderMessageItemData');
                                            },
                                            text: t('order:uploadInvoice'),
                                            type: 'primaryLight',
                                            iconStart: <CloudUpload />,
                                            fileInputOnChange: invoiceFileInputOnChange,
                                            fileInputAccept: 'application/pdf',
                                            tooltip: t('common:pdfUploadTooltipHint'),
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    ) : undefined;
                break;
            case MessageDirection.OUTBOUND:
                if (baseData.originalReceiver) {
                    mapping.seller = `${baseData.originalReceiver.firstname} ${baseData.originalReceiver.lastname}`;
                }
                data.infoBox!.topic = t('order:yourOrder');
                data.infoBox!.content = t('order:sentContent', mapping);
                data.infoBox!.card = (
                    <Grid container sm={12} direction={'column'} spacing={1}>
                        <Grid item>
                            <OrderInfoCard orderRef={order.links.self} />
                        </Grid>
                        <Grid item container justifyContent={'flex-end'}>
                            {showCompleteButton()}
                        </Grid>
                    </Grid>
                );
                break;
        }
        return data;
    }

    function addInvoiceAddedData(): MessageItemData {
        switch (data.messageDirection) {
            case MessageDirection.INBOUND:
                data.infoBox!.topic = t('order:receivedInvoiceTitle');
                data.infoBox!.content = t('order:receivedInvoiceText');
                data.infoBox!.card = <OrderInfoCard orderRef={order.links.self} isCompletedVariant={true} />;
                break;
            case MessageDirection.OUTBOUND:
                data.infoBox!.topic = t('order:invoiceUploadedTitleSeller');
                data.infoBox!.content = t('order:invoiceUploadedContentSeller', { fileName: invoice?.fileName });
                data.dialogs = (
                    <TradeInfoDialog
                        key={2}
                        isOpen={isTradeInfoOpen}
                        title={t('purchaseIntent:details')}
                        content={<OrderInfoCard orderRef={order.links.self} />}
                        onClose={onShowTradeInfoDialog}
                    />
                );
                data.infoBox!.buttons = invoice ? showMessageItemButtonComponent(invoice) : undefined;
                break;
        }
        return data;
    }

    function addExchangedInvoiceData(): MessageItemData {
        switch (data.messageDirection) {
            case MessageDirection.INBOUND:
                if (baseData.originalSender) {
                    mapping.seller = `${baseData.originalSender.firstname} ${baseData.originalSender.lastname}`;
                }
                data.infoBox!.topic = t('order:exchangedInvoiceTitle');
                data.infoBox!.content = t('order:exchangedInvoiceText', mapping);
                data.infoBox!.buttons = invoice ? showMessageItemButtonComponent(invoice) : undefined;
                break;
            case MessageDirection.OUTBOUND:
                break;
        }
        return data;
    }

    function showCompleteButton(): ReactFragment | null {
        return (
            <Tooltip
                title={t(
                    isSeller
                        ? order.isCompleted
                            ? 'order:markNotCompletedTooltipSeller'
                            : 'order:markCompletedTooltipSeller'
                        : order.isCompleted
                          ? 'order:markNotCompletedTooltipBuyer'
                          : 'order:markCompletedTooltipBuyer',
                )}
            >
                <Button
                    variant={'outlined'}
                    onClick={() => {
                        OrderService.setCompleted(order.links.self, !order.isCompleted).subscribe();
                        NotificationStore.setOne(
                            new NotificationInfo(
                                (() => {
                                    if (order.isCompleted) {
                                        captureWebEvent('order-is-marked-as-active');
                                        return OrderMarkedAsActiveNotification(isSeller, false);
                                    } else {
                                        captureWebEvent('order-is-marked-as-complete');
                                        return OrderMarkedAsCompleteNotification(isSeller, true);
                                    }
                                })(),
                                now(),
                            ),
                        );
                    }}
                >
                    {order.isCompleted ? t('order:markAsActive') : t('order:markAsCompleted')}
                </Button>
            </Tooltip>
        );
    }
}
