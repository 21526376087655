import { ComponentClass, FunctionComponent } from 'react';
import OfferStepProductInformationProps, {
    OfferStepProductInformation,
} from 'components/marketplace/offers/dialog/steps/OfferStepProductInformation';
import OfferStepAmountAndPackagingProps, {
    OfferStepAmountAndPackaging,
} from 'components/marketplace/offers/dialog/steps/OfferStepAmountAndPackaging';
import OfferStepProductDescriptionProps, {
    OfferStepProductDescription,
} from 'components/marketplace/offers/dialog/steps/OfferStepProductDescription';
import OfferStepPricingAndContactDetailsProps, {
    OfferStepPricingAndContactDetails,
} from 'components/marketplace/offers/dialog/steps/OfferStepPricingAndContactDetails';

export interface StepOfferComponentProps<T> {
    setData: (dataId: keyof OfferDialogData, data: T, callback?: () => void) => void;
    setShowAllFormErrors: (value: boolean) => void;
    isNewOffer: boolean;
    data: T;
    limitingProductRef?: string;
}

export interface OfferStep {
    title: string;
    dataId?: keyof OfferDialogData;
    component?: FunctionComponent<any> | ComponentClass<any, any>;
}

export interface OfferBlock {
    title: string;
    subtitle?: string;
    offerSteps: OfferStep[];
}

export default interface OfferDialogStructure {
    offerBlocks: OfferBlock[];
}

export interface OfferDialogData {
    OfferStepProductInformation: OfferStepProductInformationProps;
    OfferStepAmountAndPackaging: OfferStepAmountAndPackagingProps;
    OfferStepProductDescription: OfferStepProductDescriptionProps;
    OfferStepPricingAndContactDetails: OfferStepPricingAndContactDetailsProps;
}

export const structure: OfferDialogStructure = {
    offerBlocks: [
        {
            title: '',
            subtitle: '',
            offerSteps: [
                {
                    title: 'OFFER_STEP_PRODUCT_INFORMATION',
                    component: OfferStepProductInformation,
                    dataId: 'OfferStepProductInformation',
                },
                {
                    title: 'OFFER_STEP_AMOUNT_AND_PACKAGING_TITLE',
                    component: OfferStepAmountAndPackaging,
                    dataId: 'OfferStepAmountAndPackaging',
                },
                {
                    title: 'OFFER_STEP_PRODUCT_DESCRIPTION',
                    component: OfferStepProductDescription,
                    dataId: 'OfferStepProductDescription',
                },
                {
                    title: 'OFFER_STEP_PRICING_AND_CONTACT_DETAILS',
                    component: OfferStepPricingAndContactDetails,
                    dataId: 'OfferStepPricingAndContactDetails',
                },
            ],
        },
    ],
};
