export const additivesUsage = require('./additivesUsage.json');
export const admin = require('./admin.json');
export const allergens = require('./allergens.json');
export const association = require('./association.json');
export const common = require('./common.json');
export const company = require('./company.json');
export const containertype = require('./containertype.json');
export const dashboard = require('./dashboard.json');
export const delivery = require('./delivery.json');
export const dialogs = require('./dialogs.json');
export const eNumbers = require('./eNumbers.json');
export const error = require('./error.json');
export const file = require('./file.json');
export const globalSystemMessage = require('./globalSystemMessage.json');
export const grade = require('./grade.json');
export const levelsOfProcessing = require('./levelsOfProcessing.json');
export const mail = require('./mail.json');
export const maintenance = require('./maintenance.json');
export const membership = require('./membership.json');
export const messaging = require('./messaging.json');
export const mobile = require('./mobile.json');
export const notification = require('./notification.json');
export const offer = require('./offer.json');
export const ontofood = require('./ontofood.json');
export const ontofoodEditor = require('./ontofoodEditor.json');
export const order = require('./order.json');
export const payment = require('./payment.json');
export const plugin = require('./plugin.json');
export const preOrder = require('./preOrder.json');
export const priceRequest = require('./priceRequest.json');
export const productDataSheet = require('./productDataSheet.json');
export const profile = require('./profile.json');
export const purchaseIntent = require('./purchaseIntent.json');
export const recurringOrder = require('./recurringOrder.json');
export const recurringOrderBuyingDialog = require('./recurringOrderBuyingDialog.json');
export const request = require('./request.json');
export const sales = require('./sales.json');
export const search = require('./search.json');
export const shortunits = require('./shortunits.json');
export const subscription = require('./subscription.json');
export const system = require('./system.json');
export const tenant = require('./tenant.json');
export const tooltips = require('./tooltips.json');
export const units = require('./units.json');
