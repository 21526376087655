import { WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Offer } from 'model/Offer';
import { Product } from 'model/Product';
import { CompanyRoles, NearbuyRole } from 'model/NearbuyRole';
import { MessageTermsStatus, Person } from 'model/Person';
import { ComponentBase } from 'resub';
import * as React from 'react';
import { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, IconButton, Theme } from '@mui/material';
import { captureWebEvent } from 'util/AnalyticUtils';
import IconComponent from 'components/shared/IconComponent';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import DialogContent from '@mui/material/DialogContent';
import { theme } from 'style/NearbuyStyle';
import StyledDatePickerComponent from 'components/shared/Popovers/StyledDatePickerComponent';
import TextField from '@mui/material/TextField';
import { Calendar } from '@mui/x-date-pickers/internals/components/icons';
import Chip from '@mui/material/Chip';
import { wrapButtonWithTooltip } from 'util/style-helpers';
import FeatureToggle from 'components/feature/FeatureToggle';
import { Feature } from 'model/Feature';
import Button from '@mui/material/Button';
import MessageRestrictionDialog from 'components/messaging/MessageRestrictionDialog';
import Divider from '@mui/material/Divider';
import {
    CompanyRolesStore,
    CompanyStore,
    ContactsStore,
    MetaStore,
    OfferStore,
    PersonStore,
    ProductStore,
} from 'store';
import { ContainerType, ContainerView } from 'model/ContainerView';
import { getContainerName, getNumberCurrency, getNumberDecimal } from 'util/helpers';
import { MetaStaticEntityType } from 'model/Meta';
import { getUnitFromString } from 'model/Unit';
import PreOrderDialog, { PreOrderDialogType } from 'components/marketplace/pre-order/dialog/PreOrderDialog';
import SimpleContactCard from 'components/marketplace/offers/SimpleContactCard';

const _styles = (theme: Theme) =>
    createStyles({
        chip: {
            marginRight: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        },
        contentText: {
            fontSize: '15px',
            whiteSpace: 'pre-line',
            marginBottom: theme.spacing(0.5),
        },
        headingMain: {
            fontSize: '20px',
            fontWeight: 'bold',
            marginBottom: theme.spacing(0.5),
        },
        subHeading: {
            fontSize: '16px',
            fontWeight: 'bold',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(0.5),
        },
        iconClose: {
            fontSize: '1.3em',
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.primary.dark,
            },
        },
        iconButton: {
            padding: 0,
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        iconText: {
            fontSize: '14px',
            fontWeight: 500,
            color: theme.palette.primary.dark,
        },
        shipperIcon: {
            padding: theme.spacing(0.4),
            color: theme.palette.primary.dark,
        },
        dot: {
            fontSize: 27,
            marginRight: theme.spacing(2.8),
            fill: theme.palette.secondary.dark,
            verticalAlign: 'middle',
        },
    });

interface OfferDetailViewDialogProps extends WithStyles<typeof _styles>, WithTranslation {
    isOpen: boolean;
    offerRef: string;
    onClose: () => void;
}

interface OfferDetailViewDialogState {
    offer?: Offer;
    product?: Product;
    roles?: CompanyRoles;
    isPreOrderDialogOpen: boolean;
    ownCompanyRef: string;
    user: Person;
    contactPersonRef: string;
    contactPerson: Person;
    isTradeButtonDisabled: boolean;
    isRestrictionDialogOpen: boolean;
    areLevelsOfProcessingAvailable: boolean;
    areGraduatedPricesAvailable: boolean;
    companyId: string;
    containers: ContainerView[];
    containerTypes: ContainerType[];
}

class OfferDetailViewDialog extends ComponentBase<OfferDetailViewDialogProps, OfferDetailViewDialogState> {
    protected _buildState(
        props: OfferDetailViewDialogProps,
        initialBuild: boolean,
        incomingState: Readonly<OfferDetailViewDialogState> | undefined,
    ): Partial<OfferDetailViewDialogState> {
        let ownCompanyRef = CompanyStore.getSelected()?.links.self;
        if (ownCompanyRef === undefined) ownCompanyRef = '';
        const company = ownCompanyRef && CompanyStore.getOne(ownCompanyRef);
        const newState: Partial<OfferDetailViewDialogState> = {
            ...incomingState,
            offer: OfferStore.getOne(props.offerRef),
            companyId: company && company.id,
            ownCompanyRef: ownCompanyRef,
            user: PersonStore.getSelected(),
            containers: new Array<ContainerView>(),
        };

        if (initialBuild) {
            newState.isPreOrderDialogOpen = false;
            newState.isTradeButtonDisabled = true;
            newState.isRestrictionDialogOpen = false;
        }

        if (newState.offer) {
            newState.containers = newState.offer.containers;
        }

        if (!newState.containerTypes) {
            const metaContainerType = MetaStore.getOne(MetaStaticEntityType.CONTAINER_TYPE);

            if (!metaContainerType) return newState;

            newState.containerTypes = metaContainerType.data as ContainerType[];
        }

        if (incomingState?.containerTypes && newState.containers && newState.containers.length > 0) {
            newState.containers.sort((a: ContainerView, b: ContainerView) => {
                const nameA = getContainerName(incomingState.containerTypes, a.containerType);
                const nameB = getContainerName(incomingState.containerTypes, b.containerType);
                const sizeAinBaseUnit = a.amount * (getUnitFromString(a.unit)?.conversionFactor ?? 1);
                const sizeBinBaseUnit = b.amount * (getUnitFromString(b.unit)?.conversionFactor ?? 1);

                return nameA > nameB ? 1 : nameA < nameB ? -1 : sizeAinBaseUnit > sizeBinBaseUnit ? 1 : -1;
            });
        }

        if (newState.offer) {
            if (newState.offer.links.category) {
                newState.product = ProductStore.getOne(newState.offer.links.category);
                newState.roles = CompanyRolesStore.getOne(newState.offer.links.company);
            }

            newState.areGraduatedPricesAvailable =
                newState.offer.graduatedPrices && newState.offer.graduatedPrices.length > 0;
            newState.areLevelsOfProcessingAvailable =
                newState.offer.levelsOfProcessing && newState.offer.levelsOfProcessing.length > 0;

            if (newState.offer.links.contact) {
                const contact = ContactsStore.getOne(newState.offer.links.contact);
                if (contact) {
                    newState.contactPerson = PersonStore.getOne(contact.links.person);
                    const foreignCompany = CompanyStore.getOne(contact.links.company);
                    if (newState.contactPerson && newState.user) {
                        newState.contactPersonRef = newState.contactPerson.links.self;
                        newState.isTradeButtonDisabled =
                            !foreignCompany?.verified ||
                            newState.contactPerson.messageTermsStatus != MessageTermsStatus.ACCEPTED;
                    }
                }
            }
        }
        return newState;
    }

    groupByContainerType(containers: ContainerView[]): Map<string, string[]> {
        return containers.reduce((acc, container) => {
            if (!acc.has(container.containerType)) {
                acc.set(container.containerType, []);
            }
            acc
                .get(container.containerType)
                ?.push(getNumberDecimal(container.amount, 2, 2) + ' ' + this.props.t('shortunits:' + container.unit));

            return acc;
        }, new Map<string, string[]>());
    }

    showPreOrderDialog(): ReactNode {
        if (this.state.offer && this.state.offer.links.contact && this.state.isPreOrderDialogOpen) {
            return (
                <PreOrderDialog
                    type={
                        this.state.offer.pricePerUnit === null
                            ? PreOrderDialogType.PriceRequest
                            : PreOrderDialogType.PurchaseIntent
                    }
                    isOpen
                    onClose={async (): Promise<void> => {
                        await new Promise((r) => setTimeout(r, 200));
                        this.setState({ isPreOrderDialogOpen: false });
                    }}
                    offerRef={this.state.offer.links.self}
                    contactRef={this.state.offer.links.contact}
                />
            );
        } else return null;
    }

    showProductName(): ReactNode {
        return (
            <Grid item md={12}>
                <Typography className={this.props.classes.headingMain}>
                    {this.state.product ? this.props.t(this.state.product.label, { ns: 'ontofood' }) : ''}
                </Typography>
            </Grid>
        );
    }

    showProductInformation(): ReactNode {
        return (
            <Grid container item>
                {this.state.offer?.productTitle ? (
                    <Grid item md={12}>
                        <Typography className={this.props.classes.contentText}>
                            {this.state.offer.productTitle}
                        </Typography>
                    </Grid>
                ) : null}
                {this.state.offer?.brandName ? (
                    <Grid item md={12}>
                        <Typography className={this.props.classes.contentText}>{this.state.offer.brandName}</Typography>
                    </Grid>
                ) : null}
                <Grid item md={12}>
                    {this.showLevelsOfProcessing()}
                </Grid>
            </Grid>
        );
    }

    showLevelsOfProcessing(): ReactNode {
        return this.state.areLevelsOfProcessingAvailable ? (
            <Grid item md={12} sx={{ paddingTop: '10px' }}>
                {this.state.offer?.levelsOfProcessing
                    ? this.state.offer!.levelsOfProcessing.map((lop) => {
                          return (
                              <Chip
                                  key={lop.links.self}
                                  label={this.props.t('levelsOfProcessing:' + lop.label)}
                                  color="primary"
                                  className={this.props.classes.chip}
                              />
                          );
                      })
                    : null}
            </Grid>
        ) : null;
    }

    showShipperIcon(): ReactNode {
        return (
            <Grid item>
                {this.state.roles?.roles.includes(NearbuyRole.SHIPPER) && (
                    <Box display="flex" justifyContent="left" alignItems="center" minHeight="100%">
                        {wrapButtonWithTooltip(
                            <IconComponent
                                icon={'local_shipping'}
                                weight={'100'}
                                className={this.props.classes.shipperIcon}
                            />,
                            this.props.t('tooltips:shipperIcon'),
                        )}
                        <Typography className={this.props.classes.iconText}>
                            {this.props.t('common:SHIPPER')}
                        </Typography>
                    </Box>
                )}
            </Grid>
        );
    }

    showRecurringOrderHint(): ReactNode {
        if (!this.state.offer?.isPermanent) return null;
        return (
            <Grid item>
                <Box display="flex" justifyContent="right" alignItems="center" minHeight="100%">
                    <IconComponent icon={'event_repeat'} weight={'100'} className={this.props.classes.shipperIcon} />
                    <Typography className={this.props.classes.iconText}>
                        {this.props.t('offer:fitForRecurringOrder')}
                    </Typography>
                </Box>
            </Grid>
        );
    }

    areButtonPrerequisitesGiven(): boolean {
        return (
            !!this.state.offer &&
            !this.state.offer.isDeleted &&
            this.state.offer.active &&
            !!this.state.offer.links.contact &&
            !!this.state.contactPersonRef &&
            !!this.state.user &&
            this.state.offer.links.company !== this.state.ownCompanyRef &&
            this.state.user.links.self !== this.state.contactPersonRef
        );
    }

    shouldPurchaseIntentButtonBeVisible(): boolean {
        return this.areButtonPrerequisitesGiven() && !!this.state.offer;
    }

    showMessageRestrictionDialog(): ReactNode {
        return (
            <MessageRestrictionDialog
                isOpen={this.state.isRestrictionDialogOpen ?? false}
                onClose={() => this.setState({ isRestrictionDialogOpen: false })}
            />
        );
    }

    openMessageRestrictionDialog(): void {
        this.setState({
            isRestrictionDialogOpen: true,
        });
    }

    showPurchaseIntentButton(): ReactNode {
        if (!this.state.user) return null;
        return this.shouldPurchaseIntentButtonBeVisible() ? (
            <Grid item>
                <FeatureToggle requiredFeatures={[Feature.CAN_BUY_AND_SELL]} type={'allOf'}>
                    {wrapButtonWithTooltip(
                        <Button
                            disabled={this.state.isTradeButtonDisabled}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                captureWebEvent('marketplace-make-PurchaseIntent-button');
                                this.state.user!.messageTermsStatus === MessageTermsStatus.ACCEPTED
                                    ? this.setState({
                                          isPreOrderDialogOpen: true,
                                      })
                                    : this.openMessageRestrictionDialog();
                            }}
                        >
                            {this.state.offer?.pricePerUnit
                                ? this.props.t('offer:sendPurchaseIntent')
                                : this.props.t('offer:priceRequest')}
                        </Button>,
                        this.state.isTradeButtonDisabled
                            ? this.props.t('dialogs:noMessagingInUsePurchaseIntent', {
                                  firstname: this.state.contactPerson?.firstname,
                                  lastname: this.state.contactPerson?.lastname,
                              })
                            : undefined,
                    )}
                </FeatureToggle>
            </Grid>
        ) : null;
    }

    showDatePicker(): ReactNode {
        if (this.state.offer) {
            return (
                <>
                    <Grid item xs={12}>
                        <StyledDatePickerComponent
                            value={this.state.offer.dateFrom}
                            label={this.props.t('offer:dateFrom')}
                            onChange={(): void => {
                                return;
                            }}
                            disabled
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    fullWidth
                                    helperText={undefined}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                sx={{
                                                    '&.Mui-disabled': {
                                                        backgroundColor: 'transparent',
                                                        color: theme.palette.action.disabled,
                                                        padding: 0,
                                                    },
                                                }}
                                                disabled
                                            >
                                                <Calendar />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StyledDatePickerComponent
                            value={this.state.offer.dateEnd}
                            label={
                                this.state.offer.isPermanent && !this.state.offer.dateEnd?.isValid()
                                    ? '-'
                                    : this.props.t('offer:dateUntil')
                            }
                            onChange={(): void => {
                                return;
                            }}
                            disabled
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    error={false}
                                    fullWidth
                                    helperText={undefined}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                sx={{
                                                    '&.Mui-disabled': {
                                                        backgroundColor: 'transparent',
                                                        color: theme.palette.action.disabled,
                                                        padding: 0,
                                                    },
                                                }}
                                                disabled
                                            >
                                                <Calendar />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </>
            );
        } else return null;
    }

    showProductAndDateSection(): ReactNode {
        return (
            <Grid container item md={12} justifyContent={'space-between'}>
                <Grid container item md={6} alignContent={'start'}>
                    {this.showProductName()}
                    {this.showProductInformation()}
                </Grid>
                <Grid item>
                    <Grid
                        container
                        item
                        direction={'row'}
                        spacing={1}
                        justifyContent={'space-between'}
                        sx={{ paddingBottom: 1 }}
                    >
                        {this.showShipperIcon()}
                        {this.showPurchaseIntentButton()}
                    </Grid>
                    {this.showDatePicker()}
                    {process.env.REACT_APP_NEARBUY_FEATURE_SHOW_RECURRING_ORDER_FEATURE === 'true' &&
                        this.showRecurringOrderHint()}
                </Grid>
            </Grid>
        );
    }

    showProductSpecificSection(): ReactNode {
        if (!this.state.offer) return null;

        return (
            <>
                {this.state.offer.productTraits || this.state.offer.caliber || this.state.offer.weight ? (
                    <>
                        <Grid item md={12} />
                        <Grid container item md={12}>
                            <Typography className={this.props.classes.subHeading}>
                                {this.props.t('offerDialog:productTraitTitle')}
                            </Typography>
                        </Grid>
                    </>
                ) : null}
                {this.state.offer.productTraits?.map((trait, index) => {
                    return (
                        <Grid container item md={12} key={index}>
                            <Grid item md={4}>
                                <Typography className={this.props.classes.contentText}>
                                    {this.props.t('productTrait:' + trait.trait)}
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography className={this.props.classes.contentText}>{trait.description}</Typography>
                            </Grid>
                        </Grid>
                    );
                })}
                {this.state.offer.caliber ? (
                    <Grid container item md={12}>
                        <Grid item md={2.5}>
                            <Typography className={this.props.classes.contentText}>
                                {this.props.t('offerDialog:caliber')}
                            </Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Typography className={this.props.classes.contentText}>
                                {this.props.t('offerDialog:from') +
                                    ' ' +
                                    this.state.offer.caliber.min?.toFixed(1) +
                                    ' ' +
                                    this.props.t('offerDialog:until') +
                                    ' ' +
                                    this.state.offer.caliber.max?.toFixed(1) +
                                    ' ' +
                                    this.props.t('shortunits:' + this.state.offer.caliber.unit)}
                            </Typography>
                        </Grid>
                    </Grid>
                ) : null}
                {this.state.offer.weight ? (
                    <Grid container item md={12}>
                        <Grid item md={2.5}>
                            <Typography className={this.props.classes.contentText}>
                                {this.props.t('offer:weight')}
                            </Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Typography className={this.props.classes.contentText}>
                                {this.props.t('offerDialog:from') +
                                    ' ' +
                                    this.state.offer.weight.min?.toFixed(1) +
                                    ' ' +
                                    this.props.t('offerDialog:until') +
                                    ' ' +
                                    this.state.offer.weight.max?.toFixed(1) +
                                    ' ' +
                                    this.props.t('shortunits:' + this.state.offer.weight.unit)}
                            </Typography>
                        </Grid>
                    </Grid>
                ) : null}
            </>
        );
    }

    showCloseIcon(): ReactNode {
        return (
            <IconButton
                onClick={(): void => {
                    captureWebEvent('close-offer-dialog');
                    this.props.onClose();
                }}
                className={this.props.classes.iconButton}
                size="medium"
            >
                <IconComponent icon={'close'} className={this.props.classes.iconClose} />
            </IconButton>
        );
    }

    showProductDescriptionSection(): ReactNode {
        return this.state.offer?.description ? (
            <>
                <Grid item md={1} />
                <Grid item md={12}>
                    <Typography className={this.props.classes.subHeading}>
                        {this.props.t('offerDialog:productDescription')}
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <Typography className={this.props.classes.contentText}>{this.state.offer.description}</Typography>
                </Grid>
            </>
        ) : null;
    }

    showDeliveryOptionSection(): ReactNode {
        return this.state.offer ? (
            <Grid container item md={5} alignContent={'flex-start'}>
                <Grid item md={12}>
                    <Typography className={this.props.classes.subHeading}>
                        {this.props.t('offer:deliveryConditions')}
                    </Typography>
                </Grid>
                <Grid container item md={12}>
                    <Grid item md={5}>
                        <Typography className={this.props.classes.contentText}>
                            {this.state.offer.isPermanent
                                ? this.props.t('offer:maxQuantityPerDayText')
                                : this.props.t('offer:amount')}
                        </Typography>
                    </Grid>
                    <Grid item md={1} />
                    <Grid item>
                        <Typography className={this.props.classes.contentText}>
                            {getNumberDecimal(this.state.offer.totalAmount.amount, 2, 2) +
                                ' ' +
                                this.props.t('shortunits:' + this.state.offer.totalAmount.unit)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item md={12}>
                    <Grid item md={5}>
                        <Typography className={this.props.classes.contentText}>
                            {this.state.offer.isPermanent
                                ? this.props.t('offer:minimumPurchasePerDelivery')
                                : this.props.t('offer:minimumPurchase')}
                        </Typography>
                    </Grid>
                    <Grid item md={1} />
                    <Grid item>
                        <Typography className={this.props.classes.contentText}>
                            {getNumberDecimal(this.state.offer.minAmount.amount, 2, 2) +
                                ' ' +
                                this.props.t('shortunits:' + this.state.offer.minAmount.unit)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        ) : null;
    }

    showPriceSectionSection(): ReactNode {
        return this.state.offer ? (
            <Grid container item md={5} alignContent={'flex-start'}>
                <Grid item md={12}>
                    <Typography className={this.props.classes.subHeading}>
                        {this.props.t('offer:basicAndGraduatedPricing')}
                    </Typography>
                </Grid>
                <Grid item md={5}>
                    <Typography className={this.props.classes.contentText}>
                        {this.props.t('offer:pricePerUnitWithoutNet')}
                    </Typography>
                </Grid>
                <Grid item md={1} />
                <Grid item>
                    <Typography className={this.props.classes.contentText}>
                        {this.state.offer.pricePerUnit
                            ? getNumberCurrency(this.state.offer.pricePerUnit) +
                              ' ' +
                              this.props.t('offer:per') +
                              ' ' +
                              this.props.t('shortunits:' + this.state.offer.totalAmount.unit)
                            : this.props.t('offer:uponRequest')}
                    </Typography>
                </Grid>
                {this.state.offer.graduatedPrices?.map((graduatedPrice) => {
                    return (
                        <>
                            <Grid item md={5}>
                                <Typography className={this.props.classes.contentText}>
                                    {this.props.t('offer:from') +
                                        ' ' +
                                        getNumberDecimal(graduatedPrice.amount, 2, 2) +
                                        ' ' +
                                        this.props.t('shortunits:' + this.state.offer?.totalAmount.unit)}
                                </Typography>
                            </Grid>
                            <Grid item md={1} />
                            <Grid item md={5}>
                                <Typography className={this.props.classes.contentText}>
                                    {getNumberDecimal(graduatedPrice.price, 2, 2) +
                                        ' ' +
                                        this.props.t('offer:currency') +
                                        ' ' +
                                        this.props.t('offer:per') +
                                        ' ' +
                                        this.props.t('shortunits:' + this.state.offer?.totalAmount.unit)}
                                </Typography>
                            </Grid>
                        </>
                    );
                })}
            </Grid>
        ) : null;
    }

    showContainersSection(): ReactNode {
        if (!(this.state.containers && this.state.containers.length > 0)) return null;

        const containerElements: ReactNode[] = [];
        const groupedContainer: Map<string, string[]> = this.groupByContainerType(this.state.containers);

        groupedContainer.forEach((value, key) => {
            containerElements.push(
                <>
                    <Grid item md={6}>
                        <Typography className={this.props.classes.contentText}>
                            {getContainerName(this.state.containerTypes, key, false)}
                        </Typography>
                    </Grid>
                    <Grid item md={5}>
                        <Typography className={this.props.classes.contentText}>
                            {value.reduce((acc, amount) => {
                                if (acc) {
                                    acc = acc + ', ' + amount;
                                } else {
                                    acc = amount;
                                }
                                return acc;
                            }, '')}
                        </Typography>
                    </Grid>
                </>,
            );
        });

        return (
            <Grid container item md={5} alignContent={'flex-start'}>
                <Grid item md={12}>
                    <Typography className={this.props.classes.subHeading}>{this.props.t('offer:container')}</Typography>
                </Grid>
                {containerElements}
            </Grid>
        );
    }

    showContactSection(): ReactNode {
        return this.state.offer ? (
            <>
                <Grid item>
                    <Divider sx={{ marginBottom: theme.spacing(2) }} />
                </Grid>
                <Grid container item>
                    <Grid item container md={10}>
                        <Typography className={this.props.classes.subHeading}>
                            {this.props.t('offer:contactHeading')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item alignItems={'center'} justifyContent={'space-between'}>
                    <Grid item md={5.8}>
                        <SimpleContactCard companyRef={this.state.offer.links.company} />
                    </Grid>
                    <Grid item md={5.8}>
                        {this.state.offer.links.contact && this.state.product ? (
                            <SimpleContactCard
                                offerRef={this.props.offerRef}
                                contactRef={this.state.offer.links.contact}
                                messageTopic={
                                    this.props.t('offer:offer') +
                                    ' ' +
                                    this.props.t(this.state.product.label, { ns: 'ontofood' })
                                }
                            />
                        ) : null}
                    </Grid>
                </Grid>
            </>
        ) : null;
    }

    handleClickAway = (event: any, reason: string) => {
        if (event && reason === 'backdropClick') {
            this.props.onClose();
        }
    };

    render(): ReactNode {
        if (!this.state.offer) return null;
        return (
            <Dialog open={this.props.isOpen ?? false} maxWidth="md" fullWidth onClose={this.handleClickAway}>
                <DialogTitle>
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item md={0.5}>
                            <FiberManualRecordIcon className={this.props.classes.dot} />
                        </Grid>
                        <Grid item md={10.5}>
                            <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                                {this.props.t('offer:offer')}
                            </Typography>
                        </Grid>
                        <Grid item container md={1} justifyContent={'flex-end'}>
                            {this.showCloseIcon()}
                        </Grid>
                    </Grid>
                </DialogTitle>
                {this.showMessageRestrictionDialog()}
                {this.showPreOrderDialog()}
                <DialogContent>
                    <Grid
                        container
                        direction={'column'}
                        spacing={2}
                        sx={{
                            paddingTop: theme.spacing(2),
                            paddingBottom: theme.spacing(2),
                            paddingLeft: theme.spacing(2),
                            paddingRight: theme.spacing(2),
                        }}
                    >
                        <Grid container item>
                            {this.showProductAndDateSection()}
                        </Grid>
                        <Grid container item>
                            {this.showProductSpecificSection()}
                        </Grid>
                        <Grid container item>
                            {this.showProductDescriptionSection()}
                        </Grid>
                        <Grid container item>
                            {this.showDeliveryOptionSection()}
                        </Grid>
                        <Grid container item>
                            {this.showPriceSectionSection()}
                        </Grid>
                        <Grid container item>
                            {this.showContainersSection()}
                        </Grid>
                        <Grid item>{this.showContactSection()}</Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withTranslation([
    'common',
    'shortunits',
    'offer',
    'levelsOfProcessing',
    'tooltips',
    'dialogs',
    'ontofood',
    'offerDialog',
    'productTrait',
])(withStyles(_styles, { withTheme: true })(OfferDetailViewDialog));
