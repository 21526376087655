import { WithStyles, WithTheme } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import * as React from 'react';
import { ReactFragment } from 'react';
import { Grid, InputAdornment, TextField, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { Offer } from 'model/Offer';
import { getNumberDecimal } from 'util/helpers';
import { Amount, getAmountValidationValue } from 'model/Amount';
import { theme } from 'style/NearbuyStyle';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import FormHelperText from '@mui/material/FormHelperText';

const styles = (theme: Theme) =>
    createStyles({
        textField: {
            '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.primary.dark,
            },
        },
        heading: {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        refreshButtonIcon: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.dark,
            borderRadius: '50%',
        },
        refreshButton: {
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        grid: {
            marginTop: 0,
        },
        marginLeft: {
            marginLeft: '0.5rem',
        },
        marginTop: {
            marginTop: '1.5rem',
        },
        minMaxAmountText: {
            fontSize: '15px',
            marginBottom: '5px',
        },
        helperText: {
            color: theme.palette.primary.dark,
            fontSize: '0.6rem',
            fontWeight: 'bold',
        },
    });

interface AmountComponentProperties extends WithStyles<typeof styles>, WithTheme, WithTranslation {
    offer: Offer;
    isPriceShown: boolean;
    usedInContainerSelection: boolean;
    amountInOfferUnit?: number;
    changeHandler?: (amountIn: number | undefined) => void;
    isRecurringOrder?: boolean;
}

interface AmountComponentState {
    totalPrice: number;
    firstError: boolean;
}

export class AmountComponent extends ComponentBase<AmountComponentProperties, AmountComponentState> {
    protected _buildState(
        props: AmountComponentProperties,
        initialBuild: boolean,
        incomingState?: Readonly<AmountComponentState>,
    ): Partial<AmountComponentState> | undefined {
        const newState: Partial<AmountComponentState> = {
            ...incomingState,
        };
        if (initialBuild) {
            newState.totalPrice = 0;
            newState.firstError = true;
        }
        if (props.amountInOfferUnit) {
            newState.firstError = false;
        }

        newState.totalPrice = this.calculatePrice(props.amountInOfferUnit ?? 0);

        return newState;
    }

    validate(input: string): boolean {
        return !isNaN(parseInt(input));
    }

    getInputAmountValidationValue(): 0 | -1 | 1 | boolean {
        if (this.state.firstError) {
            return true;
        } else if (this.props.offer) {
            const inputAmount: Amount = new Amount(
                this.props.amountInOfferUnit ?? 0,
                this.props.offer.totalAmount.unit,
            );
            return getAmountValidationValue(inputAmount, this.props.offer.minAmount, this.props.offer.totalAmount);
        } else return false;
    }

    calculatePrice(amountInOfferUnit: number): number {
        let totalPrice = 0;
        const { graduatedPrices } = this.props.offer;

        if (graduatedPrices && graduatedPrices.length > 0) {
            graduatedPrices.forEach((graduatedPrice) => {
                if (amountInOfferUnit < graduatedPrices[0].amount) {
                    if (this.props.offer.pricePerUnit !== null) {
                        totalPrice = amountInOfferUnit * this.props.offer.pricePerUnit;
                    } else {
                        throw new Error('Price is not defined');
                    }
                } else if (amountInOfferUnit >= graduatedPrice.amount) {
                    totalPrice = amountInOfferUnit * graduatedPrice.price;
                }
            });
        } else if (this.props.offer.pricePerUnit !== null) {
            totalPrice = amountInOfferUnit * this.props.offer.pricePerUnit;
        }

        return totalPrice;
    }

    getInputHelperLocale(validationValue: boolean | 1 | -1 | 0): string {
        switch (validationValue) {
            case false:
                return '';
            case true:
                return 'common:unknownError';
            case 1:
                return 'offer:amountInputHelper_tooBig';
            case 0:
                return 'offer:amountInputHelper_zero';
            case -1:
                return 'offer:amountInputHelper_tooSmall';
        }
    }

    showTitle(): React.ReactFragment {
        return (
            !this.props.usedInContainerSelection && (
                <Grid item paddingBottom={theme.spacing(0.5)}>
                    <Typography className={this.props.classes.heading}>
                        {this.props.t('offer:amountSelection')}
                    </Typography>
                </Grid>
            )
        );
    }

    showTotalAmount(): React.ReactElement {
        const amountValidationValue = this.getInputAmountValidationValue();
        const isAmountValid = this.props.isRecurringOrder
            ? amountValidationValue === true || amountValidationValue === 1
            : amountValidationValue === true;

        return (
            <Grid item container>
                <Grid item xs={12}>
                    <NumberFormat
                        fullWidth
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        customInput={TextField}
                        label={
                            this.props.isRecurringOrder
                                ? this.props.t('offer:amountPerDelivery')
                                : this.props.t('offer:totalAmount')
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {this.props.t('units:' + this.props.offer.totalAmount.unit + 'Plural')}
                                </InputAdornment>
                            ),
                        }}
                        data-testid="amountInput"
                        size="small"
                        hiddenLabel
                        error={!isAmountValid}
                        className={this.props.classes.textField}
                        disabled={this.props.usedInContainerSelection}
                        value={this.props.amountInOfferUnit ?? 0}
                        onValueChange={(values: NumberFormatValues) => {
                            this.props.changeHandler?.(values.floatValue);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    {this.showHelperText()}
                </Grid>
            </Grid>
        );
    }

    showPrice(): React.ReactElement {
        return (
            <>
                {this.props.isPriceShown && (
                    <Grid item container>
                        <Grid item xs={12}>
                            <NumberFormat
                                fullWidth
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                customInput={TextField}
                                InputProps={{ endAdornment: '€\u00A0' }}
                                label={
                                    this.props.isRecurringOrder
                                        ? this.props.t('offer:totalPriceDelivery')
                                        : this.props.t('offer:totalPrice')
                                }
                                size="small"
                                disabled
                                className={this.props.classes.textField}
                                value={this.state.totalPrice}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormHelperText
                                disabled
                                className={this.props.classes.helperText}
                                sx={{ paddingLeft: '15px', marginTop: -0.5 }}
                            >
                                {this.props.t('preOrder:totalPriceFormHelper')}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                )}
            </>
        );
    }

    showMinMaxPurchaseAmount(): ReactFragment {
        const showAmountAndPurchaseInfoRow = (label: string, value: string) => (
            <Grid item xs={12} container alignItems="center">
                <Grid item xs={4}>
                    <Typography fontWeight={450}>{this.props.t(label) + ':'}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography fontWeight={450}>{value}</Typography>
                </Grid>
            </Grid>
        );

        return (
            <Grid container spacing={theme.spacing(1)} paddingTop={0.5}>
                {showAmountAndPurchaseInfoRow(
                    this.props.isRecurringOrder ? 'preOrder:maximumAmount' : 'offer:maximumAmount',
                    `${getNumberDecimal(this.props.offer.totalAmount.amount, 2, 2)} ${
                        this.props.offer.totalAmount.unit &&
                        this.props.t('shortunits:' + this.props.offer.totalAmount.unit)
                    }`,
                )}
                {showAmountAndPurchaseInfoRow(
                    this.props.isRecurringOrder ? 'preOrder:minimumPurchase' : 'offer:minimumPurchase',
                    `${getNumberDecimal(this.props.offer?.minAmount.amount, 2, 2)} ${
                        this.props.offer.totalAmount.unit &&
                        this.props.t('shortunits:' + this.props.offer.minAmount.unit)
                    }`,
                )}
            </Grid>
        );
    }

    showHelperText(): React.ReactFragment {
        const amountValidationValue = this.getInputAmountValidationValue();
        const isAmountValid = this.props.isRecurringOrder
            ? amountValidationValue === true || amountValidationValue === 1
            : amountValidationValue === true;
        return (
            <Grid item>
                <Typography color={'red'} fontSize={'small'} data-testid={'helperText'}>
                    {isAmountValid ? '\u2000' : this.props.t(this.getInputHelperLocale(amountValidationValue))}
                </Typography>
            </Grid>
        );
    }

    render(): React.ReactElement {
        return (
            <Grid container item direction={'column'} justifyContent={'center'}>
                {this.showTitle()}
                <Grid container justifyContent="center">
                    <Grid item xs={6} sx={{ paddingRight: 6 }}>
                        {this.showTotalAmount()}
                    </Grid>
                    <Grid item xs={6} sx={{ paddingLeft: 6 }}>
                        {this.showPrice()}
                    </Grid>
                </Grid>
                {this.showMinMaxPurchaseAmount()}
            </Grid>
        );
    }
}

export default withTranslation(['offer', 'units', 'shortunits', 'preOrder'])(
    withStyles(styles, { withTheme: true })(AmountComponent),
);
