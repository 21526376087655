import { ArrowDropDown, ArrowDropUp, Chat, CloudDownload, CloudUpload } from '@mui/icons-material';
import Check from '@mui/icons-material/Check';
import { Box, Button, Collapse, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import PriceRequestService from 'api/PriceRequestService';
import ProvidePriceDialog from 'components/marketplace/pre-order/dialog/ProvidePriceDialog';
import TradeDetailsTable from 'components/marketplace/tradetable/TradeDetailsTable';
import PreOrderInfoCard from 'components/messaging/chat/messagefactory/infocards/PreOrderInfoCard';
import ConfirmationDialog from 'components/messaging/chat/messagefactory/infocards/shared/ConfirmationDialog';
import { Company } from 'model/Company';
import { Product } from 'model/Product';
import { PurchaseIntent, PurchaseIntentStatus } from 'model/PurchaseIntent';
import moment, { now } from 'moment';
import * as React from 'react';
import { ReactElement, ReactFragment, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import {
    CompanyStore,
    InvoiceStore,
    MetaStore,
    NotificationStore,
    OrderStore,
    PersonStore,
    PriceRequestStore,
    ProductStore,
    PurchaseIntentStore,
} from 'store';
import { Order, OrderStatus } from 'model/Order';
import { LevelOfProcessing } from 'model/LevelOfProcessing';
import { Link } from 'react-router-dom';
import TradeActionButtonComponent from 'components/messaging/chat/TradeActionButtonsComponent';
import { getQueryStringStringified, getUuidFromString } from 'util/helpers';
import { Person } from 'model/Person';
import purchaseIntentService from 'api/PurchaseIntentService';
import {
    InvoiceSentNotification,
    MessageSentNotification,
    NotificationInfo,
    OrderMarkedAsActiveNotification,
    OrderMarkedAsCompleteNotification,
} from 'model/NearbuyNotification';
import MessageService from 'api/MessageService';
import { theme } from 'style/NearbuyStyle';
import { Invoice } from 'model/Invoice';
import InvoiceService from 'api/InvoiceService';
import { ContainerType } from 'model/ContainerView';
import { MetaStaticEntityType } from 'model/Meta';
import SplitButtonComponent from 'components/shared/SplitButtonComponent';
import Grid from '@mui/material/Grid';
import OrderService from 'api/OrderService';
import { PreOrderTranslationMapping } from 'components/marketplace/shared/PreOrderTranslationMapping';
import { PriceRequest, PriceRequestStatus } from 'model/PriceRequest';
import { captureWebEvent } from 'util/AnalyticUtils';

const styles = () =>
    createStyles({
        boldTypography: {
            fontWeight: 'bold',
        },
        noTextTransform: {
            textTransform: 'none',
            padding: 0,
            textDecorationLine: 'underline',
            justifyContent: 'flex-start',
        },
        tableCell: {
            whiteSpace: 'pre-wrap',
            border: 'none',
            '&.MuiTableCell-root': {
                paddingBottom: 5,
            },
        },
        transactionRowRoundings: {
            '& td:first-of-type': {
                borderRadius: '12px 0 0 0 !important',
                border: 'none',
            },
            '& td:last-child': {
                borderRadius: '0 12px 0 0',
                border: 'none',
            },
        },
        collapseRowRoundings: {
            'td:first-of-type': {
                borderRadius: '0',
                border: 'none',
            },
            '& td:last-child': {
                borderRadius: '0',
                border: 'none',
            },
        },
        buttonRowRoundings: {
            '& td:last-child': {
                borderRadius: '0 0 12px 12px',
                border: 'none',
            },
        },
    });

interface TradeTableRowProperties extends WithStyles<typeof styles>, WithTranslation {
    role: 'BUYER' | 'SELLER';
    purchaseIntentRef?: string;
    priceRequestRef?: string;
    orderRef?: string;
    styling?: React.CSSProperties;
    isCollapseOpen?: boolean;
}

interface TradeTableRowState {
    currentUser: Person;
    userRef: string;
    ownCompanyRef: string;
    purchaseIntent?: PurchaseIntent;
    priceRequest?: PriceRequest;
    order?: Order;
    isCollapseOpen: boolean;
    data: TradeTableRowData;
    sellingPerson: Person;
    buyingPerson: Person;
    mapping?: PreOrderTranslationMapping;
    isPurchaseIntentConfirmationDialogOpen: boolean;
    isPriceRequestConfirmationDialogOpen: boolean;
    isProvidePriceDialogOpen: boolean;
    isInfoCardDialogOpen: boolean;
    invoice?: Invoice;
    areButtonsEnabled: boolean;
    containerTypes: ContainerType[];
    disabledTradeButtonTooltipText: string;
    orderId: string;
}

interface TradeTableRowData {
    product?: Product;
    levelsOfProcessing?: LevelOfProcessing[];
    amount?: number;
    unit?: string;
    pricePerUnit?: number;
    totalPrice?: number;
    date?: Date;
    foreignCompany?: Company;
    status?: PurchaseIntentStatus | OrderStatus | PriceRequestStatus;
}

export class TradeTableRow extends ComponentBase<TradeTableRowProperties, TradeTableRowState> {
    protected _buildState(
        props: TradeTableRowProperties,
        initialBuild: boolean,
        incomingState?: TradeTableRowState,
    ): Partial<TradeTableRowState> | undefined {
        const newState: Partial<TradeTableRowState> = {
            ...incomingState,
            orderId: getUuidFromString(props.orderRef),
        };

        if (initialBuild) {
            newState.data = {};
            newState.areButtonsEnabled = false;
            newState.isCollapseOpen = this.props.isCollapseOpen ?? false;
        }

        if (!newState.data) return;

        newState.currentUser = PersonStore.getSelected();
        newState.userRef = PersonStore.getSelected()?.links.self;
        if (!newState.userRef) return;

        newState.ownCompanyRef = CompanyStore.getSelected()?.links.self;
        if (!newState.ownCompanyRef) return;

        newState.purchaseIntent = props.purchaseIntentRef
            ? PurchaseIntentStore.getOne(props.purchaseIntentRef)
            : undefined;
        newState.priceRequest = props.priceRequestRef ? PriceRequestStore.getOne(props.priceRequestRef) : undefined;
        newState.order = props.orderRef ? OrderStore.getOne(props.orderRef) : undefined;

        if (newState.purchaseIntent) {
            newState.areButtonsEnabled =
                props.role == 'SELLER'
                    ? newState.userRef == newState.purchaseIntent.links.sellingPerson
                    : newState.userRef == newState.purchaseIntent.links.buyingPerson;
            const productRef = newState.purchaseIntent.links.category;
            if (productRef) {
                newState.data.product = ProductStore.getOne(productRef);
            }
            newState.data.amount = newState.purchaseIntent.amount.amount;
            newState.data.pricePerUnit = newState.purchaseIntent.totalPrice / newState.purchaseIntent.amount.amount;
            newState.data.totalPrice = newState.purchaseIntent.totalPrice;
            newState.data.date = newState.purchaseIntent.dateCreated;
            const foreignCompanyRef =
                props.role == 'SELLER'
                    ? newState.purchaseIntent.links.buyingCompany
                    : newState.purchaseIntent.links.sellingCompany;
            if (foreignCompanyRef) {
                newState.data.foreignCompany = CompanyStore.getOne(foreignCompanyRef);
            }
            newState.data.unit = newState.purchaseIntent.amount.unit;
            newState.data.levelsOfProcessing = newState.purchaseIntent.levelsOfProcessing;
            newState.data.status = newState.purchaseIntent.status;
            newState.sellingPerson = newState.purchaseIntent.links.sellingPerson
                ? PersonStore.getOne(newState.purchaseIntent.links.sellingPerson)
                : undefined;
            newState.buyingPerson = newState.purchaseIntent.links.buyingPerson
                ? PersonStore.getOne(newState.purchaseIntent.links.buyingPerson)
                : undefined;
        }

        if (newState.priceRequest) {
            newState.areButtonsEnabled =
                props.role == 'SELLER'
                    ? newState.userRef == newState.priceRequest.links.sellingPerson
                    : newState.userRef == newState.priceRequest.links.buyingPerson;
            newState.data.product = ProductStore.getOne(newState.priceRequest.links.category);
            newState.data.amount = newState.priceRequest.amount.amount;
            newState.data.pricePerUnit = newState.priceRequest.totalPrice
                ? newState.priceRequest.totalPrice / newState.priceRequest.amount.amount
                : undefined;
            newState.data.totalPrice =
                newState.priceRequest.totalPrice != null ? newState.priceRequest.totalPrice : undefined;
            newState.data.date = newState.priceRequest.dateCreated;
            newState.data.foreignCompany = CompanyStore.getOne(
                props.role == 'SELLER'
                    ? newState.priceRequest.links.buyingCompany
                    : newState.priceRequest.links.sellingCompany,
            );
            newState.data.unit = newState.priceRequest.amount.unit;
            newState.data.levelsOfProcessing = newState.priceRequest.levelsOfProcessing;
            newState.data.status = newState.priceRequest.status;
        }

        if (newState.order) {
            newState.areButtonsEnabled =
                props.role == 'SELLER'
                    ? newState.userRef == newState.order.links.sellingPerson
                    : newState.userRef == newState.order.links.buyingPerson;
            newState.data.product = newState.order.links.category
                ? ProductStore.getOne(newState.order.links.category)
                : undefined;
            newState.data.amount = newState.order.amount.amount;
            newState.data.pricePerUnit = newState.order.pricePerUnit;
            newState.data.totalPrice = newState.order.totalPrice;
            newState.data.date = newState.order.dateCreated;
            const foreignCompanyRef =
                props.role == 'SELLER' ? newState.order.links.buyingCompany : newState.order.links.sellingCompany;
            newState.data.foreignCompany = foreignCompanyRef ? CompanyStore.getOne(foreignCompanyRef) : undefined;
            newState.data.unit = newState.order.amount.unit;
            newState.data.levelsOfProcessing = newState.order.levelsOfProcessing;
            newState.data.status = newState.order.status;
            newState.sellingPerson = newState.order.links.sellingPerson
                ? PersonStore.getOne(newState.order.links.sellingPerson)
                : undefined;
            newState.buyingPerson = newState.order.links.buyingPerson
                ? PersonStore.getOne(newState.order.links.buyingPerson)
                : undefined;
        }
        if (newState.purchaseIntent && newState.purchaseIntent.links.buyingPerson && !newState.buyingPerson) {
            newState.buyingPerson = PersonStore.getOne(newState.purchaseIntent.links.buyingPerson);
        }

        if (newState.priceRequest && !newState.sellingPerson) {
            newState.sellingPerson = PersonStore.getOne(newState.priceRequest.links.sellingPerson);
        }
        if (newState.priceRequest && newState.priceRequest.links.buyingPerson && !newState.buyingPerson) {
            newState.buyingPerson = PersonStore.getOne(newState.priceRequest.links.buyingPerson);
        }

        if (newState.order && newState.order.links.invoice) {
            newState.invoice = InvoiceStore.getOne(newState.order.links.invoice);
        }

        if (!newState.containerTypes) {
            const metaContainerTypes = MetaStore.getOne(MetaStaticEntityType.CONTAINER_TYPE);
            if (!metaContainerTypes) return newState;
            newState.containerTypes = metaContainerTypes.data as ContainerType[];
        }

        newState.mapping = {
            firstNameSeller: newState.sellingPerson?.firstname ?? this.props.t('company:deletedPerson'),
            lastNameSeller: newState.sellingPerson?.lastname ?? ' ',
            firstNameBuyer: newState.buyingPerson?.firstname ?? this.props.t('company:deletedPerson'),
            lastNameBuyer: newState.buyingPerson?.lastname ?? ' ',
            product: newState.data.product
                ? props.t(newState.data.product.label, { ns: 'ontofood' })
                : props.orderRef
                  ? newState.order?.productLabel
                      ? props.t(newState.order.productLabel, { ns: 'ontofood' })
                      : props.t('common:deletedProduct')
                  : props.t('common:deletedProduct'),
        };

        if (newState.sellingPerson || newState.buyingPerson) {
            newState.disabledTradeButtonTooltipText = props.t(
                'sales:responsiblePersonTooltipHint',
                props.role == 'SELLER'
                    ? {
                          firstname: newState.sellingPerson?.firstname,
                          lastname: this.state.sellingPerson?.lastname,
                      }
                    : {
                          firstname: newState.buyingPerson?.firstname,
                          lastname: newState.buyingPerson?.lastname,
                      },
            );
        }

        return newState;
    }

    showPrice(): React.ReactFragment | null {
        return (
            <Typography>
                {this.state.data.pricePerUnit && this.state.data.unit
                    ? this.state.data.pricePerUnit.toLocaleString('de-DE', {
                          style: 'currency',
                          currency: 'EUR',
                      }) +
                      ' ' +
                      this.props.t('offer:per') +
                      ' ' +
                      this.props.t('shortunits:' + this.state.data.unit)
                    : this.props.t('-')}
            </Typography>
        );
    }

    showLops(): React.ReactFragment | null {
        if (!this.state.data.levelsOfProcessing) return null;
        return this.state.data.levelsOfProcessing.length >= 2 ? (
            <Typography>
                {this.props.t('levelsOfProcessing:' + this.state.data.levelsOfProcessing[0].label) +
                    ', ' +
                    this.props.t('levelsOfProcessing:' + this.state.data.levelsOfProcessing[1].label)}
            </Typography>
        ) : this.state.data.levelsOfProcessing.length == 1 ? (
            <Typography>{this.props.t('levelsOfProcessing:' + this.state.data.levelsOfProcessing[0].label)}</Typography>
        ) : null;
    }

    getPriceRequestQueryString() {
        if (this.state.priceRequest) {
            const queryString: Record<string, string> = {};
            queryString['price_request'] = this.state.priceRequest.links.self;
            return '?' + getQueryStringStringified(queryString);
        } else return undefined;
    }

    getPurchaseIntentQueryString() {
        if (this.state.purchaseIntent) {
            const queryString: Record<string, string> = {};
            queryString['purchase_intent'] = this.state.purchaseIntent.links.self;
            return '?' + getQueryStringStringified(queryString);
        } else return undefined;
    }

    showPurchaseIntentButtons(): ReactNode {
        if (!this.state.purchaseIntent) return null;
        switch (this.state.purchaseIntent.status) {
            case PurchaseIntentStatus.PENDING:
                return this.props.role == 'SELLER' ? (
                    <TradeActionButtonComponent
                        buttons={[
                            {
                                tooltip: this.state.areButtonsEnabled
                                    ? undefined
                                    : this.state.disabledTradeButtonTooltipText,
                                text: this.props.t('purchaseIntent:accept'),
                                type: 'primaryLight',
                                disabled: !this.state.areButtonsEnabled,
                                onClick: () => {
                                    this.updatePurchaseIntent(PurchaseIntentStatus.ACCEPTED);
                                },
                            },
                            {
                                text: this.props.t('purchaseIntent:reject'),
                                type: 'transparent',
                                tooltip: this.state.areButtonsEnabled
                                    ? this.props.t('purchaseIntent:cancelNotice')
                                    : this.state.disabledTradeButtonTooltipText,
                                onClick: () => {
                                    this.setState({
                                        isPurchaseIntentConfirmationDialogOpen: true,
                                    });
                                },
                                disabled: !this.state.areButtonsEnabled,
                            },
                        ]}
                        justifyHorizontal={'start'}
                        marginTop={'half'}
                    />
                ) : null;
            case PurchaseIntentStatus.ACCEPTED:
                return this.props.role == 'BUYER' ? (
                    <TradeActionButtonComponent
                        buttons={[
                            {
                                text: this.props.t('purchaseIntent:buy'),
                                type: 'secondaryMain',
                                link: `/order${this.getPurchaseIntentQueryString()}`,
                                disabled: !this.state.areButtonsEnabled,
                                tooltip: this.state.areButtonsEnabled
                                    ? undefined
                                    : this.state.disabledTradeButtonTooltipText,
                            },
                            {
                                text: this.props.t('purchaseIntent:cancelByBuyer'),
                                gridWidth: 1.5,
                                type: 'transparent',
                                tooltip: this.state.areButtonsEnabled
                                    ? this.props.t('purchaseIntent:cancelNoticeBuyer')
                                    : this.state.disabledTradeButtonTooltipText,
                                onClick: () => {
                                    captureWebEvent('tradeTable-cancelByBuyer-button');
                                    this.setState({
                                        isPurchaseIntentConfirmationDialogOpen: true,
                                    });
                                },
                                disabled: !this.state.areButtonsEnabled,
                            },
                        ]}
                        justifyHorizontal={'start'}
                        marginTop={'half'}
                    />
                ) : (
                    <TradeActionButtonComponent
                        buttons={[
                            {
                                text: this.props.t('purchaseIntent:cancelBySeller'),
                                gridWidth: 1.4,
                                type: 'transparent',
                                tooltip: this.state.areButtonsEnabled
                                    ? this.props.t('purchaseIntent:cancelNoticeSeller')
                                    : this.state.disabledTradeButtonTooltipText,
                                onClick: () => {
                                    captureWebEvent('my_market-tradeTable-cancelBySeller-button');
                                    this.setState({
                                        isPurchaseIntentConfirmationDialogOpen: true,
                                    });
                                },
                                disabled: !this.state.areButtonsEnabled,
                            },
                        ]}
                        justifyHorizontal={'start'}
                        marginTop={'half'}
                    />
                );
            default:
                return null;
        }
    }

    showConfirmationDialogForCancelPriceRequest(): ReactNode {
        if (!this.state.priceRequest) return null;
        return (
            <ConfirmationDialog
                key={this.state.priceRequest.links.self}
                isOpen={this.state.isPriceRequestConfirmationDialogOpen}
                onClose={() => this.onClose()}
                title={this.props.t(
                    this.props.role == 'BUYER'
                        ? 'dialogs:cancelPriceRequestTitleBuyer'
                        : 'dialogs:rejectPriceRequestTitleSeller',
                    this.state.mapping,
                )}
                content={this.props.t(
                    this.props.role == 'BUYER'
                        ? 'dialogs:cancelPriceRequestContentBuyer'
                        : 'dialogs:rejectPriceRequestContentSeller',
                    this.state.mapping,
                )}
                buttonText1={this.props.t(
                    this.props.role == 'BUYER'
                        ? 'dialogs:rejectPriceProposalButton'
                        : 'dialogs:rejectPriceRequestButton',
                )}
                buttonAction1={() => {
                    this.updatePriceRequestStatus(
                        this.props.role == 'BUYER' ? PriceRequestStatus.CANCELED_BY_BUYER : PriceRequestStatus.REJECTED,
                    );
                    this.setState({
                        isPriceRequestConfirmationDialogOpen: false,
                    });
                }}
                calledFrom={'TradeTableRow'}
            />
        );
    }

    showInfoCardForProvidePriceDialog(): ReactElement {
        return (
            <Grid>
                <PreOrderInfoCard
                    withShowOfferOrDetailsButton={true}
                    hasBackground={true}
                    priceRequestRef={this.state.priceRequest!.links.self}
                    onDecline={() => this.onClose()}
                />
            </Grid>
        );
    }

    showProvidePriceDialog(): ReactNode {
        if (this.state.priceRequest && this.state.mapping)
            return (
                <ProvidePriceDialog
                    key={this.state.priceRequest.links.self + 'providePrice'}
                    priceRequestRef={this.state.priceRequest.links.self}
                    infoCard={this.showInfoCardForProvidePriceDialog()}
                    onClose={() => this.onClose()}
                    isOpen={this.state.isProvidePriceDialogOpen}
                    mapping={this.state.mapping}
                />
            );
    }

    updatePriceRequestStatus(status: PriceRequestStatus): void {
        if (this.state.priceRequest) {
            const isBuyerSender = status == 'CANCELED_BY_BUYER';
            PriceRequestService.updateStatus(this.state.priceRequest.links.self, { status }).subscribe({
                next: () => {
                    NotificationStore.setOne(new NotificationInfo(MessageSentNotification(), now()));
                    MessageService.invalidateDialogByPreOrder(this.state.priceRequest!, isBuyerSender);
                },
            });
        }
    }

    showPriceRequestButtons(): ReactNode {
        if (!this.state.priceRequest) return null;
        switch (this.state.priceRequest.status) {
            case PriceRequestStatus.PENDING:
                return this.props.role == 'SELLER' ? (
                    <Grid container justifyContent={'flex-start'} alignItems={'center'}>
                        <Grid item>
                            <TradeActionButtonComponent
                                buttons={[
                                    {
                                        tooltip: this.state.areButtonsEnabled
                                            ? undefined
                                            : this.state.disabledTradeButtonTooltipText,
                                        text: this.props.t('priceRequest:addPriceRequestSeller'),
                                        type: 'primaryLight',
                                        disabled: !this.state.areButtonsEnabled,
                                        onClick: () => {
                                            this.setState({
                                                isProvidePriceDialogOpen: true,
                                            });
                                        },
                                    },
                                    {
                                        text: this.props.t('priceRequest:reject'),
                                        type: 'transparent',
                                        tooltip: this.state.areButtonsEnabled
                                            ? this.props.t('purchaseIntent:cancelNotice')
                                            : this.state.disabledTradeButtonTooltipText,
                                        onClick: () => {
                                            this.setState({
                                                isPriceRequestConfirmationDialogOpen: true,
                                            });
                                        },
                                        disabled: !this.state.areButtonsEnabled,
                                    },
                                ]}
                                justifyHorizontal={'start'}
                            />
                        </Grid>
                        <Grid item>{this.getChatButton()}</Grid>
                    </Grid>
                ) : null;
            case PriceRequestStatus.PRICE_ADDED:
                return this.props.role == 'BUYER' ? (
                    <Grid container justifyContent={'flex-start'} alignItems={'center'}>
                        <Grid item>
                            <TradeActionButtonComponent
                                buttons={[
                                    {
                                        link: `/order${this.getPriceRequestQueryString()}`,
                                        text: this.props.t('priceRequest:acceptAndBuy'),
                                        type: 'secondaryMain',
                                        disabled: !this.state.areButtonsEnabled,
                                    },
                                    {
                                        text: this.props.t('priceRequest:reject'),
                                        type: 'transparent',
                                        tooltip: this.props.t('priceRequest:cancelNoticeBuyer'),
                                        onClick: () => {
                                            this.setState({
                                                isPriceRequestConfirmationDialogOpen: true,
                                            });
                                        },
                                        disabled: !this.state.areButtonsEnabled,
                                    },
                                ]}
                                justifyHorizontal={'start'}
                            />
                        </Grid>
                        <Grid item>{this.getChatButton}</Grid>
                    </Grid>
                ) : null;
            default:
                return null;
        }
    }

    getChatButton(): ReactFragment | null {
        return process.env.REACT_APP_NEARBUY_FEATURE_SHOW_UNFINISHED_FEATURES === 'true'
            ? this.state.isCollapseOpen && (
                  <Tooltip
                      title={
                          this.state.areButtonsEnabled
                              ? this.props.t('common:comingSoonTooltipHint')
                              : this.state.disabledTradeButtonTooltipText
                      }
                  >
                      <span>
                          <Button variant={'outlined'} startIcon={<Chat />} disabled={!this.state.areButtonsEnabled}>
                              {this.props.t('common:toChat')}
                          </Button>
                      </span>
                  </Tooltip>
              )
            : null;
    }

    showCompleteButton(): ReactNode {
        if (!this.state.order) return null;
        return (
            <Tooltip
                title={
                    this.state.areButtonsEnabled
                        ? this.props.t(
                              this.props.role == 'SELLER'
                                  ? this.state.order.isCompleted
                                      ? 'order:markNotCompletedTooltipSeller'
                                      : 'order:markCompletedTooltipSeller'
                                  : this.state.order.isCompleted
                                    ? 'order:markNotCompletedTooltipBuyer'
                                    : 'order:markCompletedTooltipBuyer',
                          )
                        : this.state.disabledTradeButtonTooltipText
                }
            >
                <span>
                    <Button
                        startIcon={<Check />}
                        disabled={!this.state.areButtonsEnabled}
                        variant={'outlined'}
                        sx={{ marginRight: theme.spacing(1) }}
                        onClick={() => {
                            {
                                this.state.order?.isCompleted
                                    ? captureWebEvent('my_market-tradeTable-sales-markAsActive')
                                    : captureWebEvent('my_market-tradeTable-sales-markAsCompleted');
                            }
                            OrderService.setCompleted(
                                this.state.order!.links.self,
                                !this.state.order!.isCompleted,
                            ).subscribe();
                            NotificationStore.setOne(
                                new NotificationInfo(
                                    this.state.order!.isCompleted
                                        ? OrderMarkedAsActiveNotification(
                                              this.state.currentUser == this.state.sellingPerson,
                                              false,
                                          )
                                        : OrderMarkedAsCompleteNotification(
                                              this.state.currentUser == this.state.sellingPerson,
                                              true,
                                          ),
                                    now(),
                                ),
                            );
                        }}
                    >
                        <Typography>
                            {this.state.order.isCompleted
                                ? this.props.t('order:markAsActive')
                                : this.props.t('order:markAsCompleted')}
                        </Typography>
                    </Button>
                </span>
            </Tooltip>
        );
    }

    showOrderButtons(): ReactFragment | null {
        if (!this.state.order) return null;
        const handleDownloadInvoiceClick = () => {
            const link = document.createElement('a');
            link.download = this.state.invoice!.fileName as string;
            link.href = this.state.invoice!.downloadLink?.url as string;
            link.click();
        };
        switch (this.state.order.status) {
            case OrderStatus.PENDING:
                return this.props.role == 'SELLER' ? (
                    <Grid container justifyContent={'flex-start'} alignItems={'center'}>
                        <Grid item>
                            <TradeActionButtonComponent
                                buttons={[
                                    {
                                        text: this.props.t('order:uploadInvoice'),
                                        type: 'primaryLight',
                                        iconStart: <CloudUpload />,
                                        fileInputOnChange: this.invoiceFileInputOnChange,
                                        fileInputAccept: 'application/pdf',
                                        tooltip: this.state.areButtonsEnabled
                                            ? this.props.t('common:pdfUploadTooltipHint')
                                            : this.state.disabledTradeButtonTooltipText,
                                        disabled: !this.state.areButtonsEnabled,
                                        onClick: () => captureWebEvent('upload-invoice-from-tradeTableRow'),
                                    },
                                ]}
                                justifyHorizontal={'end'}
                            />
                        </Grid>
                        <Grid item>{this.showCompleteButton()}</Grid>
                        <Grid item>{this.getChatButton()}</Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent={'flex-start'}>
                        <Grid item>{this.showCompleteButton()}</Grid>
                        <Grid item>{this.getChatButton()}</Grid>
                    </Grid>
                );
            case OrderStatus.INVOICE_ADDED:
            case OrderStatus.INVOICE_EXCHANGED:
                return this.props.role == 'SELLER' ? (
                    <Grid container justifyContent={'flex-start'}>
                        <Grid item>
                            <Tooltip
                                title={
                                    this.state.areButtonsEnabled ? undefined : this.state.disabledTradeButtonTooltipText
                                }
                            >
                                <span>
                                    <SplitButtonComponent
                                        sxPropsButtonGroup={{
                                            marginRight: theme.spacing(1),
                                        }}
                                        options={[
                                            {
                                                label: this.props.t('order:downloadInvoice'),
                                                action: handleDownloadInvoiceClick,
                                                icon: <CloudDownload key={'downloadInvoiceIcon'} />,
                                            },
                                            {
                                                label: this.props.t('order:exchangeInvoice'),
                                                action: () => {
                                                    const input = document.getElementById(
                                                        'invoice-input' + this.state.orderId,
                                                    );
                                                    input?.click();
                                                },
                                                icon: <CloudUpload key={'exchangeInvoiceIcon'} />,
                                                tooltip: this.props.t('common:pdfUploadTooltipHint'),
                                            },
                                        ]}
                                        buttonDefaultText={this.props.t('order:invoice')}
                                        justifyHorizontal={'end'}
                                        disabled={!this.state.areButtonsEnabled}
                                    />
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item>{this.showCompleteButton()}</Grid>
                        <Grid item>{this.getChatButton()}</Grid>
                    </Grid>
                ) : (
                    <Grid container direction={'row'}>
                        <Grid item>
                            <TradeActionButtonComponent
                                buttons={[
                                    {
                                        text: this.props.t('order:downloadInvoice'),
                                        type: 'primaryLight',
                                        iconStart: <CloudDownload />,
                                        onClick: handleDownloadInvoiceClick,
                                        disabled: !this.state.areButtonsEnabled,
                                        tooltip: this.state.areButtonsEnabled
                                            ? undefined
                                            : this.state.disabledTradeButtonTooltipText,
                                    },
                                ]}
                                justifyHorizontal={'start'}
                                marginTop={'none'}
                            />
                        </Grid>
                        <Grid item>{this.showCompleteButton()}</Grid>
                        <Grid item>{this.getChatButton()}</Grid>
                    </Grid>
                );
            default:
                return null;
        }
    }

    getInvoiceFileInput(): React.ReactElement {
        return (
            <input
                id={'invoice-input' + this.state.orderId}
                type="file"
                style={{ display: 'none' }}
                accept={'application/pdf'}
                onChange={this.invoiceFileInputOnChange}
            />
        );
    }

    isFileSizeAllowed(file: File): boolean {
        return file.size < 5242880;
    }

    invoiceFileInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (event.target.files && event.target.files[0]) {
            const file: File = event.target.files[0];
            if (!this.state.order || !this.isFileSizeAllowed(file)) {
                return;
            }

            InvoiceService.uploadInvoice(
                this.state.order.links.self,
                new Blob([file], { type: file.type }),
                file.name,
            ).subscribe({
                next: (value) => {
                    MessageService.invalidateDialogByOrder(value, false);
                    NotificationStore.setOne(new NotificationInfo(InvoiceSentNotification(), now()));
                    if (this.props.orderRef) {
                        OrderStore.loadOne(this.props.orderRef);
                    }
                },
                error: (err) => {
                    // TODO: placeholder for a propper ui-error (https://youtrack.ninjaneers.de/youtrack/issue/NEARBUY-3442)
                    console.log(`[invoice] error[${err.value}]: ${err.message}`);
                },
            });
        }
    };

    updatePurchaseIntent(status: PurchaseIntentStatus): void {
        if (!this.state.purchaseIntent) return;
        purchaseIntentService
            .updatePurchaseIntentStatus(this.state.purchaseIntent.links.update, {
                status,
            })
            .subscribe({
                next: () => {
                    NotificationStore.setOne(new NotificationInfo(MessageSentNotification(), now()));
                    this.setState({
                        isPurchaseIntentConfirmationDialogOpen: false,
                    });
                    MessageService.invalidateDialogByPreOrder(this.state.purchaseIntent!, this.props.role == 'BUYER');
                },
            });
    }

    onClose(): void {
        this.setState({
            isPurchaseIntentConfirmationDialogOpen: false,
            isPriceRequestConfirmationDialogOpen: false,
            isProvidePriceDialogOpen: false,
        });
    }

    getStatusText(): string {
        const status = this.state.data.status?.toString();
        if (!status || !(this.state.purchaseIntent || this.state.priceRequest || this.state.order)) return '';
        const nameSpace = this.state.order ? 'order:' : this.state.purchaseIntent ? 'purchaseIntent:' : 'priceRequest:';
        const key = this.state.order
            ? status == 'PENDING'
                ? this.props.role == 'BUYER'
                    ? 'orderSent'
                    : 'orderReceived'
                : 'INVOICE'
            : this.state.purchaseIntent
              ? ['CANCELED_BY_SELLER', 'CANCELED_BY_BUYER'].includes(status)
                  ? 'CANCELED'
                  : this.state.data.status!.toString()
              : this.state.data.status!.toString();

        return this.props.t(nameSpace + key);
    }

    getKey(): string {
        const item: PurchaseIntent | PriceRequest | Order | undefined =
            this.state.purchaseIntent ?? this.state.priceRequest ?? this.state.order ?? undefined;
        return item ? item.links.self + '#' + Math.random().toString() : Math.random().toString();
    }

    private getDetailsTable(): ReactNode {
        if (!this.state.purchaseIntent && !this.state.priceRequest && !this.state.order) return null;
        const item = this.state.purchaseIntent ?? this.state.priceRequest ?? this.state.order!;
        return (
            <TradeDetailsTable
                unit={item.amount.unit}
                buyingPerson={this.state.buyingPerson}
                sellingPerson={this.state.sellingPerson}
                ownCompanyRef={this.state.ownCompanyRef}
                containerSelections={item.containers}
                containerTypes={this.state.containerTypes}
                priceRequest={this.state.priceRequest}
                purchaseIntent={this.state.purchaseIntent}
                order={this.state.order}
                isContainerSectionShown={!!item.containers.length}
                isHidden={!this.state.areButtonsEnabled}
            />
        );
    }

    showConfirmationDialogForCancelPurchaseIntent(): ReactNode {
        return (
            <ConfirmationDialog
                isOpen={this.state.isPurchaseIntentConfirmationDialogOpen}
                onClose={() => this.onClose()}
                title={
                    this.props.role == 'BUYER'
                        ? this.props.t('dialogs:cancelPurchaseIntentTitleBuyer', this.state.mapping)
                        : this.state.purchaseIntent?.status == PurchaseIntentStatus.PENDING
                          ? this.props.t('dialogs:rejectPurchaseIntentTitleSeller', this.state.mapping)
                          : this.props.t('dialogs:cancelPurchaseIntentTitleSeller', this.state.mapping)
                }
                content={
                    this.props.role == 'BUYER'
                        ? this.props.t('dialogs:cancelPurchaseIntentContentBuyer', this.state.mapping)
                        : this.state.purchaseIntent?.status == PurchaseIntentStatus.PENDING
                          ? this.props.t('dialogs:rejectPurchaseIntentContentSeller', this.state.mapping)
                          : this.props.t('dialogs:cancelPurchaseIntentContentSeller', this.state.mapping)
                }
                buttonText1={
                    this.props.role == 'BUYER'
                        ? this.props.t('dialogs:cancelPurchaseIntentButton')
                        : this.state.purchaseIntent?.status == PurchaseIntentStatus.PENDING
                          ? this.props.t('dialogs:rejectPurchaseIntentButton')
                          : this.props.t('dialogs:cancelPurchaseIntentButton')
                }
                buttonAction1={() =>
                    this.updatePurchaseIntent(
                        this.props.role == 'BUYER'
                            ? PurchaseIntentStatus.CANCELED_BY_BUYER
                            : this.state.purchaseIntent?.status == PurchaseIntentStatus.PENDING
                              ? PurchaseIntentStatus.REJECTED
                              : PurchaseIntentStatus.CANCELED_BY_SELLER,
                    )
                }
                calledFrom={'TradeTableRow'}
            />
        );
    }

    render(): React.ReactElement | null {
        if (
            !!(this.state.purchaseIntent || this.state.order || this.state.priceRequest) &&
            !!this.state.data.amount &&
            !!this.state.data.date &&
            !!this.state.data.status
        ) {
            return (
                <>
                    {/* TODO: fix <input> nested in <tbody>: NEARBUY-4017 */}
                    {this.getInvoiceFileInput()}
                    <TableRow
                        key={this.getKey()}
                        style={this.props.styling}
                        className={this.props.classes.transactionRowRoundings}
                    >
                        <TableCell className={this.props.classes.tableCell}>
                            <Typography className={this.props.classes.boldTypography}>
                                {this.state.mapping?.product ?? this.props.t('common:deletedProduct')}
                            </Typography>
                            {this.showLops()}
                        </TableCell>
                        <TableCell className={this.props.classes.tableCell}>
                            <Typography>
                                {this.state.data.amount.toLocaleString('de-DE') +
                                    ' ' +
                                    this.props.t('shortunits:' + this.state.data.unit)}
                            </Typography>
                        </TableCell>
                        <TableCell className={this.props.classes.tableCell}>{this.showPrice()}</TableCell>
                        <TableCell className={this.props.classes.tableCell}>
                            <Typography>
                                {this.state.data.totalPrice != undefined
                                    ? this.state.data.totalPrice.toLocaleString('de-DE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                      })
                                    : '-'}
                            </Typography>
                        </TableCell>
                        <TableCell className={this.props.classes.tableCell}>
                            <Typography>{moment(this.state.data.date).format('DD.MM.YY')}</Typography>
                        </TableCell>
                        <TableCell className={this.props.classes.tableCell}>
                            {this.state.data.foreignCompany ? (
                                <Button
                                    className={this.props.classes.noTextTransform}
                                    component={Link}
                                    onClick={() => captureWebEvent('tradeTable-tradeTable-company-link')}
                                    to={{
                                        pathname: '/company/' + this.state.data.foreignCompany.id + '/detail',
                                        state: { from: 'MY_MARKET' },
                                    }}
                                >
                                    <Typography>{this.state.data.foreignCompany.name}</Typography>
                                </Button>
                            ) : (
                                <Typography>{this.props.t('company:deletedCompany')}</Typography>
                            )}
                        </TableCell>
                        <TableCell className={this.props.classes.tableCell}>
                            <Typography>{this.getStatusText()}</Typography>
                        </TableCell>
                        <TableCell className={this.props.classes.tableCell}>
                            <Typography>
                                {this.props.role == 'SELLER'
                                    ? this.state.sellingPerson
                                        ? `${this.state.sellingPerson.firstname} ${this.state.sellingPerson.lastname}`
                                        : this.props.t('company:deletedPerson')
                                    : this.state.buyingPerson
                                      ? `${this.state.buyingPerson.firstname} ${this.state.buyingPerson.lastname}`
                                      : this.props.t('company:deletedPerson')}
                            </Typography>
                        </TableCell>
                        <TableCell className={this.props.classes.tableCell}>
                            <IconButton
                                sx={{ backgroundColor: 'transparent' }}
                                data-testid="collapse"
                                onClick={() => {
                                    captureWebEvent('tradeTable-collapse-row');
                                    this.setState({
                                        isCollapseOpen: !this.state.isCollapseOpen,
                                    });
                                }}
                            >
                                {this.state.isCollapseOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </TableCell>
                    </TableRow>
                    <TableRow style={this.props.styling} className={this.props.classes.collapseRowRoundings}>
                        <TableCell
                            style={{
                                padding: 0,
                                border: 'none',
                            }}
                            colSpan={9}
                        >
                            <Collapse in={this.state.isCollapseOpen} timeout="auto" unmountOnExit>
                                <Box
                                    sx={{
                                        marginTop: theme.spacing(1.5),
                                        margin: 0,
                                        padding: 0,
                                    }}
                                >
                                    {this.getDetailsTable()}
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                    <TableRow style={this.props.styling} className={this.props.classes.buttonRowRoundings}>
                        <TableCell
                            colSpan={12}
                            sx={{
                                paddingTop: 0,
                                paddingBottom: theme.spacing(2),
                                border: 'none',
                                margin: 0,
                            }}
                        >
                            <Box>
                                {this.state.purchaseIntent
                                    ? this.showPurchaseIntentButtons()
                                    : this.state.priceRequest
                                      ? this.showPriceRequestButtons()
                                      : this.showOrderButtons()}
                            </Box>
                        </TableCell>
                    </TableRow>
                    {this.showConfirmationDialogForCancelPurchaseIntent()}
                    {this.showConfirmationDialogForCancelPriceRequest()}
                    {this.showProvidePriceDialog()}
                </>
            );
        } else return null;
    }
}

export default withTranslation([
    'offer',
    'shortunits',
    'common',
    'levelsOfProcessing',
    'sales',
    'purchaseIntent',
    'priceRequest',
    'order',
    'ontofood',
    'company',
    'dialogs',
])(withStyles(styles, { withTheme: true })(TradeTableRow));
