import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, ListItem, ListItemText, Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import SystemMessageService from 'api/SystemMessageService';
import {
    DefaultErrorNotification,
    DeletedSystemMessageNotification,
    NotificationInfo,
} from 'model/NearbuyNotification';
import { SystemMessage, SystemMessageType } from 'model/SystemMessage';
import moment from 'moment';
import { now } from 'moment/moment';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { Subscription, timer } from 'rxjs';
import {
    CompanyStore,
    NotificationStore,
    PersonEmploymentStore,
    SystemMessageSeenStore,
    SystemMessageStore,
} from 'store';
import { SystemMessagesSeenEntity } from 'store/SystemMessagesSeenStore';
import { classNames } from 'util/style-helpers';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = (theme: Theme) =>
    createStyles({
        itemHover: {
            '&:hover': {
                backgroundColor: theme.palette.primary.lighter,
            },
            cursor: 'pointer',
        },
        overflowEllipsis: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        typography: {
            fontWeight: 'normal',
        },
        typographyBold: {
            fontWeight: 'bold',
        },
        deleteButton: {
            backgroundColor: theme.palette.primary.main,
        },
    });
interface SystemMessageItemProps extends WithStyles<typeof _styles>, WithTranslation {
    systemMessage: SystemMessage;
    mapping: Record<string, unknown>;
    onClick?: () => void;
    errorState?: string;
}
interface SystemMessageItemState {
    employmentRef?: string;
    isObserving?: boolean;
    isSeen?: boolean;
    seenSubscription?: Subscription;
    isDeleteButtonShown?: boolean;
}
class SystemMessageItem extends ComponentBase<SystemMessageItemProps, SystemMessageItemState> {
    protected _buildState(
        props: SystemMessageItemProps,
        initialBuild: boolean,
        incomingState: Readonly<SystemMessageItemState>,
    ): Partial<SystemMessageItemState> | undefined {
        const newState: Partial<SystemMessageItemState> = {};
        // Load employment of current user and company combination
        const employments = PersonEmploymentStore.getSelected();
        const company = CompanyStore.getSelected();
        const employment = employments?.employments.find(
            (employment) => employment.links.company == company?.links.self,
        );
        if (!employment) {
            return undefined;
        }
        // Save employmentRef to state for later use in creation of new SystemMessageSeenEntity
        newState.employmentRef = employment.links.self;
        // Load Entity containing the already seen system messages
        const messagesEntity = SystemMessageSeenStore.getOne(employment.links.self);
        if (!messagesEntity) {
            return newState;
        }
        newState.isSeen = this.isAlreadySeen(messagesEntity);
        if (newState.isSeen) {
            // Stop waiting to register current message as seen as it is already seen
            this.state.seenSubscription?.unsubscribe();
            // Stop detecting if message is visible on screen to not start the waiting process again
            if (this.ref) {
                this.observer.unobserve(this.ref);
            }
        }
        return newState;
    }
    observer: IntersectionObserver = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.onView();
                } else {
                    this.onHide();
                }
            });
        },
        { threshold: 0.6 },
    );
    ref: HTMLElement | null = null;
    isAlreadySeen(messagesEntity: SystemMessagesSeenEntity): boolean {
        // Is this system message contained in the already seen messages
        // from the persistent store?
        return !!messagesEntity?.seenMessages.find(
            (message) => message.systemMessageRef === this.props.systemMessage.links.self,
        );
    }
    setSeen() {
        if (!this.state.employmentRef || this.state.isSeen) {
            return;
        }
        const messagesEntity = SystemMessageSeenStore.getOne(this.state.employmentRef);
        // Add new SystemMessageSeen to persistent Store
        SystemMessageSeenStore.setOne({
            employmentRef: this.state.employmentRef,
            seenMessages: [
                {
                    systemMessageRef: this.props.systemMessage.links.self,
                    validUntil: this.props.systemMessage.expiresOn?.toDate(),
                },
                // Also add already persisted messages if they exist,
                // as this commands overrides everything
                ...(messagesEntity?.seenMessages ?? []),
            ],
        });
        SystemMessageSeenStore.persist();
        this.setState({ isSeen: true });
    }
    onHide() {
        this.state.seenSubscription?.unsubscribe();
    }
    onView(): void {
        this.setState({
            seenSubscription: timer(2000).subscribe(() => this.setSeen()),
        });
    }
    getDate(systemMessage: SystemMessage): string {
        if (moment(systemMessage.createdAt).isSame(moment(), 'day')) {
            return this.props.t('dialogs:today');
        } else {
            return systemMessage.createdAt.format(this.props.t('common:dateformat_systemMessage'));
        }
    }
    render(): React.ReactElement | null {
        const { onClick } = this.props;
        return (
            <React.Fragment key={this.props.systemMessage.links.self}>
                <ListItem
                    data-testid={'systemMessageItem'}
                    data-attr-ph-name={'message'}
                    onMouseOver={() =>
                        this.setState({
                            isDeleteButtonShown: true,
                        })
                    }
                    onMouseLeave={() =>
                        this.setState({
                            isDeleteButtonShown: false,
                        })
                    }
                    onClick={() => {
                        onClick && onClick();
                        captureWebEvent('click-on-notification');
                    }}
                    className={this.props.onClick ? this.props.classes.itemHover : ''}
                    ref={(ref) => {
                        this.ref = ref;
                        if (!this.state.isObserving && ref) {
                            this.observer.observe(ref);
                            this.setState({
                                isObserving: true,
                            });
                        }
                    }}
                >
                    <ListItemText
                        id={this.props.systemMessage.links.self}
                        primary={
                            <Box>
                                <Typography
                                    className={classNames(
                                        this.state.isSeen
                                            ? this.props.classes.typography
                                            : this.props.classes.typographyBold,
                                        this.props.classes.overflowEllipsis,
                                    )}
                                >
                                    {this.props.t('system:' + this.props.systemMessage.type, this.props.mapping)}
                                </Typography>
                                {this.props.errorState && (
                                    <Typography color="red" variant={'subtitle2'}>
                                        {this.props.t('system:' + this.props.errorState)}
                                    </Typography>
                                )}
                            </Box>
                        }
                        secondary={this.getDate(this.props.systemMessage)}
                    />
                    {this.state.isDeleteButtonShown && this.props.systemMessage.type != SystemMessageType.GLOBAL ? (
                        <IconButton
                            className={this.props.classes.deleteButton}
                            onClick={(e: React.MouseEvent) => {
                                captureWebEvent('delete-notification-button');
                                e.stopPropagation();
                                SystemMessageService.setSystemMessageIsDeleted(
                                    this.props.systemMessage.links.self,
                                    true,
                                ).subscribe({
                                    next: (value) => {
                                        SystemMessageStore.invalidateCache(value.links.self);
                                        NotificationStore.setOne(
                                            new NotificationInfo(
                                                DeletedSystemMessageNotification(this.props.systemMessage.links.self),
                                                now(),
                                            ),
                                        );
                                    },
                                    error: () => {
                                        NotificationStore.setOne(
                                            new NotificationInfo(DefaultErrorNotification(), now()),
                                        );
                                    },
                                });
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    ) : null}
                </ListItem>
            </React.Fragment>
        );
    }
}
export default withTranslation(['dialogs', 'common', 'system'])(
    withStyles(_styles, { withTheme: true })(SystemMessageItem),
);
