import * as React from 'react';
import { ReactNode, useState } from 'react';
import { RecurringOrder, RecurringOrderListView, RecurringOrderStatus } from 'model/RecurringOrder';
import TableRow from '@mui/material/TableRow';
import { Product } from 'model/Product';
import { Amount } from 'model/Amount';
import { Company } from 'model/Company';
import { useTranslation } from 'react-i18next';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Moment, now } from 'moment';
import moment from 'moment/moment';
import { Person } from 'model/Person';
import TradeActionButtonsComponent from 'components/messaging/chat/TradeActionButtonsComponent';
import TradeActionButtonComponent from 'components/messaging/chat/TradeActionButtonsComponent';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Grid from '@mui/material/Grid';
import TradeDetailsTable from 'components/marketplace/tradetable/TradeDetailsTable';
import { ContainerType, OrderContainer } from 'model/ContainerView';
import { OrderAddress } from 'model/Address';
import createStyles from '@mui/styles/createStyles';
import { useTheme, WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import {
    ArrowDropDown,
    ArrowDropUp,
    CloudDownload,
    CloudUpload,
    FileDownloadRounded,
    UploadFileRounded,
} from '@mui/icons-material';
import SplitButtonComponent from 'components/shared/SplitButtonComponent';
import RecurringOrderService from 'api/RecurringOrderService';
import recurringOrderService from 'api/RecurringOrderService';
import ConfirmationDialog from 'components/messaging/chat/messagefactory/infocards/shared/ConfirmationDialog';
import { PreOrderTranslationMapping } from 'components/marketplace/shared/PreOrderTranslationMapping';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { captureWebEvent } from 'util/AnalyticUtils';
import RecurringOrderBuyingDialog from 'components/marketplace/recurring-order/RecurringOrderBuyingDialog';
import { NotificationStore } from 'store';
import { InvoiceSentNotification, NotificationInfo } from 'model/NearbuyNotification';
import { Invoice } from 'model/Invoice';
import { Observable } from 'rxjs';
import { getUuidFromString } from 'util/helpers';
import { LevelOfProcessing } from 'model/LevelOfProcessing';
import DeliveriesDialog from 'components/marketplace/recurring-order/deliveries/DeliveriesDialog';

const styles = () =>
    createStyles({
        tableCell: {
            whiteSpace: 'pre-wrap',
            border: 'none',
            '&.MuiTableCell-root': {
                paddingBottom: 5,
            },
        },
        upperRowRoundings: {
            '& td:first-of-type': {
                borderRadius: '12px 0 0 0 !important',
                border: 'none',
            },
            '& td:last-child': {
                borderRadius: '0 12px 0 0 !important',
                border: 'none',
            },
        },
        noRowRoundings: {
            '& td:first-of-type': {
                borderRadius: '0 0 0 0 !important',
                border: 'none',
            },
        },
        lowerRowRoundings: {
            '& td:first-of-type': {
                borderRadius: '0 0 12px 12px !important',
                border: 'none',
            },
        },
        noTextTransform: {
            textTransform: 'none',
            padding: 0,
            textDecorationLine: 'underline',
            justifyContent: 'flex-start',
        },
    });

export interface RecurringOrderTableRowData {
    product?: Product;
    amount: Amount;
    firstDeliveryDate: Moment;
    deliveryFreq: string;
    deliveryInterval: number;
    lastDeliveryDate: Moment;
    totalPrice: number;
    pricePerUnit: number;
    dateCreated: Moment;
    sellingCompany?: Company;
    buyingCompany?: Company;
    status: RecurringOrderStatus;
    sellingPerson?: Person;
    buyingPerson?: Person;
    orderContainers?: OrderContainer[];
    containerTypes: ContainerType[];
    invoiceAddress?: OrderAddress;
    deliveryAddress?: OrderAddress;
    ownCompanyRef: string;
    levelsOfProcessing?: LevelOfProcessing[];
    recurringOrderRef: string;
    offerRef?: string;
    invoice?: Invoice;
}

interface RecurringOrderTableRowProps extends WithStyles<typeof styles> {
    key: string;
    rowData: RecurringOrderTableRowData;
    styling?: React.CSSProperties;
    role: 'BUYER' | 'SELLER' | 'NONE';
    listView: RecurringOrderListView;
}

function RecurringOrderTableRow(props: RecurringOrderTableRowProps) {
    const recurringOrderId = getUuidFromString(props.listView.links.self);

    const { t } = useTranslation([
        'recurringOrder',
        'shortunits',
        'ontofood',
        'offer',
        'purchaseIntent',
        'common',
        'company',
        'order',
        'tooltips',
    ]);
    const theme = useTheme();

    const [isCollapseOpen, setIsCollapseOpen] = useState(false);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const [isDeliveriesDialogOpen, setIsDeliveriesDialogOpen] = useState(false);
    const [isRecurringOrderBuyingDialogOpen, setIsRecurringOrderBuyingDialogOpen] = useState(false);
    const handleDialogClose = () => {
        setIsRecurringOrderBuyingDialogOpen(false);
    };

    const {
        product,
        amount,
        firstDeliveryDate,
        deliveryFreq,
        deliveryInterval,
        lastDeliveryDate,
        totalPrice,
        pricePerUnit,
        dateCreated,
        sellingCompany,
        buyingCompany,
        status,
        sellingPerson,
        buyingPerson,
        orderContainers,
        ownCompanyRef,
        containerTypes,
    } = props.rowData;

    const showPendingButtonAccordingToRole = (): React.ReactElement => {
        return props.role === 'SELLER' ? (
            <Grid item>
                <TradeActionButtonsComponent
                    buttons={[
                        {
                            text: t('purchaseIntent:accept').toUpperCase(),
                            gridWidth: 1.4,
                            type: 'transparent',
                            onClick: () => {
                                RecurringOrderService.updateRecurringOrderStatus(
                                    props.listView.links.self,
                                    RecurringOrderStatus.ACCEPTED,
                                ).subscribe();
                            },
                        },
                        {
                            text: t('purchaseIntent:reject').toUpperCase(),
                            gridWidth: 1.4,
                            type: 'transparent',
                            onClick: () => setIsConfirmationDialogOpen(true),
                        },
                    ]}
                    justifyHorizontal={'start'}
                />
            </Grid>
        ) : (
            <Grid item>
                <TradeActionButtonsComponent
                    buttons={[
                        {
                            text: t('purchaseIntent:cancel').toUpperCase(),
                            gridWidth: 1.4,
                            type: 'transparent',
                            onClick: () => setIsConfirmationDialogOpen(true),
                        },
                    ]}
                    justifyHorizontal={'start'}
                />
            </Grid>
        );
    };

    const showAcceptedButtonsAccordingToRole = (): React.ReactElement => {
        return props.role === 'SELLER' ? (
            <Grid item>
                <TradeActionButtonsComponent
                    buttons={[
                        {
                            text: t('purchaseIntent:cancel').toUpperCase(),
                            gridWidth: 1.4,
                            type: 'transparent',
                            onClick: () => setIsConfirmationDialogOpen(true),
                        },
                    ]}
                    justifyHorizontal={'start'}
                />
            </Grid>
        ) : (
            <Grid item>
                <TradeActionButtonsComponent
                    buttons={[
                        {
                            text: t('purchaseIntent:buy').toUpperCase(),
                            gridWidth: 1.4,
                            type: 'secondaryMain',
                            onClick: () => setIsRecurringOrderBuyingDialogOpen(true),
                        },
                        {
                            text: t('purchaseIntent:cancel').toUpperCase(),
                            gridWidth: 1.4,
                            type: 'transparent',
                            onClick: () => setIsConfirmationDialogOpen(true),
                        },
                    ]}
                    justifyHorizontal={'start'}
                />
            </Grid>
        );
    };

    const handleDownloadInvoiceClick = () => {
        if (!props.rowData.invoice) return;
        const { fileName, downloadLink } = props.rowData.invoice;
        if (!fileName || !downloadLink?.url) return;
        const link = document.createElement('a');
        link.download = fileName;
        link.href = downloadLink.url;
        link.click();
    };

    const handleUploadInvoiceClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (event.target.files && event.target.files[0]) {
            const file: File = event.target.files[0];
            if (file.size > 5_242_880) return;

            const existingInvoiceRef = props.rowData.invoice?.links.self;

            const uploadObservable = recurringOrderService.uploadRecurringOrderInvoice(
                props.listView.links.self,
                new Blob([file], { type: file.type }),
                file.name,
            );

            function subscribeToUploadObservable(observable: Observable<RecurringOrder>) {
                observable.subscribe({
                    next: (_) => {
                        NotificationStore.setOne(new NotificationInfo(InvoiceSentNotification(), now()));
                    },
                    error: (err) => {
                        console.error(`[recurringOrderInvoice] error[${err.value}]: ${err.message}`);
                    },
                });
            }

            if (existingInvoiceRef) {
                RecurringOrderService.removeRecurringOrderInvoice(
                    props.listView.links.self,
                    existingInvoiceRef,
                )?.subscribe(() => {
                    subscribeToUploadObservable(uploadObservable);
                });
            } else {
                subscribeToUploadObservable(uploadObservable);
            }
        }
    };

    const showOrderedButtonsAccordingToRole = (): React.ReactElement => {
        return props.role === 'SELLER' ? (
            <Grid item>
                {props.listView.links.invoices?.length ? (
                    <SplitButtonComponent
                        sxPropsButtonGroup={{
                            marginRight: theme.spacing(1),
                        }}
                        options={[
                            {
                                label: t('order:downloadInvoice'),
                                action: handleDownloadInvoiceClick,
                                icon: <FileDownloadRounded key={'downloadInvoiceIcon'} />,
                            },
                            {
                                label: t('order:exchangeInvoice'),
                                action: () => {
                                    const input = document.getElementById('invoice-input-' + recurringOrderId);
                                    input?.click();
                                },
                                icon: <UploadFileRounded key={'exchangeInvoiceIcon'} />,
                                tooltip: t('common:pdfUploadTooltipHint'),
                            },
                        ]}
                        buttonDefaultText={t('order:invoice')}
                        justifyHorizontal={'end'}
                    />
                ) : (
                    <TradeActionButtonsComponent
                        buttons={[
                            {
                                text: t('order:uploadInvoice').toUpperCase(),
                                gridWidth: 1.4,
                                type: 'primaryLight',
                                iconStart: <CloudUpload />,
                                fileInputOnChange: (event) => handleUploadInvoiceClick(event),
                                fileInputAccept: 'application/pdf',
                                tooltip: t('common:pdfUploadTooltipHint'),
                            },
                        ]}
                        justifyHorizontal={'start'}
                    />
                )}
            </Grid>
        ) : (
            <Grid item>
                <TradeActionButtonComponent
                    buttons={[
                        {
                            text: t('order:downloadInvoice'),
                            type: 'primaryLight',
                            iconStart: <CloudDownload />,
                            onClick: handleDownloadInvoiceClick,
                            disabled: !props.rowData.invoice,
                            tooltip: props.rowData.invoice ? undefined : t('tooltips:noInvoiceAvailable'),
                        },
                    ]}
                    justifyHorizontal={'start'}
                    marginTop={'none'}
                />
            </Grid>
        );
    };

    const showButtonsAccordingToStatus = (): React.ReactNode => {
        switch (status) {
            case RecurringOrderStatus.PENDING:
                return showPendingButtonAccordingToRole();
            case RecurringOrderStatus.ACCEPTED:
                return showAcceptedButtonsAccordingToRole();
            case RecurringOrderStatus.ORDERED:
                return showOrderedButtonsAccordingToRole();
        }
    };

    const responsiblePerson =
        (props.role == 'BUYER'
            ? buyingPerson
                ? `${buyingPerson.firstname} ${buyingPerson.lastname}`
                : undefined
            : sellingPerson
              ? `${sellingPerson.firstname} ${sellingPerson.lastname}`
              : undefined) ?? t('company:deletedPerson');

    const company = props.role == 'BUYER' ? sellingCompany : buyingCompany;

    const getTranslatedStatus = (status: RecurringOrderStatus): string => {
        if (!status) return '-';
        if (status == RecurringOrderStatus.ORDERED) {
            return props.rowData.invoice
                ? t('order:COMPLETED_WITH_INVOICE')
                : props.role == 'SELLER'
                  ? t(`order:orderReceived`)
                  : t(`order:orderSent`);
        } else return t(`recurringOrder:${status}`);
    };

    const getInvoiceFileInput = (): ReactNode => {
        return (
            <input
                id={'invoice-input-' + recurringOrderId}
                type="file"
                style={{ display: 'none' }}
                accept={'application/pdf'}
                onChange={(event) => handleUploadInvoiceClick(event)}
            />
        );
    };

    const showProductLableCell = (): ReactNode => (
        <TableCell className={props.classes.tableCell}>{t(`ontofood:${product?.label}`)}</TableCell>
    );

    const showAmountCell = (): ReactNode => (
        <TableCell className={props.classes.tableCell}>
            {`${amount.amount} ${t(`shortunits:${amount.unit}`)}`}
        </TableCell>
    );

    const showCollapseButtonCell = (): ReactNode => (
        <TableCell className={props.classes.tableCell}>
            <IconButton
                sx={{ backgroundColor: 'transparent' }}
                onClick={() => {
                    setIsCollapseOpen(!isCollapseOpen);
                }}
            >
                {isCollapseOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            </IconButton>
        </TableCell>
    );

    const showResponsiblePersonCell = (): ReactNode => (
        <TableCell className={props.classes.tableCell}>{responsiblePerson}</TableCell>
    );

    const showFirstDeliveryCell = (): ReactNode => (
        <TableCell className={props.classes.tableCell}>
            {firstDeliveryDate ? moment(firstDeliveryDate).format('DD.MM.YYYY') : '-'}
        </TableCell>
    );

    const showFrequencyCell = (): ReactNode => (
        <TableCell className={props.classes.tableCell}>
            {`${t('recurringOrder:deliveryPrefix')} ${deliveryInterval} ${t(deliveryFreq)}`}
        </TableCell>
    );

    const showLastDeliveryCell = (): ReactNode => (
        <TableCell className={props.classes.tableCell}>
            {lastDeliveryDate ? moment(lastDeliveryDate).format('DD.MM.YYYY') : '-'}
        </TableCell>
    );

    const showPriceCell = (): ReactNode => (
        <TableCell className={props.classes.tableCell}>
            <Typography>
                {totalPrice
                    ? `${totalPrice.toLocaleString('de-DE', {
                          style: 'currency',
                          currency: 'EUR',
                      })}`
                    : '-'}
            </Typography>
            <Typography sx={{ color: theme.palette.grey['600'] }}>
                {`${pricePerUnit.toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                })} ${t('offer:per')}  ${t(`shortunits:${amount.unit}`)}`}
            </Typography>
        </TableCell>
    );

    const showDateCreatedCell = (): ReactNode => (
        <TableCell className={props.classes.tableCell}>
            {dateCreated ? moment(dateCreated).format('DD.MM.YYYY') : '-'}
        </TableCell>
    );

    const showCompanyNameCell = (): ReactNode => (
        <TableCell className={props.classes.tableCell}>
            {company ? (
                <Button
                    className={props.classes.noTextTransform}
                    component={Link}
                    onClick={() => captureWebEvent('tradeTable-tradeTable-company-link')}
                    to={{
                        pathname: '/company/' + company.id + '/detail',
                        state: { from: 'MY_MARKET' },
                    }}
                >
                    <Typography>{company.name}</Typography>
                </Button>
            ) : (
                <Typography>{t('company:deletedCompany')}</Typography>
            )}
        </TableCell>
    );

    const showStatusCell = (): ReactNode => (
        <TableCell className={props.classes.tableCell}>
            <Typography sx={{ fontWeight: 600 }}>{getTranslatedStatus(status)}</Typography>
        </TableCell>
    );

    const showButtonRow = (): ReactNode => (
        <TableRow
            style={props.styling}
            className={isCollapseOpen ? props.classes.noRowRoundings : props.classes.lowerRowRoundings}
        >
            <TableCell
                colSpan={12}
                sx={{
                    paddingTop: 0,
                    paddingBottom: theme.spacing(2),
                    border: 'none',
                    margin: 0,
                }}
            >
                <Box>
                    <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'}>
                        {showButtonsAccordingToStatus()}
                        {status === RecurringOrderStatus.ORDERED ? (
                            <Grid item>
                                <TradeActionButtonsComponent
                                    buttons={[
                                        {
                                            text: t('recurringOrder:singleDelivery').toUpperCase(),
                                            gridWidth: 1.4,
                                            type: 'transparent',
                                            iconStart: <FormatListBulletedIcon />,
                                            onClick: () => {
                                                setIsDeliveriesDialogOpen(true);
                                            },
                                        },
                                    ]}
                                    justifyHorizontal={'start'}
                                    marginTop={'none'}
                                />
                            </Grid>
                        ) : null}
                    </Grid>
                </Box>
            </TableCell>
        </TableRow>
    );

    const showConfirmationDialog = (): ReactNode => {
        const { status, links } = props.listView;
        const firstNameSeller = props.rowData.sellingPerson?.firstname ?? t('company:deletedPerson');
        const lastNameSeller = props.rowData.sellingPerson?.lastname ?? '';
        const firstNameBuyer = props.rowData.buyingPerson?.firstname ?? t('company:deletedPerson');
        const lastNameBuyer = props.rowData.buyingPerson?.lastname ?? '';
        const product =
            (props.rowData.product && t(`ontofood:${props.rowData.product.label}`)) ?? t('common:deletedProduct');
        const mapping: PreOrderTranslationMapping = {
            firstNameBuyer,
            lastNameBuyer,
            firstNameSeller,
            lastNameSeller,
            product,
        };
        return (
            isConfirmationDialogOpen && (
                <ConfirmationDialog
                    calledFrom={'RecurringOrderTableRow'}
                    isOpen
                    onClose={() => setIsConfirmationDialogOpen(false)}
                    title={
                        props.role == 'BUYER'
                            ? t('dialogs:cancelRecurringOrderTitleBuyer', mapping)
                            : status == RecurringOrderStatus.PENDING
                              ? t('dialogs:rejectRecurringOrderTitleSeller', mapping)
                              : t('dialogs:cancelRecurringOrderTitleSeller', mapping)
                    }
                    content={
                        props.role == 'BUYER'
                            ? t('dialogs:cancelRecurringOrderContentBuyer', mapping)
                            : status == RecurringOrderStatus.PENDING
                              ? t('dialogs:rejectRecurringOrderContentSeller', mapping)
                              : t('dialogs:cancelRecurringOrderContentSeller', mapping)
                    }
                    buttonText1={
                        props.role == 'BUYER'
                            ? t('dialogs:cancelRecurringOrderButton')
                            : status == RecurringOrderStatus.PENDING
                              ? t('dialogs:rejectRecurringOrderButton')
                              : t('dialogs:cancelRecurringOrderButton')
                    }
                    buttonAction1={() =>
                        RecurringOrderService.updateRecurringOrderStatus(
                            links.self,
                            props.role == 'BUYER'
                                ? RecurringOrderStatus.CANCELED_BY_BUYER
                                : status == RecurringOrderStatus.PENDING
                                  ? RecurringOrderStatus.REJECTED
                                  : RecurringOrderStatus.CANCELED_BY_SELLER,
                        ).subscribe(() => setIsConfirmationDialogOpen(false))
                    }
                />
            )
        );
    };

    const showDeliveriesDialog = (): ReactNode => {
        return (
            isDeliveriesDialogOpen && (
                <DeliveriesDialog
                    rowData={props.rowData}
                    recurringOrderRef={props.listView.links.self}
                    isOpen
                    role={props.role}
                    onClose={() => setIsDeliveriesDialogOpen(false)}
                />
            )
        );
    };

    const showDetailsRow = (): ReactNode => (
        <TableRow style={props.styling} className={props.classes.lowerRowRoundings}>
            <TableCell
                style={{
                    padding: 0,
                    border: 'none',
                }}
                colSpan={12}
            >
                <Collapse in={isCollapseOpen}>
                    <Box>
                        <TradeDetailsTable
                            ownCompanyRef={ownCompanyRef}
                            unit={amount.unit}
                            sellingPerson={sellingPerson}
                            buyingPerson={buyingPerson}
                            containerSelections={orderContainers ?? []}
                            containerTypes={containerTypes}
                            isContainerSectionShown={!!orderContainers}
                            recurringOrder={props.listView}
                        />
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );

    return (
        <>
            {showConfirmationDialog()}
            {showDeliveriesDialog()}
            {getInvoiceFileInput()}
            <TableRow key={props.key} style={props.styling} className={props.classes.upperRowRoundings}>
                {showProductLableCell()}
                {showAmountCell()}
                {showFirstDeliveryCell()}
                {showFrequencyCell()}
                {showLastDeliveryCell()}
                {showPriceCell()}
                {showDateCreatedCell()}
                {showCompanyNameCell()}
                {showStatusCell()}
                {showResponsiblePersonCell()}
                {showCollapseButtonCell()}
            </TableRow>
            {showButtonRow()}
            {showDetailsRow()}
            {isRecurringOrderBuyingDialogOpen ? (
                <RecurringOrderBuyingDialog
                    open={isRecurringOrderBuyingDialogOpen}
                    onClose={handleDialogClose}
                    recurringOrderRef={props.listView.links.self}
                    buyingDialogData={{
                        offerRef: props.rowData.offerRef,
                        amount: props.rowData.amount,
                        firstDeliveryDate: props.rowData.firstDeliveryDate,
                        lastDeliveryDate: props.rowData.lastDeliveryDate,
                        deliveryInterval: props.rowData.deliveryInterval,
                        deliveryFreq: props.rowData.deliveryFreq,
                        totalPrice: props.rowData.totalPrice,
                        pricePerUnit: props.rowData.pricePerUnit,
                        product: props.rowData.product,
                        levelsOfProcessing: props.rowData.levelsOfProcessing,
                    }}
                />
            ) : null}
        </>
    );
}

export default withStyles(styles, { withTheme: true })(RecurringOrderTableRow);
