import { Moment } from 'moment/moment';
import { Amount } from 'model/Amount';
import { Pair } from 'util/Types';
import { PaymentType } from 'model/Payment';
import { Delivery } from 'model/Delivery';
import { OrderAddress } from 'model/Address';
import { OrderContainer } from 'model/ContainerView';
import { LevelOfProcessing } from 'model/LevelOfProcessing';

export class RecurringOrder {
    constructor(
        public dateCreated: Moment,
        public dateModified: Moment,
        public dateStart: Moment,
        public dateEnd: Moment,
        public payment: PaymentType,
        public status: RecurringOrderStatus,
        public entries: RecurringOrderEntry[],
        public deliveries: Delivery[],
        public addresses: OrderAddress[],
        public rrule: string,
        public links: RecurringOrderLinks,
    ) {}
}

export class RecurringOrderLinks {
    constructor(
        public self: string,
        public buyingPerson: string | undefined,
        public buyingCompany: string | undefined,
        public sellingPerson: string | undefined,
        public sellingCompany: string | undefined,
        public invoices?: string[] | null,
    ) {}
}

export class RecurringOrderListView {
    constructor(
        public dateCreated: Moment,
        public firstDeliveryDate: Moment,
        public lastDeliveryDate: Moment,
        public rrule: string,
        public invoiceAddress: OrderAddress,
        public deliveryAddress: OrderAddress,
        public payment: PaymentType,
        public status: RecurringOrderStatus,
        public amount: Amount,
        public totalPrice: number,
        public pricePerUnit: number,
        public orderContainers: OrderContainer[] | undefined,
        public levelsOfProcessing: LevelOfProcessing[] | undefined,
        public links: RecurringOrderListViewLinks,
    ) {}
}

export class RecurringOrderListViewLinks {
    constructor(
        public self: string,
        public offer: string,
        public product: string | undefined,
        public buyingPerson: string | undefined,
        public buyingCompany: string | undefined,
        public sellingPerson: string | undefined,
        public sellingCompany: string | undefined,
        public invoices?: string[] | null,
    ) {}
}

export class RecurringOrderWriteView {
    constructor(
        public dateStart: Date,
        public dateEnd: Date,
        public entries: RecurringOrderEntryWriteView[],
        public rrule: string,
        public paymentType: PaymentType = PaymentType.ON_ACCOUNT,
    ) {}
}

export class RecurringOrderEntryWriteView {
    constructor(
        public offerRef: string,
        public containers?: Pair<number, string>[],
        public totalAmount?: Amount,
    ) {}
}

export class RecurringOrderStatusWriteView {
    constructor(public status: RecurringOrderStatus) {}
}

export class RecurringOrderEntry {
    constructor(
        public amount: Amount,
        public totalPrice: number,
        public pricePerUnit: number,
        public containers: OrderContainer[],
        public levelsOfProcessing: LevelOfProcessing[],
        public links: RecurringOrderEntryLink,
    ) {}
}

export class RecurringOrderEntryLink {
    constructor(
        public offer: string,
        public category?: string,
    ) {}
}

export enum RecurringOrderStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    ORDERED = 'ORDERED',
    CANCELED_BY_BUYER = 'CANCELED_BY_BUYER',
    CANCELED_BY_SELLER = 'CANCELED_BY_SELLER',
    REJECTED = 'REJECTED',
}

export enum DeliveryStatus {
    PENDING = 'PENDING',
    CANCELED = 'CANCELED',
    DELIVERED = 'DELIVERED',
}
