import { useTheme } from '@mui/styles';
import { Trans, useTranslation } from 'react-i18next';
import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import { NutritionInformation } from 'model/ProductDataSheet';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import { deepCopy } from 'util/deep-equality-helpers';
import { Amount } from 'model/Amount';
import UnitSelectComponent from 'components/marketplace/shared/UnitSelectComponent';
import { getUnitFromString, Unit, UnitString, UnitType } from 'model/Unit';
import NumberFormat from 'react-number-format';
import { InputAdornment } from '@mui/material';

export interface StepNutritionProps {
    nutritionInformation?: NutritionInformation | undefined;
}

export const StepNutrition = (props: StepComponentProps<StepNutritionProps>) => {
    const theme = useTheme();
    const { t } = useTranslation(['productDataSheet', 'units']);
    const useStyles = makeStyles({
        boldTypography: {
            fontWeight: 'bold',
        },
        slightlyBoldTypography: {
            fontWeight: 450,
            color: '#191A1A',
        },
        numberFormat: {
            minWidth: '100%',
        },
    });
    const classes = useStyles();

    const showTitle = () => (
        <Grid item md={12}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_NUTRITION_TITLE')}
            </Typography>
        </Grid>
    );

    const showText = (): ReactNode => (
        <Grid item md={12}>
            <Typography sx={{ paddingBottom: theme.spacing(3) }}>
                <Trans
                    i18nKey="productDataSheet:DATASTEP_NUTRITION_TEXT"
                    t={t}
                    components={[
                        <a
                            style={{ color: theme.palette.text.primary }}
                            key={0}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                                'https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/' +
                                '?uri=CELEX:32011R1169&from=DE#d1e40-18-1'
                            }
                        />,
                    ]}
                />
            </Typography>
        </Grid>
    );

    const showNutritionPer100SubTitle = (): ReactNode => (
        <Grid item md={12}>
            <Typography className={classes.boldTypography}>{t('productDataSheet:nutritionPer100')}</Typography>
        </Grid>
    );

    const handleNumberChange = (value: string, field: keyof NutritionInformation) => {
        const floatValue = parseFloat(value);
        const nutritionInformation = deepCopy(props.data.nutritionInformation) ?? new NutritionInformation();

        if (field == 'energy') {
            nutritionInformation.energy = new Amount(
                !isNaN(floatValue) ? floatValue : 0,
                nutritionInformation.energy?.unit ?? '',
            );
        } else {
            nutritionInformation[field] = !isNaN(floatValue) ? floatValue : 0;
        }

        props.setData('StepNutrition', { nutritionInformation });
    };

    const handleUnitChange = (unit: Unit) => {
        const nutritionInformation = deepCopy(props.data.nutritionInformation) ?? new NutritionInformation();

        nutritionInformation.energy = new Amount(nutritionInformation.energy?.amount ?? 0, unit.unit);

        props.setData('StepNutrition', { nutritionInformation });
    };

    const getNumberFormatInput = (field: keyof NutritionInformation): ReactNode => (
        <NumberFormat
            className={classes.numberFormat}
            InputProps={
                field == 'energy'
                    ? undefined
                    : {
                          endAdornment: (
                              <InputAdornment position="end">
                                  <Typography className={classes.slightlyBoldTypography}>
                                      {t('units:' + UnitString.gram)}
                                  </Typography>
                              </InputAdornment>
                          ),
                      }
            }
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            customInput={TextField}
            value={
                field == 'energy'
                    ? props.data.nutritionInformation?.energy?.amount
                        ? props.data.nutritionInformation.energy.amount
                        : undefined
                    : props.data.nutritionInformation && props.data.nutritionInformation[field]
                      ? props.data.nutritionInformation[field]
                      : undefined
            }
            onValueChange={(numberFormatValues) => handleNumberChange(numberFormatValues.value, field)}
            label={t(`productDataSheet:${field}`)}
        />
    );

    const showEnergySection = (): ReactNode => (
        <Grid item container direction={'row'} columnSpacing={theme.spacing(2)} rowSpacing={theme.spacing(0.25)}>
            <Grid item xs={12}>
                <Typography className={classes.boldTypography}>{t('productDataSheet:energy')}</Typography>
            </Grid>
            <Grid item md={6}>
                {getNumberFormatInput('energy')}
            </Grid>
            <Grid item md={6}>
                <UnitSelectComponent
                    isRequired
                    selectedUnit={getUnitFromString(props.data.nutritionInformation?.energy?.unit)}
                    onChange={(unit) => handleUnitChange(unit)}
                    typeToLimitTo={UnitType.ENERGY}
                />
            </Grid>
        </Grid>
    );

    const showFatSection = (): ReactNode => (
        <Grid item container direction={'row'} columnSpacing={theme.spacing(2)} rowSpacing={theme.spacing(0.25)}>
            <Grid item xs={12}>
                <Typography className={classes.boldTypography}>{t('productDataSheet:fat')}</Typography>
            </Grid>
            <Grid item md={6}>
                {getNumberFormatInput('fat')}
            </Grid>
            <Grid item md={6}>
                {getNumberFormatInput('saturatedFattyAcids')}
            </Grid>
            <Grid item md={6}>
                {getNumberFormatInput('monounsaturatedFattyAcids')}
            </Grid>
            <Grid item md={6}>
                {getNumberFormatInput('polyunsaturatedFattyAcids')}
            </Grid>
        </Grid>
    );

    const showCarbSection = (): ReactNode => (
        <Grid item container direction={'row'} columnSpacing={theme.spacing(2)} rowSpacing={theme.spacing(0.25)}>
            <Grid item xs={12}>
                <Typography className={classes.boldTypography}>{t('productDataSheet:carbs')}</Typography>
            </Grid>
            <Grid item md={6}>
                {getNumberFormatInput('carbs')}
            </Grid>
            <Grid item md={6}>
                {getNumberFormatInput('sugar')}
            </Grid>
        </Grid>
    );

    const showProteinSection = (): ReactNode => (
        <Grid item container direction={'row'} columnSpacing={theme.spacing(2)} rowSpacing={theme.spacing(0.25)}>
            <Grid item xs={12}>
                <Typography className={classes.boldTypography}>{t('productDataSheet:protein')}</Typography>
            </Grid>
            <Grid item md={12}>
                {getNumberFormatInput('protein')}
            </Grid>
        </Grid>
    );

    const showSaltSection = (): ReactNode => (
        <Grid item container direction={'row'} columnSpacing={theme.spacing(2)} rowSpacing={theme.spacing(0.25)}>
            <Grid item xs={12}>
                <Typography className={classes.boldTypography}>{t('productDataSheet:salt')}</Typography>
            </Grid>
            <Grid item md={12}>
                {getNumberFormatInput('salt')}
            </Grid>
        </Grid>
    );

    return (
        <Grid
            item
            container
            rowSpacing={theme.spacing(1.5)}
            sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}
        >
            {showTitle()}
            {showText()}
            {showNutritionPer100SubTitle()}
            {showEnergySection()}
            {showFatSection()}
            {showCarbSection()}
            {showProteinSection()}
            {showSaltSection()}
        </Grid>
    );
};
