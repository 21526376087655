import {
    RecurringOrderTab,
    RecurringOrderTableFilter,
    RecurringOrderTableItems,
    RecurringOrderType,
} from 'components/marketplace/recurring-order/RecurringOrderType';
import { Location } from 'history';
import { SettingsKey } from 'model/UserSettings';
import { RecurringOrderListView, RecurringOrderStatus } from 'model/RecurringOrder';
import { RecurringOrderTableComponentState } from 'components/marketplace/recurring-order/RecurringOrderTableComponent';
import { CompanyStore, InvoiceStore, MetaStore, PersonStore, ProductStore } from 'store';
import { MetaStaticEntityType } from 'model/Meta';
import { ContainerType } from 'model/ContainerView';
import { sortByDateCreated, sortByValueMap } from 'util/TableHelpers';
import { TFunction } from 'i18next';
import moment from 'moment';

export function getActiveRecurringNavBarTitleLocaleTag(tradeType: RecurringOrderType): string {
    switch (tradeType) {
        case RecurringOrderType.RECURRING_ORDER_REQUESTS:
            return 'recurringOrder:receivedRecurringOrders';
        case RecurringOrderType.RECURRING_ORDER_PURCHASE:
            return 'recurringOrder:sentRecurringOrders';
    }
}

export function getRecurringOrderPlaceHolderLocaleTag(filter: RecurringOrderTableFilter): string {
    switch (filter.recurringOrderType) {
        case RecurringOrderType.RECURRING_ORDER_REQUESTS:
            return filter.tab == RecurringOrderTab.ACTIVE
                ? 'recurringOrder:emptyTablePlaceholderTextRecurringRequestsOrderActive'
                : 'recurringOrder:emptyTablePlaceholderTextRecurringRequestsOrderTerminated';
        case RecurringOrderType.RECURRING_ORDER_PURCHASE:
            return filter.tab == RecurringOrderTab.ACTIVE
                ? 'recurringOrder:emptyTablePlaceholderTextRecurringPurchaseOrderActive'
                : 'recurringOrder:emptyTablePlaceholderTextRecurringPurchaseOrderTerminated';
    }
}

export function getRecurringOrderType(location: Location<unknown>): RecurringOrderType {
    switch (true) {
        case location.pathname.endsWith('receivedRecurringOrders'):
            return RecurringOrderType.RECURRING_ORDER_REQUESTS;
        case location.pathname.endsWith('sentRecurringOrders'):
            return RecurringOrderType.RECURRING_ORDER_PURCHASE;
        default:
            return RecurringOrderType.RECURRING_ORDER_REQUESTS;
    }
}

export function getRecurringSwitchSettingKey(recurringOrderType: RecurringOrderType): SettingsKey {
    switch (recurringOrderType) {
        case RecurringOrderType.RECURRING_ORDER_REQUESTS:
            return SettingsKey.RECURRING_ORDER_SHOW_ONLY_OWN_TRADE_RESULTS_PURCHASE_REQUESTS;
        case RecurringOrderType.RECURRING_ORDER_PURCHASE:
            return SettingsKey.RECURRING_ORDER_SHOW_ONLY_OWN_TRADE_RESULTS_PURCHASES;
    }
}

export function getRecurringSortSettingKey(filter: RecurringOrderTableFilter): SettingsKey {
    switch (filter.recurringOrderType) {
        case RecurringOrderType.RECURRING_ORDER_REQUESTS:
            return filter.tab === RecurringOrderTab.ACTIVE
                ? SettingsKey.RECURRING_ORDER_SORT_REQUESTS_ACTIVE
                : SettingsKey.RECURRING_ORDER_SORT_REQUESTS_TERMINATED;
        case RecurringOrderType.RECURRING_ORDER_PURCHASE:
            return filter.tab === RecurringOrderTab.ACTIVE
                ? SettingsKey.RECURRING_ORDER_SORT_PURCHASES_ACTIVE
                : SettingsKey.RECURRING_ORDER_SORT_PURCHASES_TERMINATED;
    }
}

export function getInfoTextLocaleTag(tabType: RecurringOrderTab): string {
    switch (tabType) {
        case RecurringOrderTab.ACTIVE:
            return 'recurringOrder:activeText';
        case RecurringOrderTab.TERMINATED:
            return 'recurringOrder:inactiveText';
    }
}

export function getRRuleFreqLocizeKeyAndValue(rrule: string): string | undefined {
    const params = new URLSearchParams(rrule.replace(/;/g, '&'));
    const frequency = params.get('FREQ');
    if (frequency === 'DAILY') return 'recurringOrder:DAILY';
    if (frequency === 'WEEKLY') return 'recurringOrder:WEEKLY';
    if (frequency === 'MONTHLY') return 'recurringOrder:MONTHLY';
    return undefined;
}

export function getRRuleInterval(rrule: string): number | undefined {
    const params = new URLSearchParams(rrule.replace(/;/g, '&'));
    const interval = params.get('INTERVAL');
    if (interval) return Number(interval);
    return undefined;
}

export function getTradeRole(item: RecurringOrderListView, companyRef: string): 'BUYER' | 'SELLER' | 'NONE' {
    if (item.links.buyingCompany === companyRef) return 'BUYER';
    if (item.links.sellingCompany === companyRef) return 'SELLER';
    return 'NONE';
}

export function applyFilter(
    items: RecurringOrderTableItems,
    filter: RecurringOrderTableFilter,
    companyRef: string,
    userRef: string,
    searchStringMap: Map<string, string[]> | undefined,
): RecurringOrderListView[] {
    if (!items.all) return [];
    function matchesTab(item: RecurringOrderListView, filter: RecurringOrderTableFilter): boolean {
        switch (filter.tab) {
            case RecurringOrderTab.ACTIVE:
                return ['PENDING', 'ACCEPTED', 'ORDERED'].includes(item.status);
            case RecurringOrderTab.TERMINATED:
                return ['CANCELED_BY_BUYER', 'CANCELED_BY_SELLER', 'REJECTED'].includes(item.status);
        }
    }

    let filteredItems: RecurringOrderListView[] = [];

    switch (filter.recurringOrderType) {
        case RecurringOrderType.RECURRING_ORDER_PURCHASE:
            filteredItems = items.all.filter((item) => item.links.buyingCompany == companyRef);
            break;
        case RecurringOrderType.RECURRING_ORDER_REQUESTS:
            filteredItems = items.all.filter((item) => item.links.sellingCompany == companyRef);
            break;
    }

    filteredItems = filteredItems.filter((item) => matchesTab(item, filter));

    if (filter.searchString && searchStringMap) {
        const foundItems = new Array<string>();

        for (const [key, entities] of searchStringMap.entries()) {
            const filteredEntities = entities.filter((entity) =>
                entity.toLowerCase().includes(filter.searchString.toLowerCase()),
            );

            if (filteredEntities.length > 0) {
                foundItems.push(key);
            }
        }

        filteredItems = filteredItems.filter((entity) => foundItems.includes(entity.links.self));
    }

    if (filter.dateFrom?.isValid()) {
        filteredItems = filteredItems.filter(
            (item) => moment(item.dateCreated).startOf('day') >= filter.dateFrom!.startOf('day'),
        );
    }

    if (filter.dateUntil?.isValid()) {
        filteredItems = filteredItems.filter(
            (item) => moment(item.dateCreated).startOf('day') <= filter.dateUntil!.startOf('day'),
        );
    }

    if (filter.areOnlyCurrentUserResultsShown) {
        if (filter.recurringOrderType == 'RECURRING_ORDER_PURCHASE') {
            filteredItems = filteredItems.filter((item) => item.links.buyingPerson == userRef);
        } else {
            filteredItems = filteredItems.filter((item) => item.links.sellingPerson == userRef);
        }
    }

    return filteredItems;
}

export function sortItems(
    items: RecurringOrderListView[],
    sortSettingPrefix: string | undefined,
    sortSettingValue: string | undefined,
    itemRefToCompanyMap: Map<string, string> | undefined,
    itemRefToProductMap: Map<string, string> | undefined,
    itemRefToResponsiblePersonNameMap: Map<string, string> | undefined,
): RecurringOrderListView[] {
    if (!itemRefToCompanyMap || !itemRefToProductMap) return items;
    function sortByLocalCompare(array: RecurringOrderListView[]): RecurringOrderListView[] {
        let valueMap: Map<string, string> | undefined = undefined;

        switch (sortSettingPrefix) {
            case 'PRODUCT':
                valueMap = itemRefToProductMap;
                break;
            case 'COMPANY':
                valueMap = itemRefToCompanyMap;
                break;
            case 'PERSON':
                valueMap = itemRefToResponsiblePersonNameMap;
        }
        return sortByValueMap(array, valueMap, sortSettingValue);
    }

    if (sortSettingPrefix && sortSettingValue) {
        switch (sortSettingPrefix) {
            case 'STATUS':
                return sortByDateCreated(items, 'DESC').filter((item) => {
                    if (sortSettingValue == 'CANCELED') {
                        return ['CANCELED_BY_BUYER', 'CANCELED_BY_SELLER'].includes(item.status);
                    } else if (sortSettingValue == 'INVOICE_PRESENT') {
                        return !!item.links.invoices?.length && item.status == RecurringOrderStatus.ORDERED;
                    } else if (sortSettingValue == 'ORDERED') {
                        return !item.links.invoices?.length && item.status == RecurringOrderStatus.ORDERED;
                    } else return item.status.toString() == sortSettingValue;
                });
            case 'PERSON':
            case 'PRODUCT':
            case 'COMPANY':
                return sortByLocalCompare(sortByDateCreated(items, 'DESC'));
            default:
                return sortByDateCreated(items, sortSettingValue);
        }
    } else {
        return sortByDateCreated(items, sortSettingValue);
    }
}

export function composeMaps(newState: Partial<RecurringOrderTableComponentState>, t: TFunction) {
    if (!newState.items?.all?.length || !newState.rowDataMap || !newState.ownCompanyRef) return;
    newState.itemRefToCompanyNameMap = new Map<string, string>();
    newState.itemRefToTranslatedProductMap = new Map<string, string>();
    newState.refToSearchStrings = new Map<string, string[]>();
    newState.itemRefToResponsiblePersonNameMap = new Map<string, string>();
    newState.items.toShow.forEach((item) => {
        const rowData = newState.rowDataMap!.get(item.links.self);
        if (!rowData) return;
        const companyName =
            (getTradeRole(item, newState.ownCompanyRef!) == 'SELLER'
                ? rowData.buyingCompany?.name
                : rowData.sellingCompany?.name) ?? t('company:deletedCompany');

        const personName =
            getTradeRole(item, newState.ownCompanyRef!) == 'BUYER'
                ? rowData.buyingPerson
                    ? `${rowData.buyingPerson.firstname} ${rowData.buyingPerson.lastname}`
                    : t('company:deletedPerson')
                : rowData.sellingPerson
                  ? `${rowData.sellingPerson.firstname} ${rowData.sellingPerson.lastname}`
                  : t('company:deletedPerson');

        newState.itemRefToResponsiblePersonNameMap!.set(item.links.self, personName);

        newState.itemRefToCompanyNameMap!.set(item.links.self, companyName);

        const productLabel = rowData.product?.label;
        if (productLabel) {
            const translatedProduct = t(`ontofood:${productLabel}`);
            newState.itemRefToTranslatedProductMap!.set(item.links.self, translatedProduct);
        }

        const searchStrings: string[] = [];

        const translatedProduct = newState.itemRefToTranslatedProductMap!.get(item.links.self);
        if (translatedProduct) searchStrings.push(translatedProduct);
        searchStrings.push(companyName);
        searchStrings.push(personName);

        newState.refToSearchStrings!.set(item.links.self, searchStrings);
    });
}

export function setRowData(newState: Partial<RecurringOrderTableComponentState>) {
    if (!newState.items?.all?.length) {
        newState.isInitialFetchingComplete = true;
    } else {
        newState.items.all.forEach((item) => {
            if (item) {
                const product = item.links.product ? ProductStore.getOne(item.links.product) : undefined;
                const amount = item.amount;
                const firstDeliveryDate = item.firstDeliveryDate;
                const deliveryFreq = getRRuleFreqLocizeKeyAndValue(item.rrule);
                const deliveryInterval = getRRuleInterval(item.rrule);
                const lastDeliveryDate = item.lastDeliveryDate;
                const totalPrice = item.totalPrice;
                const pricePerUnit = item.pricePerUnit;
                const dateCreated = item.dateCreated;
                const sellingCompany = item.links.sellingCompany
                    ? CompanyStore.getOne(item.links.sellingCompany)
                    : undefined;
                const buyingCompany = item.links.buyingCompany
                    ? CompanyStore.getOne(item.links.buyingCompany)
                    : undefined;
                const sellingPerson = item.links.sellingPerson
                    ? PersonStore.getOne(item.links.sellingPerson)
                    : undefined;
                const buyingPerson = item.links.buyingPerson ? PersonStore.getOne(item.links.buyingPerson) : undefined;
                const status = item.status;
                const orderContainers = item.orderContainers;
                const invoiceAddress = item.invoiceAddress;
                const deliveryAddress = item.deliveryAddress;
                const ownCompanyRef = newState.ownCompanyRef;
                const metaContainerType = MetaStore.getOne(MetaStaticEntityType.CONTAINER_TYPE);
                const containerTypes = metaContainerType?.data as ContainerType[];
                const levelsOfProcessing = item.levelsOfProcessing;
                const offerRef = item.links.offer;
                const recurringOrderRef = item.links.self;
                const invoiceRef = item.links.invoices?.length ? item.links.invoices[0] : undefined;
                const invoice = invoiceRef ? InvoiceStore.getOne(invoiceRef) : undefined;

                if (
                    amount &&
                    firstDeliveryDate &&
                    lastDeliveryDate &&
                    deliveryFreq &&
                    deliveryInterval &&
                    totalPrice &&
                    pricePerUnit &&
                    dateCreated &&
                    status &&
                    ownCompanyRef &&
                    offerRef
                ) {
                    newState.rowDataMap?.set(item.links.self, {
                        product,
                        amount,
                        firstDeliveryDate,
                        deliveryFreq,
                        deliveryInterval,
                        lastDeliveryDate,
                        totalPrice,
                        pricePerUnit,
                        dateCreated,
                        sellingCompany,
                        buyingCompany,
                        status,
                        sellingPerson,
                        orderContainers,
                        invoiceAddress,
                        deliveryAddress,
                        buyingPerson,
                        ownCompanyRef,
                        levelsOfProcessing,
                        containerTypes,
                        recurringOrderRef,
                        offerRef,
                        invoice,
                    });
                    newState.isInitialFetchingComplete = true;
                }
            }
        });
    }
}
