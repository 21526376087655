import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PriceRequestService from 'api/PriceRequestService';
import { PreOrderTranslationMapping } from 'components/marketplace/shared/PreOrderTranslationMapping';
import { PriceRequest, PriceRequestWriteView } from 'model/PriceRequest';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { ComponentBase } from 'resub';
import { PriceRequestStore } from 'store';
import { theme } from 'style/NearbuyStyle';
import { FormValidationHelper, WithFormValidationHelper, withFormValidationHelper } from 'util/FormErrors';
import { Transition } from 'util/style-helpers';
import { captureWebEvent } from 'util/AnalyticUtils';

interface ProvidePriceProps extends WithTranslation {
    priceRequestRef: string;
    infoCard: React.ReactElement;
    onClose: (remove: boolean) => void;
    isOpen: boolean;
    mapping: PreOrderTranslationMapping;
}

interface ProvidePriceState extends WithFormValidationHelper {
    price: number | undefined;
    pricePerUnit?: number;
    priceRequest?: PriceRequest;
    isSendButtonEnabled: boolean;
}

export class ProvidePriceDialog extends ComponentBase<ProvidePriceProps, ProvidePriceState> {
    @withFormValidationHelper<ProvidePriceDialog>('validate')
    protected _buildState(
        props: ProvidePriceProps,
        initialBuild: boolean,
        incomingState: Readonly<ProvidePriceState> | undefined,
    ): Partial<ProvidePriceState> | undefined {
        return {
            priceRequest: PriceRequestStore.getOne(props.priceRequestRef),
        };
    }

    validate(formHelper: FormValidationHelper, prevState: ProvidePriceState, props: ProvidePriceProps): void {
        if (prevState.price === undefined) return;
        if (prevState.price <= 0) {
            formHelper.setError('priceFormError', props.t('priceRequest:errorInvalidPrice'));
        }
    }

    setPrice(): void {
        if (this.state.priceRequest && this.state.pricePerUnit) {
            const priceRequestWriteView = new PriceRequestWriteView(
                this.state.priceRequest.links.offer,
                '',
                undefined,
                undefined,
                this.state.pricePerUnit,
            );
            PriceRequestService.setPrice(this.state.priceRequest.links.self, priceRequestWriteView).subscribe(() =>
                this.props.onClose(false),
            );
        }
    }

    showContent(): React.ReactElement {
        return (
            <Grid container>
                <Typography sx={{ paddingTop: theme.spacing(3) }}>
                    {this.props.t('priceRequest:addYourPricePhrase')}
                </Typography>
                <Grid
                    container
                    direction={'row'}
                    sx={{
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(3),
                    }}
                >
                    <Grid sx={{ paddingRight: theme.spacing(3) }}>
                        <NumberFormat
                            value={this.state.price}
                            label={this.props.t('priceRequest:totalPrice')}
                            onValueChange={(numbers: NumberFormatValues) => {
                                if (numbers.floatValue) {
                                    this.setState({
                                        price: numbers.floatValue,
                                        isSendButtonEnabled: numbers.floatValue > 0,
                                        pricePerUnit: numbers.floatValue / this.state.priceRequest!.amount.amount,
                                    });
                                } else
                                    this.setState({
                                        price: 0,
                                        isSendButtonEnabled: false,
                                        pricePerUnit: 0,
                                    });
                            }}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            customInput={TextField}
                            required
                            {...this.state.formValidationHelper.getFormError('priceFormError')}
                        />
                    </Grid>
                    <Grid>
                        <NumberFormat
                            disabled
                            value={this.state.pricePerUnit}
                            label={
                                this.props.t('priceRequest:euroPer') +
                                this.props.t('units:' + this.state.priceRequest?.amount.unit)
                            }
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            customInput={TextField}
                        />
                    </Grid>
                </Grid>
                <Typography>{this.props.t('priceRequest:providePriceInfo', this.props.mapping)}</Typography>
            </Grid>
        );
    }

    cancelAndClose(): void {
        this.props.onClose(false);
        this.setState({
            price: undefined,
            pricePerUnit: undefined,
        });
    }

    showButtons(): React.ReactElement {
        return (
            <Grid container spacing={1} justifyContent={'flex-end'} key={'dialog-button-grid-container'}>
                <Grid item>
                    <Button
                        onClick={() => {
                            captureWebEvent('cancel-price-suggestion');
                            this.cancelAndClose();
                        }}
                        variant={'outlined'}
                    >
                        {this.props.t('dialogs:CANCEL')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => {
                            captureWebEvent('send-price-suggestion');
                            this.setPrice();
                        }}
                        variant="contained"
                        disabled={!this.state.isSendButtonEnabled}
                    >
                        {this.props.t('dialogs:SEND')}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    showCloseIcon(): React.ReactFragment {
        return (
            <IconButton
                onClick={(): void => this.cancelAndClose()}
                size="medium"
                sx={{
                    padding: 0,
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                <CloseIcon
                    sx={{
                        fontSize: '1.3em',
                        color: theme.palette.primary.main,
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: theme.palette.primary.dark,
                        },
                    }}
                />
            </IconButton>
        );
    }

    render() {
        if (!this.state.priceRequest) return null;
        return (
            <Dialog
                open={this.props.isOpen ?? false}
                TransitionComponent={Transition}
                onClose={(event, reason) => {
                    if (event && reason === 'backdropClick') this.cancelAndClose();
                }}
            >
                <DialogTitle>
                    <Grid container direction={'row'} justifyContent={'space-between'}>
                        <Grid container item direction={'row'} xs={11}>
                            {this.props.t('priceRequest:proposePrice')}
                        </Grid>
                        <Grid>{this.showCloseIcon()}</Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {this.props.infoCard}
                    {this.showContent()}
                </DialogContent>
                <DialogActions>{this.showButtons()}</DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation(['units', 'dialogs', 'priceRequest'])(ProvidePriceDialog);
