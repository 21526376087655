import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { WithStyles, WithTheme } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment as DateAdapter } from '@mui/x-date-pickers/AdapterMoment';
import RequestDialogHandlers from 'components/marketplace/requests/dialog/RequestDialogHandlers';
import { RequestDialogHelpers } from 'components/marketplace/requests/dialog/RequestDialogHelpers';
import ConfirmationDialog from 'components/messaging/chat/messagefactory/infocards/shared/ConfirmationDialog';
import OntofoodSelectComponent from 'components/ontofood/OntofoodSelectComponent';
import LevelOfProcessingComponent from 'components/product/LevelOfProcessingComponent';
import DeleteButtonWithDialog from 'components/shared/DeleteButtonWithDialog';

import StyledDatePickerComponent from 'components/shared/Popovers/StyledDatePickerComponent';
import { CompanyContacts, ContactType } from 'model/Contact';
import { MetaStaticEntityType } from 'model/Meta';
import { Person } from 'model/Person';
import { Product } from 'model/Product';
import { Request, RequestWriteView } from 'model/Request';
import moment, { Moment } from 'moment';
import 'moment/locale/de';
import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import {
    CompanyContactsStore,
    ContactsStore,
    EmploymentStore,
    MetaStore,
    PersonStore,
    ProductStore,
    RequestStore,
} from 'store';
import { theme } from 'style/NearbuyStyle';
import { deepCopy, isDeepEquality } from 'util/deep-equality-helpers';
import { FormValidationHelper, withFormValidationHelper, WithFormValidationHelper } from 'util/FormErrors';
import { getUuidFromString, without } from 'util/helpers';
import { Transition, wrapButtonWithTooltip } from 'util/style-helpers';
import { Unit } from 'model/Unit';
import { captureWebEvent } from 'util/AnalyticUtils';
import ContactSelectComponent from 'components/marketplace/shared/ContactSelectComponent';
import { Employment, EmploymentStatus } from 'model/Employment';
import HintComponent from 'components/hint/HintComponent';
import { Link } from 'react-router-dom';
import AmountSelectComponent from 'components/marketplace/shared/AmountSelectComponent';

const styles = (theme: Theme) =>
    createStyles({
        dialogContent: {
            padding: theme.spacing(3),
        },
        mandatoryField: {
            fontSize: '14px',
            textAlign: 'right',
            marginBottom: '20px',
        },
        textFieldFullWidth: {
            margin: 0,
            flexGrow: 1,
            width: '100%',
        },
        divider: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        dateHint: {
            padding: 0,
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
            textAlign: 'left',
        },
        employeeOverviewButton: {
            marginTop: theme.spacing(1),
        },
        requestDot: {
            fill: theme.palette.primary.main,
            marginRight: theme.spacing(1),
            '&:hover': {
                fill: theme.palette.primary.main,
            },
        },
    });

export interface RequestDialogProperties extends WithStyles<typeof styles>, WithTheme, WithTranslation {
    isOpen: boolean;
    companyRef: string;
    requestRef?: string | null;
    onClose?: () => void;
    onDelete?: () => void;
    isReadOnly?: boolean;
    calledFrom: string;
}

export interface RequestDialogState extends WithFormValidationHelper {
    request?: Request;
    requestWriteView?: RequestWriteView;
    initialRequestWriteView?: RequestWriteView;

    isCreateNew: boolean;

    selectedProduct?: Product;
    units?: Unit[];

    currentEmployment?: Employment;
    currentCompanyContacts?: CompanyContacts;
    personBySelectedContact?: Person;
    automaticallySetContact: string | null;

    hasChanges: boolean;
    isSaveButtonEnabled: boolean;
    hasFormValidationErrors: boolean;

    isOpen: boolean;
    isSaveLeavePreventionOpen: boolean;
}

export class RequestDialog extends ComponentBase<RequestDialogProperties, RequestDialogState> {
    @withFormValidationHelper<RequestDialog>('validate')
    protected _buildState(
        props: RequestDialogProperties,
        initialBuild: boolean,
        incomingState?: RequestDialogState,
    ): Partial<RequestDialogState> | undefined {
        const isDialogJustOpening = !!(initialBuild || (incomingState && props.isOpen && !incomingState.isOpen));
        const newState: Partial<RequestDialogState> = {
            ...incomingState,
            isOpen: props.isOpen,
            isSaveButtonEnabled: false,
            isCreateNew: props.requestRef === null,
            currentEmployment: EmploymentStore.getSelected(),
            currentCompanyContacts: CompanyContactsStore.getOne(this.props.companyRef),
            request: props.requestRef ? RequestStore.getOne(props.requestRef) : undefined,
        };

        if (isDialogJustOpening) {
            newState.hasFormValidationErrors = false;

            if (props.requestRef) {
                RequestStore.invalidateCache(props.requestRef);
            }
            if (newState.request) {
                newState.requestWriteView = RequestWriteView.create(newState.request);
            } else {
                newState.requestWriteView = RequestWriteView.create();
            }
            newState.initialRequestWriteView = deepCopy(newState.requestWriteView);
        }

        newState.units = MetaStore.getOne(MetaStaticEntityType.UNIT)?.data as Unit[];

        const isReadyForRequest = newState.requestWriteView && newState.initialRequestWriteView;

        if (!isReadyForRequest) return newState;

        if (newState.requestWriteView?.category) {
            newState.selectedProduct = incomingState?.requestWriteView?.category
                ? ProductStore.getOne(incomingState.requestWriteView.category)
                : undefined;
        }

        const contactRef = newState.requestWriteView?.contact;
        if (contactRef) {
            const contact = ContactsStore.getOne(contactRef);
            if (contact) {
                newState.personBySelectedContact = PersonStore.getOne(contact.links.person);
            }
        }

        const hasWriteViewChanges =
            !!(newState.requestWriteView && newState.initialRequestWriteView) &&
            !isDeepEquality(
                newState.isCreateNew ? without(newState.requestWriteView, 'contact') : newState.requestWriteView,
                newState.isCreateNew
                    ? without(newState.initialRequestWriteView, 'contact')
                    : newState.initialRequestWriteView,
            );

        const areMandatoryValuesGiven = !!(
            newState.requestWriteView?.totalAmount.amount !== undefined &&
            newState.requestWriteView.totalAmount.unit &&
            newState.requestWriteView.category
        );

        const contactFromWriteView = newState.requestWriteView?.contact;
        newState.hasChanges =
            hasWriteViewChanges ||
            (newState.isCreateNew ? newState.automaticallySetContact != contactFromWriteView : false);

        newState.isSaveButtonEnabled =
            newState.hasChanges && areMandatoryValuesGiven && !newState.hasFormValidationErrors;

        return newState;
    }

    componentDidUpdate(prevProps: Readonly<RequestDialogProperties>, prevState: RequestDialogState, prevContext: any) {
        super.componentDidUpdate(prevProps, prevState, prevContext);
        const hasFormValidationErrors = this.state.formValidationHelper.hasErrors();
        if (prevState.hasFormValidationErrors !== hasFormValidationErrors) {
            this.setState({ hasFormValidationErrors });
        }
    }

    validate(formHelper: FormValidationHelper, prevState: RequestDialogState, props: RequestDialogProperties): void {
        RequestDialogHelpers.validate(formHelper, prevState, props);
    }

    getTitle(): string {
        const tRequest = this.props.t('offer:request');
        const tEdit = this.props.t('offer:edit');
        const tCreate = this.props.t('offer:create');

        return this.props.isReadOnly ? tRequest : `${tRequest} ${this.state.request ? tEdit : tCreate}`;
    }

    showSaveLeavePrevention(handlers: RequestDialogHandlers): React.ReactFragment {
        return (
            <ConfirmationDialog
                calledFrom={'RequestDialog'}
                title={this.props.t('dialogs:saveChanges')}
                content={this.props.t('dialogs:savePreventionDialogContentLong')}
                defaultButtonText={this.props.t('dialogs:backToEdit')}
                buttonText2={this.props.t('dialogs:discard')}
                isOpen={this.state.isSaveLeavePreventionOpen}
                onClose={() => {
                    this.setState({ isSaveLeavePreventionOpen: false });
                }}
                buttonText1={this.props.t('dialogs:SAVE')}
                buttonAction1={handlers.onSave}
                isButtonAction1Disabled={!this.state.isSaveButtonEnabled}
                actionButton1Tooltip={
                    !this.state.isSaveButtonEnabled ? this.props.t('tooltips:disabledSaveButton') : ''
                }
                buttonAction2={handlers.onConfirmationDialogDiscardClick}
            />
        );
    }

    showDialogTitle(handlers: RequestDialogHandlers): ReactNode {
        return (
            <Grid container direction={'row'} justifyContent={'space-between'}>
                <Grid container sm={6} item alignItems={'center'}>
                    <FiberManualRecordIcon className={this.props.classes.requestDot} />
                    {this.getTitle()}
                </Grid>
                <FormControlLabel
                    control={wrapButtonWithTooltip(
                        <Switch
                            onChange={(event) => {
                                handlers.updateRequestWriteView({
                                    active: event.target.checked,
                                });
                            }}
                            checked={this.state.requestWriteView?.active}
                            data-testid="showOnMarketplaceToggle"
                            color="primary"
                        />,
                        undefined,
                    )}
                    label={this.props.t('offer:showInMarketplace') as string}
                />
            </Grid>
        );
    }

    showMandatoryLegend(): ReactNode {
        return (
            <Typography className={this.props.classes.mandatoryField}>{this.props.t('common:mandatory')}</Typography>
        );
    }

    showOntofoodSelect(handlers: RequestDialogHandlers): ReactNode {
        return (
            <OntofoodSelectComponent
                calledFrom={'RequestItemDialog'}
                value={this.state.requestWriteView?.category}
                onChange={handlers.onOntofoodSelectChange}
                productType={'market'}
                isReadOnly={this.props.requestRef !== null}
            />
        );
    }

    showTotalAmountSelect(handlers: RequestDialogHandlers, helpers: RequestDialogHelpers): ReactNode {
        const totalAmount = helpers.getTotalAmount();
        if (!totalAmount) return null;
        return (
            <AmountSelectComponent
                amountValue={totalAmount.amount ? totalAmount.amount : ''}
                onAmountChange={handlers.handleTotalAmountAmountChange}
                amountLabel={this.props.t('offer:amount')}
                amountFormError={this.state.formValidationHelper.getFormError('totalAmount')}
                unitValue={totalAmount.unit}
                onUnitChange={handlers.handleTotalAmountUnitChange}
                isUnitReadOnly={this.props.requestRef !== null}
                isAmountRequired
                fullLabelWidth
            />
        );
    }

    showDateFromPicker(momentFrom: Moment, handlers: RequestDialogHandlers): ReactNode {
        return (
            <LocalizationProvider dateAdapter={DateAdapter}>
                <StyledDatePickerComponent
                    value={momentFrom}
                    disablePast
                    label={this.props.t('offer:dateFrom')}
                    onChange={handlers.onDateFromChange}
                    readOnly={this.props.isReadOnly}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            {...this.state.formValidationHelper.getFormError('noFromDate')}
                            sx={{
                                button: {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            required
                        />
                    )}
                />
            </LocalizationProvider>
        );
    }

    showDateEndPicker(momentEnd: Moment, momentFrom: Moment, handlers: RequestDialogHandlers): ReactNode {
        return (
            <LocalizationProvider dateAdapter={DateAdapter}>
                <StyledDatePickerComponent
                    value={momentEnd ?? ''}
                    label={`${this.props.t('offer:dateUntil')}`}
                    minDate={momentFrom}
                    readOnly={this.props.isReadOnly}
                    onChange={handlers.onDateEndChange}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            {...this.state.formValidationHelper.getFormError('noUntilDate')}
                            sx={{
                                button: {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        />
                    )}
                    disablePast
                />
            </LocalizationProvider>
        );
    }

    showDateHint(momentEnd: Moment): ReactNode {
        return momentEnd ? (
            <Typography className={this.props.classes.dateHint}>{this.props.t('dialogs:dateHintRequest')}</Typography>
        ) : null;
    }

    showProductDescriptionInput(handlers: RequestDialogHandlers): ReactNode {
        const description = this.state.requestWriteView?.description;
        return (
            <TextField
                id="description"
                label={this.props.t('offer:productDescription')}
                className={this.props.classes.textFieldFullWidth}
                value={description}
                onChange={handlers.onProductDescriptionChange}
                multiline
                inputProps={{
                    readOnly: this.props.isReadOnly,
                }}
            />
        );
    }

    showLevelsOfProcessing(handlers: RequestDialogHandlers): ReactNode {
        const levelsOfProcessing = this.state.requestWriteView?.levelsOfProcessing ?? [];
        if (!levelsOfProcessing) return null;
        return (
            <LevelOfProcessingComponent
                selectedLopRefs={levelsOfProcessing}
                remove={handlers.removeLevelOfProcessing}
                update={handlers.updateLevelsOfProcessing}
            />
        );
    }

    showDialogButtons(handlers: RequestDialogHandlers): ReactNode {
        return [
            !this.props.isReadOnly && this.props.onDelete && this.state.request && (
                <DeleteButtonWithDialog
                    onDelete={() => {
                        if (this.props.onDelete) {
                            this.props.onDelete();
                            captureWebEvent(`delete-dialog-calledFrom-${this.props.calledFrom}`);
                        }
                    }}
                    key={'delete-button'}
                />
            ),
            !this.props.isReadOnly ? (
                <Grid container spacing={1} justifyContent={'flex-end'} key={'dialog-button-grid-container'}>
                    <Grid item>
                        <Button
                            onClick={() => {
                                handlers.onCancel();
                                handlers.captureRequestEvent('cancel');
                            }}
                            variant={'outlined'}
                        >
                            {this.props.t('dialogs:CANCEL')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={() => {
                                handlers.onSave();
                                handlers.captureRequestEvent('save');
                            }}
                            variant="contained"
                            disabled={!this.state.isSaveButtonEnabled}
                        >
                            {this.props.t('dialogs:SAVE')}
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <Button onClick={this.props.onClose}>{this.props.t('dialogs:BACK')}</Button>
            ),
        ];
    }

    showContactSelection(handlers: RequestDialogHandlers): ReactNode {
        const contact = this.state.requestWriteView?.contact;

        return (
            <ContactSelectComponent
                type={ContactType.PROCUREMENT}
                preSelectedContactRef={
                    this.state.isCreateNew
                        ? contact != this.state.automaticallySetContact
                            ? contact ?? null
                            : undefined
                        : contact ?? null
                }
                companyRef={this.props.companyRef}
                onChange={handlers.onSelectedContactRefChange}
            />
        );
    }

    showContactMissingHint(): ReactElement | null {
        const selectedContact = this.state.requestWriteView?.contact;
        if (!this.state.requestWriteView) return null;

        if (!this.state.currentEmployment) return null;

        const ownCompanyUuid = getUuidFromString(this.state.currentEmployment.links.company);

        const isContactNotAvailable = !this.state.currentCompanyContacts?.contactPersons.length;
        const hint = isContactNotAvailable
            ? 'request:NoContactAvailable'
            : !selectedContact
              ? this.state.currentEmployment.status === EmploymentStatus.MANAGER
                  ? 'request:adminContactNotSelected'
                  : 'request:employeeContactNotSelected'
              : undefined;

        return (
            <>
                {!!hint && <HintComponent text={this.props.t(hint)} isWarningSymbolShown={true} />}
                {isContactNotAvailable && (
                    <Grid container item justifyContent={'end'} style={{ margin: 7 }} md={12}>
                        <Button
                            className={this.props.classes.employeeOverviewButton}
                            component={Link}
                            to={'/edit/company/' + ownCompanyUuid + '/employees'}
                            variant="outlined"
                        >
                            {this.props.t('offer:toEmployeeOverview')}
                        </Button>
                    </Grid>
                )}
            </>
        );
    }

    showDialogContent(handlers: RequestDialogHandlers, helpers: RequestDialogHelpers): ReactNode {
        if (!this.state.requestWriteView) return null;

        const momentFrom = moment(this.state.requestWriteView!.dateFrom);
        const momentEnd = moment(this.state.requestWriteView!.dateEnd);

        return (
            <Container>
                {this.showMandatoryLegend()}
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid
                        item
                        container
                        spacing={1}
                        sm={6}
                        direction={'column'}
                        sx={{
                            paddingLeft: theme.spacing(1),
                        }}
                    >
                        <Grid item sm={12}>
                            {this.showOntofoodSelect(handlers)}
                        </Grid>
                        {this.showTotalAmountSelect(handlers, helpers)}
                    </Grid>
                    <Grid container item spacing={1} sm={6} direction={'column'}>
                        <Grid container item spacing={1} sm={12} direction={'row'} justifyContent={'flex-start'}>
                            <Grid item sm={6}>
                                {this.showDateFromPicker(momentFrom, handlers)}
                            </Grid>
                            <Grid item sm={6}>
                                {this.showDateEndPicker(momentEnd, momentFrom, handlers)}
                            </Grid>
                            <Grid item sm={12}>
                                {this.showDateHint(momentEnd)}
                            </Grid>
                            <Grid item sm={12} justifyContent={'flex-start'}>
                                {this.showProductDescriptionInput(handlers)}
                            </Grid>
                            <Grid item sm={12} justifyContent={'center'}>
                                {this.showLevelsOfProcessing(handlers)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider className={this.props.classes.divider} />
                <Grid container item style={{ margin: 0, padding: 0 }} md={12} alignItems="flex-start">
                    {this.showContactSelection(handlers)}
                </Grid>
                {this.showContactMissingHint()}
            </Container>
        );
    }

    render(): React.ReactElement | null {
        const helpers = new RequestDialogHelpers(this.state);
        const handlers = new RequestDialogHandlers(this.props, this.state, this.setState.bind(this), helpers);

        return (
            <Dialog
                open={this.props.isOpen ?? false}
                maxWidth="md"
                fullWidth
                sx={{ padding: theme.spacing(2) }}
                TransitionComponent={Transition}
                onClose={handlers.onClose}
            >
                {this.showSaveLeavePrevention(handlers)}
                <DialogTitle>{this.showDialogTitle(handlers)}</DialogTitle>
                <DialogContent className={this.props.classes.dialogContent}>
                    {this.showDialogContent(handlers, helpers)}
                </DialogContent>
                <DialogActions>{this.showDialogButtons(handlers)}</DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation(['offer', 'request', 'common', 'dialogs', 'tooltips'])(
    withStyles(styles, { withTheme: true })(RequestDialog),
);
