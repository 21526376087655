import PostHog from 'posthog-js';
import { isDeepEquality } from 'util/deep-equality-helpers';

type EventProperties = Record<string, any>;

interface DifferingKeys<T> {
    key: keyof T;
    condition: boolean | T[keyof T];
}

export interface EventParam {
    key: string;
    condition: any;
}

export interface CaptureMultipleEventsParams {
    params: EventParam[];
    eventName?: string;
}

const specialKeyMappings: Record<string, (value: any) => any> = {
    minAmount: (value) => value.amount,
    totalAmount: (value) => value.amount,
};

function getValue<T>(obj: T, key: keyof T): any {
    const value = obj[key];
    const specialMapping = specialKeyMappings[key as string];
    return specialMapping ? specialMapping(value) : value;
}

export function captureWebEvent(eventName: string, prop?: EventProperties): void {
    PostHog.capture(eventName, prop);
}

export function captureMultipleEvents({ params, eventName = '' }: CaptureMultipleEventsParams): void {
    params.forEach((filter) => {
        if (!filter.condition) return;

        const eventKey = `${eventName}-${filter.key}`;
        captureWebEvent(eventKey);
    });
}

export function generateParamList<T extends object>(
    obj: T,
    keys: (keyof T)[],
    useBooleanConditions = false,
): DifferingKeys<T>[] {
    return keys.map((key) => {
        const value = getValue(obj, key);
        return {
            key,
            condition: useBooleanConditions ? !!value : value,
        };
    });
}

export function getDifferingKeys<T extends object>(objects: T[], keys: (keyof T)[]): DifferingKeys<T>[] {
    return keys
        .filter((key) => {
            const referenceValue = getValue(objects[0], key);
            return !objects.every((obj) => {
                const currentValue = getValue(obj, key);
                if (referenceValue instanceof Date && currentValue instanceof Date) {
                    return referenceValue.getTime() === currentValue.getTime();
                }
                if (Array.isArray(referenceValue) && Array.isArray(currentValue)) {
                    return isDeepEquality(referenceValue, currentValue);
                }
                return referenceValue === currentValue;
            });
        })
        .map((differentKey) => ({ key: differentKey, condition: true }));
}

export function getMarketFilterDateRange(startDate: string, endDate: string): string | undefined {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        return;
    }
    const timeDiff = end.getTime() - start.getTime();
    const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
    return daysDiff.toString();
}
