import Box from '@mui/material/Box';
import { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import UserSettingsService from 'api/UserSettingsService';
import OfferTableRow from 'components/marketplace/marketitem/table/OfferTableRow';
import RequestTableRow from 'components/marketplace/marketitem/table/RequestTableRow';
import TradeTableHeaderSelect from 'components/marketplace/tradetable/TradeTrableHeaderSelect';
import RequestFullDetailDialog from 'components/marketplace/requests/RequestFullDetailDialog';
import { Company } from 'model/Company';
import { Offer } from 'model/Offer';
import { Request } from 'model/Request';
import { SettingsKey } from 'model/UserSettings';
import * as React from 'react';
import { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { CompanyOfferStore, CompanyRequestStore, CompanyStore, ProductStore, UserSettingsStore } from 'store';
import { theme } from 'style/NearbuyStyle';
import { getSortSettingPrefixAndValue, sortByDateCreated, sortByValueMap } from 'util/TableHelpers';
import OfferDetailViewDialog from 'components/marketplace/offers/OfferDetailViewDialog';

interface MarketItemTableProps extends WithTranslation {
    active: boolean;
    readOnly: boolean;
    type: 'Offer' | 'Request';
    companyRef?: string;
}

interface MarketItemTableState {
    company: Company;
    items: (Offer | Request)[];
    currentSortSettingsKey: SettingsKey;
    currentSortSetting: string | null | undefined;
    currentSortSettingPrefix: string | undefined;
    currentSortSettingValue: string | undefined;
    refToTranslatedProducts: Map<string, string>;
    isOfferFullDetailDialogOpen: boolean;
    isRequestFullDetailDialogOpen: boolean;
    refForDetailDialog: string | undefined;
}

class MarketItemTable extends ComponentBase<MarketItemTableProps, MarketItemTableState> {
    protected _buildState(
        props: MarketItemTableProps,
        initialBuild: boolean,
        incomingState: Readonly<MarketItemTableState> | undefined,
    ): Partial<MarketItemTableState> | undefined {
        const newState: Partial<MarketItemTableState> = {
            company: props.companyRef ? CompanyStore.getOne(props.companyRef) : CompanyStore.getSelected(),
            refToTranslatedProducts: new Map<string, string>(),
            currentSortSettingsKey:
                props.type == 'Offer'
                    ? props.active
                        ? SettingsKey.SORT_OFFERS_ACTIVE
                        : SettingsKey.SORT_OFFERS_TERMINATED
                    : props.active
                      ? SettingsKey.SORT_REQUESTS_ACTIVE
                      : SettingsKey.SORT_REQUESTS_TERMINATED,
        };

        if (initialBuild) {
            newState.isOfferFullDetailDialogOpen = false;
            newState.isRequestFullDetailDialogOpen = false;
        }

        if (newState.company) {
            newState.items = (
                props.type == 'Offer'
                    ? (CompanyOfferStore.search(newState.company.links.self) as (Offer | Request)[])
                    : (CompanyRequestStore.search(newState.company.links.self) as (Offer | Request)[])
            ).filter((item) => item.active === props.active);

            newState.items.forEach((item) => {
                const productLabel = ProductStore.getOne(item.links.category ?? '')?.label;

                if (productLabel) {
                    const translatedProduct = props.t('ontofood:' + productLabel);
                    newState.refToTranslatedProducts!.set(item.links.self, translatedProduct);
                }
            });
        }

        if (!newState.items) return newState;

        newState.currentSortSetting = incomingState?.currentSortSetting;
        if (newState.currentSortSettingsKey && !newState.currentSortSetting) {
            newState.currentSortSetting = !props.companyRef
                ? UserSettingsStore.getOne(newState.currentSortSettingsKey)?.settings?.replace(/['"]+/g, '')
                : null;
        }

        [newState.currentSortSettingPrefix, newState.currentSortSettingValue] = getSortSettingPrefixAndValue(
            newState.currentSortSetting,
        );

        newState.items = sortByDateCreated(newState.items, 'DESC');

        if (newState.currentSortSettingPrefix && newState.currentSortSettingValue) {
            newState.items =
                newState.currentSortSettingPrefix == 'PRODUCT'
                    ? sortByValueMap(newState.items, newState.refToTranslatedProducts, newState.currentSortSettingValue)
                    : newState.currentSortSettingPrefix == 'DATE'
                      ? newState.items.sort((a, b) => {
                            if (a.dateFrom && b.dateFrom && a.dateEnd && b.dateEnd) {
                                return (
                                    (a.dateFrom > b.dateFrom
                                        ? 1
                                        : a.dateFrom < b.dateFrom
                                          ? -1
                                          : a.dateEnd > b.dateEnd
                                            ? 1
                                            : -1) * (newState.currentSortSettingValue == 'ASC' ? 1 : -1)
                                );
                            } else return 0;
                        })
                      : newState.items;
        }
        return newState;
    }

    handleSortSettingChange(event: SelectChangeEvent) {
        const sortValue = [event.target.name, event.target.value].join('_');

        if (!this.props.companyRef) {
            UserSettingsService.saveSetting(
                this.state.currentSortSettingsKey,
                event.target.value != 'NONE' ? sortValue : null,
            ).subscribe(() => {
                UserSettingsStore.invalidateCache(this.state.currentSortSettingsKey);
                this.setState({
                    currentSortSetting: event.target.value != 'NONE' ? sortValue : null,
                });
            });
        } else {
            this.setState({
                currentSortSetting: event.target.value != 'NONE' ? sortValue : null,
            });
        }
    }

    getTitleRow() {
        const productTitle = this.props.t('common:product');
        const dateTitle = this.props.t('offer:timePeriod');
        const titles: (string | undefined)[] = [];
        titles.push(productTitle);
        titles.push(this.props.t('offer:amount'));
        if (this.props.type == 'Offer') {
            titles.push(this.props.t('offer:pricePerUnit'));
        }
        titles.push(dateTitle);
        titles.push(this.props.t('offer:productDescription'));
        titles.push(undefined);

        return (
            <TableRow>
                {titles.map((title, index) => {
                    let name = 'DEFAULT';
                    let onlyTitle = false;
                    const options: string[] = ['ASC', 'DESC', 'NONE'];
                    switch (title) {
                        case productTitle:
                            name = 'PRODUCT';
                            break;
                        case dateTitle:
                            name = 'DATE';
                            break;
                        default:
                            onlyTitle = true;
                    }

                    return (
                        <TableCell key={title ?? index}>
                            {onlyTitle ? (
                                <Typography fontWeight={'bold'} fontSize={14} noWrap>
                                    {title}
                                </Typography>
                            ) : (
                                <TradeTableHeaderSelect
                                    name={name}
                                    title={title}
                                    options={options}
                                    currentSortSettingPrefix={this.state.currentSortSettingPrefix}
                                    currentSortSettingValue={this.state.currentSortSettingValue}
                                    handleSortSettingChange={this.handleSortSettingChange.bind(this)}
                                />
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    }

    getOfferTableRow(ref: string): ReactNode {
        return (
            <OfferTableRow
                offerRef={ref}
                key={ref}
                readOnly={this.props.readOnly}
                onClick={
                    this.props.companyRef
                        ? () =>
                              this.setState({
                                  isOfferFullDetailDialogOpen: true,
                                  refForDetailDialog: ref,
                              })
                        : undefined
                }
            />
        );
    }

    getRequestTableRow(ref: string): ReactNode {
        return (
            <RequestTableRow
                requestRef={ref}
                key={ref}
                readOnly={this.props.readOnly}
                onClick={
                    this.props.companyRef
                        ? () =>
                              this.setState({
                                  isRequestFullDetailDialogOpen: true,
                                  refForDetailDialog: ref,
                              })
                        : undefined
                }
            />
        );
    }

    showOfferFullDetailDialog(): ReactNode {
        if (!this.state.refForDetailDialog) return null;

        return (
            <>
                {this.state.isOfferFullDetailDialogOpen ? (
                    <OfferDetailViewDialog
                        offerRef={this.state.refForDetailDialog}
                        isOpen
                        onClose={() => {
                            this.setState({ isOfferFullDetailDialogOpen: false });
                        }}
                    />
                ) : null}
            </>
        );
    }

    showRequestFullDetailDialog(): ReactNode {
        if (!this.state.refForDetailDialog) return null;
        return (
            <RequestFullDetailDialog
                requestRef={this.state.refForDetailDialog}
                isOpen={this.state.isRequestFullDetailDialogOpen}
                onClose={() => this.setState({ isRequestFullDetailDialogOpen: false })}
            />
        );
    }

    showTable(): ReactNode {
        if (this.state.items.length < 1) return null;
        return (
            <Table
                sx={{
                    tableLayout: 'auto',
                }}
            >
                <TableHead>{this.getTitleRow()}</TableHead>
                <TableBody>
                    {this.state.items &&
                        this.state.items.map((item) =>
                            this.props.type == 'Offer'
                                ? this.getOfferTableRow(item.links.self)
                                : this.getRequestTableRow(item.links.self),
                        )}
                </TableBody>
            </Table>
        );
    }

    showEmptyStateText(): ReactNode {
        if (this.state.items.length > 0) return null;
        return (
            <Typography
                sx={{
                    backgroundColor: theme.palette.grey[200],
                    borderRadius: 2,
                    color: theme.palette.primary.dark,
                    fontWeight: 600,
                    padding: theme.spacing(3),
                    textAlign: 'center',
                }}
            >
                {this.props.type === 'Offer'
                    ? this.props.t('offer:emptyStateDescription')
                    : this.props.t('request:emptyStateDescription')}
            </Typography>
        );
    }

    render(): ReactNode {
        return (
            <Box
                sx={{
                    minHeight: '20vh',
                    maxHeight: '68vh',
                    width: '100%',
                    overflow: 'auto',
                    borderRadius: '10px',
                }}
            >
                {this.showOfferFullDetailDialog()}
                {this.showRequestFullDetailDialog()}
                {this.showTable()}
                {this.showEmptyStateText()}
            </Box>
        );
    }
}

export default withTranslation(['common', 'offer', 'ontofood', 'request'])(MarketItemTable);
