import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { withResubAutoSubscriptions } from 'resub';
import { ExtendedTheme } from 'style/NearbuyStyle';
import { Transition, wrapButtonWithTooltip } from 'util/style-helpers';
import { getButtonVariant } from 'shared/buttons';
import { captureWebEvent } from 'util/AnalyticUtils';

interface ConfirmationDialogProps {
    isOpen: boolean;
    onClose: (remove: boolean) => void;
    title: string;
    content: string;
    buttonText1?: string;
    buttonAction1?: (status: never) => void;
    buttonText2?: string;
    buttonAction2?: (status: never) => void;
    defaultButtonText?: string;
    isDeleteModifierKeyNotice?: boolean;
    link?: never[];
    isButtonAction1Disabled?: boolean;
    isButtonAction2Disabled?: boolean;
    actionButton1Tooltip?: string;
    actionButton2Tooltip?: string;
    calledFrom: string;
}

function ConfirmationDialog(props: ConfirmationDialogProps) {
    /* Hooks */
    const { t } = useTranslation(['dialogs']);
    const theme = useTheme<ExtendedTheme>();
    /* States and Store Subscribed Constants */
    /* Effects */
    /* Styles und StyledComponents */
    /* JSX */

    const title = <DialogTitle>{props.title}</DialogTitle>;
    const contentWithLink = () => {
        return <Trans components={props.link ? props.link : []}>{props.content}</Trans>;
    };

    const content = (
        <DialogContent sx={{ color: theme.palette.text.primary }}>
            {props.link ? contentWithLink() : props.content}
        </DialogContent>
    );
    const cancelButton = (
        <Button
            variant={getButtonVariant('transparent')}
            onClick={() => {
                captureWebEvent(`confirmation-dialog-canceled-from-${props.calledFrom}`);
                props.onClose(false);
            }}
        >
            {props.defaultButtonText ? props.defaultButtonText : t('dialogs:CANCEL')}
        </Button>
    );
    const customButton1 = (
        <Button
            variant={getButtonVariant('primaryLight')}
            onClick={props.buttonAction1}
            disabled={props.isButtonAction1Disabled}
        >
            {props.buttonText1}
        </Button>
    );
    const customButton2 = (
        <Button
            variant={getButtonVariant('transparent')}
            onClick={props.buttonAction2}
            disabled={props.isButtonAction2Disabled}
        >
            {props.buttonText2}
        </Button>
    );
    const deleteModifierKeyNotice = (
        <Typography sx={{ fontSize: 'smaller', fontStyle: 'italic' }}>
            {t('dialogs:deleteModifierKeyNotice')}
        </Typography>
    );
    const actions = (
        <DialogActions
            sx={{
                flexWrap: 'wrap',
            }}
        >
            <Grid item>{cancelButton}</Grid>
            <Grid item>
                {props.buttonAction2 && props.buttonText2
                    ? props.actionButton2Tooltip
                        ? wrapButtonWithTooltip(customButton2, props.actionButton2Tooltip)
                        : customButton2
                    : null}
            </Grid>
            <Grid item>
                {props.buttonAction1 && props.buttonText1
                    ? props.actionButton1Tooltip
                        ? wrapButtonWithTooltip(customButton1, props.actionButton1Tooltip)
                        : customButton1
                    : null}
            </Grid>
            <Grid item>{props.isDeleteModifierKeyNotice ? deleteModifierKeyNotice : null}</Grid>
        </DialogActions>
    );

    return (
        <Dialog
            onClose={(event, reason) => {
                if (event && reason === 'backdropClick') {
                    props.onClose(false);
                }
            }}
            TransitionComponent={Transition}
            open={props.isOpen ?? false}
        >
            {props.title ? title : null}
            {content}
            {actions}
        </Dialog>
    );
}

export default withResubAutoSubscriptions(ConfirmationDialog);
