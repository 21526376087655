import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Grid, TextField, Theme, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Transition, wrapButtonWithTooltip } from 'util/style-helpers';
import { captureWebEvent } from 'util/AnalyticUtils';
import { OrderAddress, OrderAddressType } from 'model/Address';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ConfirmationDialog from 'components/messaging/chat/messagefactory/infocards/shared/ConfirmationDialog';
import { theme } from 'style/NearbuyStyle';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        color: theme.palette.primary.dark,
        lineHeight: '25px',
        fontSize: '20px',
        fontWeight: 'bold',
    },
    saveButton: {
        color: theme.palette.common.black,
        paddingRight: theme.spacing(2.5),
        paddingLeft: theme.spacing(2.5),
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
        },
        '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
        },
    },
    marginBottom: {
        marginBottom: '20px',
    },
    iconButton: {
        padding: 0,
    },
    closeButton: {
        fontSize: '1.4em',
        color: theme.palette.primary.main,
    },
}));

interface ChangeAddressDialogProps {
    isOpen: boolean;
    onClose: () => void;
    addressToEdit: OrderAddress;
    changeAddress: (address: OrderAddress) => void;
}

export const ChangeAddressDialog: React.FC<ChangeAddressDialogProps> = (props) => {
    const { t } = useTranslation(['payment', 'dialogs', 'membership']);
    const classes = useStyles(theme);

    const [isSaveLeavePreventionOpen, setSaveLeavePreventionOpen] = useState(false);
    const [addressName, setAddressName] = useState(props.addressToEdit.name);
    const [addressSuffix, setAddressSuffix] = useState(props.addressToEdit.suffix);
    const [streetAndNumber, setStreetAndNumber] = useState(props.addressToEdit.street);
    const [zipCode, setZipCode] = useState(props.addressToEdit.zipcode);
    const [city, setCity] = useState(props.addressToEdit.city);

    useEffect(() => {
        setAddressName(props.addressToEdit.name || '');
        setAddressSuffix(props.addressToEdit.suffix || '');
        setStreetAndNumber(props.addressToEdit.street || '');
        setZipCode(props.addressToEdit.zipcode || '');
        setCity(props.addressToEdit.city || '');
    }, [props.addressToEdit]);

    const closeDialog = () => {
        props.onClose();
    };

    const handleSave = () => {
        const updatedAddress = {
            type: props.addressToEdit.type,
            city: city || props.addressToEdit.city,
            suffix: addressSuffix || props.addressToEdit.suffix,
            zipcode: zipCode || props.addressToEdit.zipcode,
            street: streetAndNumber || props.addressToEdit.street,
            name: addressName || props.addressToEdit.name,
        };
        props.changeAddress(updatedAddress);
        closeDialog();
    };

    const isFormValid = () => {
        return addressName && streetAndNumber && zipCode && city;
    };

    const hasChanges = () => {
        return (
            addressName !== props.addressToEdit.name ||
            addressSuffix !== props.addressToEdit.suffix ||
            streetAndNumber !== props.addressToEdit.street ||
            zipCode !== props.addressToEdit.zipcode ||
            city !== props.addressToEdit.city
        );
    };

    const showTitleAndCloseIcon = () => (
        <Grid container direction="row" alignItems="center" sx={{ mb: -2.5 }}>
            <Grid item md={11.5}>
                <Typography className={`${classes.title} ${classes.marginBottom}`}>
                    {props.addressToEdit.type === OrderAddressType.DELIVERY
                        ? t('payment:deliveryAddressChange')
                        : t('payment:billingAddressChange')}
                </Typography>
            </Grid>
            <Grid item md={0.5} sx={{ pb: 1.5 }}>
                <CloseIcon
                    sx={{ cursor: 'pointer', marginLeft: 'auto', color: 'black' }}
                    onClick={() => {
                        captureWebEvent(`close-change-${props.addressToEdit.type}-address-dialog`);
                        closeDialog();
                    }}
                />
            </Grid>
        </Grid>
    );

    const showPromptAndInputFields = () => (
        <Grid container direction="column" justifyContent="flex-start" sx={{ mb: -2 }}>
            <form autoComplete="on">
                <Grid item>
                    <TextField
                        id="addressNameField"
                        name="addressName"
                        fullWidth
                        label={t('payment:addressName')}
                        required
                        onChange={(e) => setAddressName(e.target.value)}
                        value={addressName}
                        error={!addressName}
                        autoComplete="name"
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="addressSuffixField"
                        name="suffix"
                        fullWidth
                        label={t('payment:addressSupplement')}
                        onChange={(e) => setAddressSuffix(e.target.value)}
                        value={addressSuffix}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="streetAndNumberField"
                        name="streetAndNumber"
                        fullWidth
                        label={t('payment:streetAndNumber')}
                        required
                        onChange={(e) => setStreetAndNumber(e.target.value)}
                        value={streetAndNumber}
                        error={!streetAndNumber}
                        autoComplete="address-line1"
                    />
                </Grid>
                <Grid container item direction="row" spacing={1}>
                    <Grid item xs={6}>
                        <TextField
                            id="zipCodeField"
                            name="zipCode"
                            fullWidth
                            label={t('payment:zipCode')}
                            required
                            onChange={(e) => setZipCode(e.target.value)}
                            value={zipCode}
                            error={!zipCode}
                            autoComplete="postal-code"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="cityField"
                            name="city"
                            fullWidth
                            label={t('payment:city')}
                            required
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                            error={!city}
                            autoComplete="address-level2"
                        />
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );

    const showButtons = () => {
        const areAllMandatoryFieldsFillOut = isFormValid();
        const isSaveButtonDisabled = !areAllMandatoryFieldsFillOut || !hasChanges();

        return (
            <Grid container item direction="row" justifyContent="flex-end" spacing={1} sx={{ pr: 3 }}>
                <Grid item>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            captureWebEvent(`cancel-change-${props.addressToEdit.type}-address`);
                            closeDialog();
                        }}
                    >
                        {t('dialogs:CANCEL')}
                    </Button>
                </Grid>
                <Grid item>
                    {wrapButtonWithTooltip(
                        <Button
                            className={classes.saveButton}
                            type="submit"
                            onClick={() => {
                                captureWebEvent(`save-${props.addressToEdit.type}-address-button`);
                                handleSave();
                            }}
                            disabled={isSaveButtonDisabled}
                        >
                            {t('dialogs:SAVE')}
                        </Button>,
                        isSaveButtonDisabled ? t('membership:fillOutRequiredFields') : undefined,
                    )}
                </Grid>
            </Grid>
        );
    };

    const showSaveLeavePrevention = () => (
        <ConfirmationDialog
            calledFrom={'change-address-dialog'}
            title={t('dialogs:saveChanges')}
            content={t('dialogs:savePreventionDialogContentLong')}
            defaultButtonText={t('dialogs:backToEdit')}
            buttonText2={t('dialogs:discard')}
            isOpen={isSaveLeavePreventionOpen}
            onClose={() => setSaveLeavePreventionOpen(false)}
            buttonText1={t('dialogs:SAVE')}
            buttonAction1={closeDialog}
            isButtonAction1Disabled={true}
            buttonAction2={closeDialog}
        />
    );

    return (
        <Dialog open={props.isOpen} onClose={closeDialog} TransitionComponent={Transition} maxWidth="sm" fullWidth>
            {showSaveLeavePrevention()}
            <DialogTitle>{showTitleAndCloseIcon()}</DialogTitle>
            <DialogContent>{showPromptAndInputFields()}</DialogContent>
            <DialogActions>{showButtons()}</DialogActions>
        </Dialog>
    );
};
