import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { Theme } from '@mui/material';
import { ComponentBase } from 'resub';
import Typography from '@mui/material/Typography';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { classNames } from 'util/style-helpers';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = (theme: Theme) =>
    createStyles({
        ellipsisOverflow: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        popover: {
            textAlign: 'center',
            padding: 5,
            cursor: 'pointer',
        },
        infoButton: {
            marginRight: theme.spacing(1),
            minWidth: 10,
        },
        icon: {
            margin: theme.spacing(0.5),
        },
        iconSmall: {
            width: 15,
            height: 15,
        },
        slimButton: {
            padding: 0,
            margin: 0,
            textTransform: 'none',
            justifyContent: 'left',
        },
        block: {
            display: 'flex',
            alignItems: 'center',
        },
        link: {
            color: theme.palette.primary.dark,
        },
        noAction: {
            cursor: 'default',
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.common.black,
            },
        },
    });

interface EmailComponentProps extends WithStyles<typeof _styles> {
    email: string;
    popover?: boolean;
    calledFrom: string;
}

interface EmailComponentState {
    anchorEl: HTMLElement | null;
    mailOpen: boolean;
}

class EmailComponent extends ComponentBase<EmailComponentProps, EmailComponentState> {
    protected _buildState(): Partial<EmailComponentState> | undefined {
        return undefined;
    }

    mailTo(): void {
        if (this.props.email && this.props.email !== '') {
            window.open('mailto:' + this.props.email);
        }
    }

    render(): React.ReactElement {
        if (this.props.popover) {
            return (
                <>
                    <Popover
                        id={'email'}
                        anchorEl={this.state.anchorEl}
                        open={this.state.mailOpen || false}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        onClick={() => captureWebEvent('company-detail-mail-address')}
                        onClose={(): void => this.setState({ mailOpen: false, anchorEl: null })}
                    >
                        <Typography className={this.props.classes.popover} onClick={(): void => this.mailTo()}>
                            {this.props.email}
                        </Typography>
                    </Popover>
                    <IconButton
                        data-posthog="mail-open-button"
                        className={this.props.classes.infoButton}
                        onClick={(event) => {
                            captureWebEvent(`company-detail-mail-button-from-${this.props.calledFrom}`);
                            this.setState({
                                mailOpen: true,
                                anchorEl: event.currentTarget,
                            });
                        }}
                        size={'small'}
                    >
                        <MailOutlineIcon className={this.props.classes.icon} />
                    </IconButton>
                </>
            );
        } else {
            return (
                <>
                    <div className={this.props.classes.block}>
                        <IconButton
                            disableRipple
                            className={classNames(this.props.classes.infoButton, this.props.classes.noAction)}
                            size={'small'}
                        >
                            <MailOutlineIcon
                                className={classNames(this.props.classes.icon, this.props.classes.iconSmall)}
                            />
                        </IconButton>
                        <a
                            className={classNames(this.props.classes.link, this.props.classes.ellipsisOverflow)}
                            href={'mailto:' + this.props.email}
                            onClick={() => captureWebEvent('contact-person-email-link')}
                        >
                            <Typography className={this.props.classes.ellipsisOverflow}>{this.props.email}</Typography>
                        </a>
                    </div>
                </>
            );
        }
    }
}

export default withStyles(_styles, { withTheme: true })(EmailComponent);
