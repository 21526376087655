import AddIcon from '@mui/icons-material/Add';
import { Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Dashlet from 'components/dashboard/Dashlet';
import OfferDashletList from 'components/dashboard/marketplace/offer/OfferDashletList';
import { Company } from 'model/Company';
import { DefaultDashletProperties } from 'model/DashletProperties';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { ComponentBase } from 'resub';
import { CompanyStore } from 'store';
import { theme } from 'style/NearbuyStyle';
import Button from '@mui/material/Button';
import { captureWebEvent } from 'util/AnalyticUtils';
import OfferDialog from 'components/marketplace/offers/dialog/OfferDialog';

const _styles = (theme: Theme) =>
    createStyles({
        addButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
            '&:disabled': {
                backgroundColor: theme.palette.action.disabledBackground,
            },
            right: theme.spacing(2),
            bottom: theme.spacing(4),
            padding: 10,
            position: 'absolute',
        },
    });

type MyOffersDashletProps = WithStyles<typeof _styles> & DefaultDashletProperties & WithTranslation;

interface MyOffersDashletState {
    dialogOpen: boolean;
    company?: Company;
}

class MyOffersDashlet extends ComponentBase<MyOffersDashletProps, MyOffersDashletState> {
    protected _buildState(
        props: MyOffersDashletProps,
        initialBuild: boolean,
        incomingState?: Readonly<MyOffersDashletState>,
    ): Partial<MyOffersDashletState> | undefined {
        const newState: Partial<MyOffersDashletState> = {};
        if (initialBuild) {
            newState.dialogOpen = false;
        }
        if (!newState.company) {
            newState.company = CompanyStore.getSelected();
        }
        return newState;
    }

    render(): React.ReactElement | null {
        if (!this.state.company) return null;
        return (
            <>
                {this.state.dialogOpen ? (
                    <OfferDialog
                        isOpen
                        onClose={(): void => this.setState({ dialogOpen: false })}
                        companyRef={this.state.company.links.self}
                    />
                ) : null}
                <Dashlet {...this.props.dashletProperties}>
                    <OfferDashletList companyRef={this.state.company.links.self} />
                    <Button
                        size={'medium'}
                        variant={'text'}
                        component={RouterLink}
                        onClick={() => captureWebEvent('offer-dashlet-fullView-button')}
                        to={`/marketplace/${this.state.company.id}/offers`}
                        sx={{
                            fontWeight: 600,
                            padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
                            borderRadius: '8px',
                            color: theme.palette.primary.dark,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.lighter,
                                color: theme.palette.primary.dark,
                            },
                        }}
                    >
                        {this.props.t('common:linkToFullView')}
                    </Button>
                    <IconButton
                        id="add-offer-dialog-open-button"
                        className={this.props.classes.addButton}
                        onClick={() => {
                            captureWebEvent('offer-dashlet-add-button');
                            this.setState({ dialogOpen: true });
                        }}
                        size="large"
                    >
                        <AddIcon />
                    </IconButton>
                </Dashlet>
            </>
        );
    }
}

export default withTranslation(['common'])(withStyles(_styles, { withTheme: true })(MyOffersDashlet));
