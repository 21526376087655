import { Button, Card, CardContent, CardHeader, Grid, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ChangeAddressDialog } from 'components/order/ChangeAddressDialog';
import OrderAddresses from 'components/order/OrderAddresses';
import { OrderAddress, OrderAddressType } from 'model/Address';
import { Person } from 'model/Person';
import React, { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ComponentBase } from 'resub';
import { CompanyStore, PersonStore, RecurringOrderStore } from 'store';
import { delay } from 'util/helpers';
import UpdateManager from 'components/shared/InstantSave/FormUpdateManager';
import SaveLeavePreventionPrompt from 'components/shared/SaveLeavePreventionPrompt';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Chip from '@mui/material/Chip';
import moment, { Moment } from 'moment';
import OfferDetailViewDialog from 'components/marketplace/offers/OfferDetailViewDialog';
import RecurringOrderService from 'api/RecurringOrderService';
import { Company } from 'model/Company';
import { RecurringOrder, RecurringOrderStatus } from 'model/RecurringOrder';
import { Amount } from 'model/Amount';
import { Product } from 'model/Product';
import { LevelOfProcessing } from 'model/LevelOfProcessing';

const styles = (theme: Theme) =>
    createStyles({
        title: {
            color: theme.palette.primary.dark,
            lineHeight: '25px',
            fontSize: '20px',
            fontWeight: 'bold',
        },
        card: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            marginBottom: theme.spacing(2),
            height: '100%',
        },
        paymentContainer: {
            paddingLeft: '0',
            paddingTop: '0',
        },
        smallTypography: {
            fontSize: 'small',
            fontWeight: 'bold',
            paddingBottom: theme.spacing(1),
        },
        dot: {
            maxWidth: '22px',
            maxHeight: '22px',
            color: theme.palette.secondary.dark,
        },
        chip: {
            margin: theme.spacing(0.5),
            backgroundColor: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.main}`,
            '&:hover': {
                color: theme.palette.common.black,
                backgroundColor: theme.palette.primary.light,
            },
        },
    });

interface RecurringOrderBuyingDialogProps extends WithStyles<typeof styles>, RouteComponentProps, WithTranslation {
    open: boolean;
    onClose: () => void;
    recurringOrderRef: string;
    buyingDialogData: RecurringOrderBuyingDialogData;
}

interface RecurringOrderBuyingDialogData {
    offerRef: string | undefined;
    amount: Amount;
    firstDeliveryDate: Moment;
    lastDeliveryDate: Moment;
    deliveryInterval: number;
    deliveryFreq: string;
    totalPrice: number;
    pricePerUnit: number;
    product?: Product;
    levelsOfProcessing: LevelOfProcessing[] | undefined;
}

interface RecurringOrderBuyingDialogState {
    recurringOrder: RecurringOrder;
    userCompany: Company;
    userPerson: Person;
    invoiceAddress: OrderAddress;
    deliveryAddress: OrderAddress;
    isChangeAddressDialogOpen: boolean;
    isLeavePrevented: boolean;
    addressToEdit: OrderAddress;
    isDialogOpen: boolean;
    isErrorMessageVisible: boolean;
    isDisabled: boolean;
    sellingPerson: Person;
    isOfferDetailViewDialogOpen: boolean;
}

class RecurringOrderBuyingDialog extends ComponentBase<
    RecurringOrderBuyingDialogProps,
    RecurringOrderBuyingDialogState
> {
    protected _buildState(
        props: RecurringOrderBuyingDialogProps,
        initialBuild: boolean,
        incomingState: Readonly<RecurringOrderBuyingDialogState> | undefined,
    ): Partial<RecurringOrderBuyingDialogState> | undefined {
        const userCompany = CompanyStore.getSelected();
        const user = PersonStore.getSelected();
        if (!userCompany || !user || !props.recurringOrderRef) return;

        const newState: Partial<RecurringOrderBuyingDialogState> = { ...incomingState };

        if (!props.recurringOrderRef) return;

        newState.recurringOrder = RecurringOrderStore.getOne(props.recurringOrderRef);
        newState.invoiceAddress = newState.recurringOrder?.addresses.filter(
            (it) => it.type == OrderAddressType.INVOICE,
        )[0];
        newState.deliveryAddress = newState.recurringOrder?.addresses.filter(
            (it) => it.type == OrderAddressType.DELIVERY,
        )[0];

        if (initialBuild) {
            newState.isErrorMessageVisible = false;
            newState.isLeavePrevented = true;
            newState.isChangeAddressDialogOpen = false;
            newState.addressToEdit = undefined;
            newState.isOfferDetailViewDialogOpen = false;
        }
        return newState;
    }

    checkStatus() {
        if (!(this.state.recurringOrder.status == RecurringOrderStatus.ACCEPTED)) {
            this.setState({
                isErrorMessageVisible: true,
                isDisabled: true,
            });
        }
    }

    showPurchaseDetails() {
        return (
            <Grid item container>
                <Card className={this.props.classes.card}>
                    <CardHeader
                        title={
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box display="flex" alignItems="center">
                                    <FiberManualRecordIcon className={this.props.classes.dot} />
                                    <Typography fontWeight="bold" sx={{ marginLeft: 1 }}>
                                        {this.props.t(`ontofood:${this.props.buyingDialogData.product?.label}`)}
                                    </Typography>
                                </Box>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ isOfferDetailViewDialogOpen: true });
                                    }}
                                >
                                    {this.props.t('recurringOrderBuyingDialog:showOffer').toUpperCase()}
                                </Button>
                            </Box>
                        }
                    />
                    <CardContent className={this.props.classes.paymentContainer}>
                        <Grid item sx={{ pl: 1.5, mt: -3 }}>
                            {this.showLevelsOfProcessing()}
                            {this.deliveryDetails()}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    changeAddress = (address: OrderAddress) => {
        if (address.type === OrderAddressType.DELIVERY) {
            RecurringOrderService.updateRecurringOrderAddress(this.props.recurringOrderRef, address).subscribe(() =>
                this.setState({
                    deliveryAddress: address,
                }),
            );
        } else if (address.type === OrderAddressType.INVOICE) {
            RecurringOrderService.updateRecurringOrderAddress(this.props.recurringOrderRef, address).subscribe(() =>
                this.setState({
                    invoiceAddress: address,
                }),
            );
        }
    };

    editClick = (address: OrderAddress) => {
        this.setState({
            addressToEdit: address,
            isChangeAddressDialogOpen: true,
        });
    };

    showChangeAddressDialog(): React.ReactFragment | undefined {
        if (!this.state.isChangeAddressDialogOpen) return;
        return (
            <ChangeAddressDialog
                isOpen={this.state.isChangeAddressDialogOpen}
                onClose={async () => {
                    await delay(200);
                    this.setState({
                        isChangeAddressDialogOpen: false,
                    });
                }}
                changeAddress={this.changeAddress.bind(this)}
                addressToEdit={this.state.addressToEdit}
            />
        );
    }

    showAddresses(): React.ReactFragment | undefined {
        if (!this.state.invoiceAddress || !this.state.deliveryAddress) {
            return;
        }
        return (
            <Grid item>
                <Card className={this.props.classes.card}>
                    <CardContent>
                        <OrderAddresses
                            deliveryAddress={this.state.deliveryAddress}
                            invoiceAddress={this.state.invoiceAddress}
                            direction={'row'}
                            onEditClick={this.editClick.bind(this)}
                        />
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    showError(): React.ReactFragment | undefined {
        if (!this.state.sellingPerson) return;
        return (
            <Grid item>
                <Typography
                    color={'red'}
                    className={this.props.classes.smallTypography}
                    hidden={!this.state.isErrorMessageVisible}
                >
                    {this.props.t('order:canceledErrorMessage', {
                        firstNameSeller: this.state.sellingPerson.firstname,
                        lastNameSeller: this.state.sellingPerson.lastname,
                    })}
                </Typography>
            </Grid>
        );
    }

    updateRecurringOrderStatus(): void {
        RecurringOrderService.updateRecurringOrderStatus(
            this.props.recurringOrderRef,
            RecurringOrderStatus.ORDERED,
        ).subscribe(() => this.props.onClose());
    }

    showButtons(): React.ReactFragment {
        return (
            <Grid container direction="row" spacing={2} justifyContent="flex-end">
                <Grid item>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            this.props.onClose();
                        }}
                    >
                        {this.props.t('recurringOrderBuyingDialog:cancelAndReturn').toUpperCase()}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        onClick={() => {
                            this.setState({ isLeavePrevented: false }, () => {
                                this.checkStatus();
                            });
                            !this.state.isErrorMessageVisible ? this.updateRecurringOrderStatus() : undefined;
                        }}
                        disabled={this.state.isDisabled}
                    >
                        {this.props.t('recurringOrderBuyingDialog:bindingPurchase').toUpperCase()}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    showPaymentDetails(): React.ReactFragment {
        return (
            <Grid item>
                <Card className={this.props.classes.card}>
                    <CardHeader
                        title={
                            <Typography className={this.props.classes.title}>
                                {this.props.t('payment:paymentDetails')}
                            </Typography>
                        }
                    />
                    <CardContent>
                        <Typography fontWeight={'bold'}>{this.props.t('payment:paymentOnAccount')}</Typography>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    showLevelsOfProcessing(): ReactNode {
        const levelsOfProcessing = this.props.buyingDialogData.levelsOfProcessing;
        return levelsOfProcessing ? (
            <Grid item md={12} sx={{ paddingTop: '10px' }}>
                {levelsOfProcessing
                    ? levelsOfProcessing.map((lop) => {
                          return (
                              <Chip
                                  key={lop.links.self}
                                  label={this.props.t('levelsOfProcessing:' + lop.label)}
                                  color="primary"
                                  className={this.props.classes.chip}
                              />
                          );
                      })
                    : null}
            </Grid>
        ) : null;
    }

    showSaveLeavePrevention(): React.ReactFragment {
        return (
            <SaveLeavePreventionPrompt
                prevent={this.state.isLeavePrevented}
                title={this.props.t('payment:leaveOrderPreventionDialogHeading')}
                description={this.props.t('payment:leaveOrderPreventionDialogContent')}
                buttons={[
                    {
                        text: this.props.t('payment:discard'),
                        action: () => UpdateManager.clearUpdates(),
                    },
                    {
                        text: this.props.t('payment:backToOrder'),
                        variant: 'contained',
                    },
                ]}
            />
        );
    }

    deliveryDetails(): ReactNode {
        const {
            amount,
            firstDeliveryDate,
            lastDeliveryDate,
            deliveryInterval,
            deliveryFreq,
            totalPrice,
            pricePerUnit,
        } = this.props.buyingDialogData;

        return (
            <Grid container spacing={1} sx={{ mt: 1, pl: 1 }}>
                <Grid container item xs={12} direction="row" alignItems="center">
                    <Grid item xs={3}>
                        <Typography>{this.props.t('recurringOrder:firstDelivery')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography fontWeight="bold">{moment(firstDeliveryDate).format('DD.MM.YYYY')}</Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={7} direction="row" alignItems="center">
                    <Grid item xs={5.2}>
                        <Typography>{this.props.t('recurringOrder:lastDelivery')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography fontWeight="bold">
                            {lastDeliveryDate ? moment(lastDeliveryDate).format('DD.MM.YYYY') : '-'}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={4} direction="row" alignItems="center" spacing={3.7}>
                    <Grid item>
                        <Typography>{this.props.t('offer:deliveryCycle')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography fontWeight="bold">
                            {this.props.t('recurringOrder:deliveryPrefix')} {deliveryInterval}{' '}
                            {this.props.t(deliveryFreq)}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} direction="row" alignItems="center">
                    <Grid item xs={3}>
                        <Typography>{this.props.t('recurringOrder:amountPerDelivery')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography fontWeight="bold">
                            {amount.amount.toLocaleString('de-DE', { maximumFractionDigits: 2 }) +
                                ' ' +
                                this.props.t(`shortunits:${amount.unit}`)}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={7} direction="row" alignItems="center" spacing={0.5}>
                    <Grid item xs={5.15}>
                        <Typography>{this.props.t('recurringOrder:pricePerDelivery')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography fontWeight="bold">
                            {totalPrice.toLocaleString('de-DE', {
                                style: 'currency',
                                currency: 'EUR',
                            })}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={4} direction="row" alignItems="center" spacing={3.7}>
                    <Grid item>
                        <Typography>
                            {this.props.t('recurringOrder:pricePer') + ' ' + this.props.t(`shortunits:${amount.unit}`)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography fontWeight="bold">
                            {pricePerUnit.toLocaleString('de-DE', {
                                style: 'currency',
                                currency: 'EUR',
                            })}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    render(): React.ReactElement | null {
        return (
            <Grid container item>
                <Dialog
                    open={this.props.open}
                    onClose={() => {
                        this.props.onClose();
                    }}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle>{this.props.t('recurringOrderBuyingDialog:buy')}</DialogTitle>
                    <DialogContent>
                        {this.showChangeAddressDialog()}
                        {this.showSaveLeavePrevention()}

                        <Grid container item direction={'column'} xs={12}>
                            {this.showPurchaseDetails()}
                            {this.showPaymentDetails()}
                            {this.showAddresses()}
                            {this.showError()}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid item>{this.showButtons()}</Grid>
                    </DialogActions>
                </Dialog>
                {this.state.isOfferDetailViewDialogOpen ? (
                    <OfferDetailViewDialog
                        offerRef={this.props.buyingDialogData.offerRef!}
                        isOpen={this.state.isOfferDetailViewDialogOpen}
                        onClose={() => {
                            this.setState({ isOfferDetailViewDialogOpen: false });
                        }}
                    />
                ) : null}
            </Grid>
        );
    }
}

export default withTranslation(['ontofood', 'levelsOfProcessing', 'payment', 'order', 'recurringOrderBuyingDialog'])(
    withStyles(styles, { withTheme: true })(withRouter(RecurringOrderBuyingDialog)),
);
