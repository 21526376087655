import { Amount } from 'model/Amount';
import { RequestDialogProperties, RequestDialogState } from 'components/marketplace/requests/dialog/RequestDialog';
import { FormValidationHelper } from 'util/FormErrors';
import moment from 'moment/moment';

export class RequestDialogHelpers {
    constructor(private state: RequestDialogState) {}

    getTotalAmount(): Amount | undefined {
        return this.state.requestWriteView?.totalAmount;
    }

    static validate(
        formHelper: FormValidationHelper,
        prevState: RequestDialogState,
        props: RequestDialogProperties,
    ): void {
        if (!prevState) return;
        if (prevState.requestWriteView) {
            if (!prevState.requestWriteView.totalAmount.amount || prevState.requestWriteView.totalAmount.amount <= 0) {
                formHelper.setError('totalAmount', props.t('offer:errorInvalidAmount'));
            }
            if (!prevState.requestWriteView.dateFrom) {
                formHelper.setError('noFromDate', props.t('offer:errorNoDate'));
            }
            if (!prevState.requestWriteView.dateEnd) {
                formHelper.setError('noUntilDate', props.t('offer:errorNoDate'));
            }

            if (
                prevState.requestWriteView.active &&
                prevState.requestWriteView.dateEnd &&
                moment(prevState.requestWriteView.dateEnd).isBefore(moment().startOf('day'))
            ) {
                formHelper.setError('noUntilDate', props.t('offer:errorNoDate'));
            }
            if (
                prevState.requestWriteView.dateFrom &&
                prevState.requestWriteView.dateEnd &&
                moment(prevState.requestWriteView.dateEnd).isBefore(moment(prevState.requestWriteView.dateFrom))
            ) {
                formHelper.setError('noUntilDate', props.t('offer:errorUntilBeforeFrom'));
            }
        }
    }
}
