import * as React from 'react';
import { Badge, Divider, Popover } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ComponentBase } from 'resub';
import { withTranslation, WithTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import { SystemMessage, SystemMessageDisplayType } from 'model/SystemMessage';
import { CompanyStore, PersonEmploymentStore, SystemMessageSeenStore, SystemMessageStore } from 'store';
import { getSystemMessage } from 'components/systemmessage/SystemMessageFactory';
import IconButton from '@mui/material/IconButton';
import { SettingsRounded } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { ExtendedTheme } from 'style/NearbuyStyle';
import { LoadingIndicator } from 'components/shared/LoadingIndicator';
import { captureWebEvent } from 'util/AnalyticUtils';

const styles = (theme: ExtendedTheme) =>
    createStyles({
        contentTop: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '25px',
        },
        systemMessageHeadline: {
            fontSize: '20px',
            fontWeight: 'bold',
        },
        roundedBtn: {
            backgroundColor: theme.palette.primary.main,
            height: 'fit-content',
        },
        buttonPadding: {
            padding: 0,
            margin: theme.spacing(0.5),
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        rightSideButtonColor: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.primary,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.dark,
            },
        },
        scrollable: {
            overflow: 'auto',
        },
        typography: {
            fontWeight: 'bold',
        },
        list: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        divider: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        noSystemMessages: {
            marginTop: '40%',
            textAlign: 'center',
        },
    });

interface SystemMessageComponentProps extends WithStyles<typeof styles>, WithTranslation {}

interface SystemMessageComponentState {
    isOpen: boolean;
    systemMessages?: readonly SystemMessage[];
    unreadSystemMessagesCount?: number;
}

class SystemMessageComponent extends ComponentBase<SystemMessageComponentProps, SystemMessageComponentState> {
    protected _buildState(
        props: SystemMessageComponentProps,
        initialBuild: boolean,
        incomingState: Readonly<SystemMessageComponentState>,
    ): Partial<SystemMessageComponentState> | undefined {
        const employments = PersonEmploymentStore.getSelected();
        const company = CompanyStore.getSelected();
        const employment = employments?.employments.find(
            (employment) => employment.links.company == company?.links.self,
        );
        if (employment && employments && company) {
            const systemMessages = SystemMessageStore.search({}).filter(
                (message) => message.systemMessageDisplayType === SystemMessageDisplayType.STANDARD,
            );
            const seenSystemMessageRefs = SystemMessageSeenStore.getOne(employment.links.self)?.seenMessages.map(
                (message) => message.systemMessageRef,
            );
            let unreadSystemMessagesCount = undefined;
            if (seenSystemMessageRefs) {
                unreadSystemMessagesCount = systemMessages.filter(
                    (message) => !seenSystemMessageRefs.find((ref) => message.links.self == ref),
                ).length;
            }
            return {
                systemMessages,
                unreadSystemMessagesCount,
            };
        }
    }

    private buttonRef?: HTMLElement | null;

    render(): React.ReactElement {
        return (
            <>
                <IconButton
                    ref={(ref) => {
                        this.buttonRef = ref;
                    }}
                    data-testid={'system-message'}
                    className={this.props.classes.buttonPadding}
                    onClick={() => {
                        this.state.unreadSystemMessagesCount
                            ? captureWebEvent('toolbar-notification-button-with-unread-notification')
                            : captureWebEvent('toolbar-notification-button');
                        this.setState({
                            isOpen: true,
                        });
                    }}
                >
                    <Badge
                        data-testid={'badge'}
                        color="error"
                        badgeContent={
                            this.state.unreadSystemMessagesCount && this.state.unreadSystemMessagesCount > 0 ? (
                                <div data-testid={'badge-content'}>{this.state.unreadSystemMessagesCount}</div>
                            ) : undefined
                        }
                        overlap="circular"
                    >
                        <Avatar className={this.props.classes.rightSideButtonColor}>
                            <NotificationsIcon />
                        </Avatar>
                    </Badge>
                </IconButton>
                <Popover
                    id={'systemMessage'}
                    open={this.state.isOpen || false}
                    anchorEl={this.buttonRef}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={(): void => {
                        captureWebEvent('message-overlay-closed');
                        this.setState({
                            isOpen: false,
                        });
                    }}
                    PaperProps={{
                        style: { width: '450px', height: '500px' },
                    }}
                >
                    <div className={this.props.classes.contentTop}>
                        <Typography className={this.props.classes.systemMessageHeadline}>
                            {this.props.t('dialogs:systemMessageHeadline')}
                        </Typography>
                        <Link
                            component={RouterLink}
                            to={'/profile/system-messages'}
                            onClick={() =>
                                this.setState({
                                    isOpen: false,
                                })
                            }
                        >
                            <IconButton className={this.props.classes.roundedBtn}>
                                <SettingsRounded />
                            </IconButton>
                        </Link>
                    </div>
                    <Divider />
                    <React.Suspense
                        fallback={
                            <div className={this.props.classes.noSystemMessages}>
                                <LoadingIndicator />
                            </div>
                        }
                    >
                        {this.state.systemMessages?.length == 0 && (
                            <Typography className={this.props.classes.noSystemMessages}>
                                {this.props.t('dialogs:noSystemMessages')}
                            </Typography>
                        )}
                        <Grid item className={this.props.classes.scrollable}>
                            <List className={this.props.classes.list}>
                                {this.state.systemMessages
                                    ?.map((systemMessage) => {
                                        const systemMessageComponent = getSystemMessage(systemMessage);
                                        if (systemMessageComponent) {
                                            return (
                                                <React.Fragment key={systemMessage.links.self}>
                                                    {systemMessageComponent}
                                                    <Divider className={this.props.classes.divider} component="li" />
                                                </React.Fragment>
                                            );
                                        }
                                        return null;
                                    })
                                    .filter((element) => element !== null)}
                            </List>
                        </Grid>
                    </React.Suspense>
                </Popover>
            </>
        );
    }
}

export default withTranslation('dialogs')(withStyles(styles, { withTheme: true })(SystemMessageComponent));
