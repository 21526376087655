import { IconButton, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { WithStyles, WithTheme } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import UnitSelectComponent from 'components/marketplace/shared/UnitSelectComponent';
import { DeleteIcon } from 'components/shared/Icons';
import { ContainerType, ContainerUsageType, ContainerView, ContainerWriteView } from 'model/ContainerView';
import { asUnitString, getUnitFromString, Unit } from 'model/Unit';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { ComponentBase } from 'resub';
import { ContainerStore, MetaStore } from 'store';
import { FormValidationHelper, withFormValidationHelper, WithFormValidationHelper } from 'util/FormErrors';
import { MetaStaticEntityType } from 'model/Meta';

const styles = (theme: Theme) =>
    createStyles({
        selectInput: {
            width: '100%',
            flexGrow: 1,
        },
        formControl: {
            display: 'flex',
            flexGrow: 1,
            marginBottom: theme.spacing(2),
        },
        textField: {
            maxWidth: 120,
        },
        deleteButton: {
            padding: theme.spacing(0.65),
            margin: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(0.5)}`,
        },
        errorTypography: {
            color: theme.palette.error.main,
            fontSize: 13,
            fontWeight: '500',
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        errorField: {
            width: '100%',
            flexGrow: 1,
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: theme.palette.error.main,
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.error.main,
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.error.main,
                },
            },
        },
    });

interface ContainerProperties extends WithStyles<typeof styles>, WithTheme, WithTranslation {
    offerUnit?: Unit | undefined;
    pdsUnit?: Unit | undefined;
    container?: ContainerWriteView | undefined;
    onUpdate: (container?: ContainerWriteView, id?: string) => void;
    remove?: (containerView: ContainerWriteView) => void;
    containerId?: string;
    isDuplicate: boolean;
    containerUsageType?: ContainerUsageType;
    isReadOnly?: boolean;
    showInvalidErrors?: boolean;
}

interface ContainerState extends WithFormValidationHelper {
    containerWriteView: ContainerWriteView;
    key: string;
    containerTypes: ContainerType[];
    selectedContainerType: string;
    containerView?: ContainerView;
    unitFromWriteView: Unit | undefined;
}

export class ContainerComponent extends ComponentBase<ContainerProperties, ContainerState> {
    @withFormValidationHelper<ContainerComponent>('validateContainer')
    protected _buildState(
        props: ContainerProperties,
        initialBuild: boolean,
        incomingState: ContainerState | undefined,
    ): Partial<ContainerState> | undefined {
        const newState: Partial<ContainerState> = {
            ...incomingState,
            containerWriteView: props.container,
            containerView: ContainerStore.getSelected(),
        };

        const metaContainerTypes = MetaStore.getOne(MetaStaticEntityType.CONTAINER_TYPE);
        if (metaContainerTypes) {
            newState.containerTypes = ((metaContainerTypes?.data ?? []) as ContainerType[]).sort((a, b) =>
                this.props.t('containertype:' + a.slug).localeCompare(this.props.t('containertype:' + b.slug)),
            );
        }

        const unitStringFromWriteView = asUnitString(incomingState?.containerWriteView.unit ?? '');
        newState.unitFromWriteView = unitStringFromWriteView ? getUnitFromString(unitStringFromWriteView) : undefined;
        return newState;
    }

    validateContainer(formHelper: FormValidationHelper, prevState: ContainerState, props: ContainerProperties): void {
        if (prevState) {
            if (!prevState.containerWriteView.amount || prevState.containerWriteView.amount <= 0) {
                formHelper.setError(
                    'minAmount',
                    props.containerUsageType == ContainerUsageType.PRODUCT_DATA_SHEET
                        ? props.t('error:noContainerSaveWithoutValue')
                        : props.t('offer:errorInvalidAmount'),
                );
            }
        }
    }

    componentDidMount(): void {
        super.componentDidMount();
        if (this.props.container) {
            this.setState({
                // pseudo uuid
                key: Math.random().toFixed(length),
            });
        } else {
            this.setState({
                // pseudo uuid
                key: Math.random().toFixed(length),
            });
        }
    }

    updateContainer(): void {
        this.props.onUpdate(this.state.containerWriteView, this.props.containerId ?? undefined);
    }

    handleContainerTypeChange(selectedContainerType: string): void {
        this.setState(
            (state: ContainerState) => {
                const container = state.containerWriteView;
                container.containerType = selectedContainerType;
                return { containerWriteView: container };
            },
            () => this.updateContainer(),
        );
    }

    removeButton(): React.ReactElement | undefined {
        if (!this.props.remove) return;
        return (
            <IconButton
                className={this.props.classes.deleteButton}
                onClick={() => (this.props.remove ? this.props.remove(this.state.containerWriteView) : null)}
                size="large"
                disabled={this.props.isReadOnly ?? false}
            >
                <DeleteIcon />
            </IconButton>
        );
    }

    handleContainerUnitChange(unit: Unit) {
        this.setState(
            (state: ContainerState) => {
                const container = state.containerWriteView;
                container.unit = unit.unit;
                return { containerWriteView: container };
            },
            () => this.updateContainer(),
        );
    }

    render(): React.ReactElement {
        const isContainerUsageTypePDS = this.props.containerUsageType === ContainerUsageType.PRODUCT_DATA_SHEET;
        const isContainerUsageTypeOffer = this.props.containerUsageType === ContainerUsageType.OFFER;
        return (
            <Grid container item md={12} spacing={1.5}>
                <Grid item md={isContainerUsageTypePDS || isContainerUsageTypeOffer ? 3.75 : 4}>
                    <TextField
                        label={this.props.t(
                            isContainerUsageTypePDS ? 'productDataSheet:container' : 'offer:containerType',
                        )}
                        select
                        className={
                            this.props.isDuplicate ? this.props.classes.errorField : this.props.classes.selectInput
                        }
                        value={this.state.containerWriteView.containerType}
                        onChange={(selectedOption) =>
                            this.handleContainerTypeChange(selectedOption.target.value as string)
                        }
                        disabled={this.props.isReadOnly ?? false}
                        error={this.props.showInvalidErrors && !this.state.containerWriteView.containerType}
                    >
                        {this.state.containerTypes &&
                            this.state.containerTypes.map((ct: ContainerType) => {
                                return (
                                    <MenuItem key={ct.id} value={ct.id}>
                                        {this.props.t('containertype:' + ct.slug)}
                                    </MenuItem>
                                );
                            })}
                    </TextField>
                </Grid>
                <Grid item md={isContainerUsageTypePDS || isContainerUsageTypeOffer ? 3.75 : 2}>
                    <NumberFormat
                        label={
                            this.props.containerUsageType == ContainerUsageType.PRODUCT_DATA_SHEET
                                ? this.props.t('productDataSheet:amountPerContainer')
                                : this.props.t('common:size')
                        }
                        className={
                            this.props.isDuplicate ? this.props.classes.errorField : this.props.classes.selectInput
                        }
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={2}
                        customInput={TextField}
                        value={this.state.containerWriteView.amount ? this.state.containerWriteView.amount : undefined}
                        onValueChange={(event) => {
                            this.setState(
                                (state: ContainerState) => {
                                    const container = state.containerWriteView;
                                    container.amount = Number(event.value);
                                    return { containerWriteView: container };
                                },
                                () => this.updateContainer(),
                            );
                        }}
                        required={true}
                        inputProps={{
                            min: 0,
                        }}
                        disabled={this.props.isReadOnly ?? false}
                        helperText={
                            !this.props.showInvalidErrors &&
                            this.state.formValidationHelper.getFormError('minAmount')?.helperText
                        }
                        error={
                            this.props.showInvalidErrors
                                ? !this.state.containerWriteView.amount
                                : this.state.formValidationHelper.getFormError('minAmount')?.error
                        }
                    />
                </Grid>
                <Grid item md={isContainerUsageTypePDS || isContainerUsageTypeOffer ? 3.75 : 3}>
                    <UnitSelectComponent
                        selectedUnit={this.state.unitFromWriteView ?? this.props.offerUnit}
                        onChange={(selected) => this.handleContainerUnitChange(selected)}
                        typeToLimitTo={this.props.offerUnit?.unitType ?? this.props.pdsUnit?.unitType}
                        isRequired={true}
                        isReadOnly={this.props.isReadOnly ?? false}
                        error={this.props.showInvalidErrors && !this.state.containerWriteView.unit}
                    />
                </Grid>
                <Grid item md={0.75}>
                    {this.removeButton()}
                </Grid>
                {this.props.isDuplicate ? (
                    <Typography className={this.props.classes.errorTypography}>
                        {this.props.t('offer:errorDuplicateContainer')}
                    </Typography>
                ) : null}
            </Grid>
        );
    }
}

export default withTranslation(['common', 'offer', 'containertype', 'error'])(
    withStyles(styles, { withTheme: true })(ContainerComponent),
);
