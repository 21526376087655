import http from 'api/http';
import { map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { Invoice } from 'model/Invoice';
import { Order } from 'model/Order';
import { DialogStore, MessageStore, MessagesUnseenStore } from 'store';
import { getDialogId } from 'model/Dialog';
import { captureWebEvent } from 'util/AnalyticUtils';

class InvoiceService {
    public getInvoice(invoiceRef: string): Observable<Invoice> {
        return http
            .getAxios()
            .get<Invoice>(`${invoiceRef}`)
            .pipe(map((response) => response.data));
    }

    uploadInvoice(orderRef: string, file: Blob, filename: string): Observable<Order> {
        captureWebEvent('upload-invoice-file');
        const formData = new FormData();
        formData.append('file', file, filename);
        formData.append('filename', filename);

        return reloadMessages(
            http
                .getAxios()
                .post<Order>(`${orderRef}/invoice`, formData)
                .pipe(map((response) => response.data)),
        );
    }
}

function reloadMessages(observable: Observable<Order>) {
    return observable.pipe(
        switchMap((order: Order) => forkJoin([of(order)])),
        tap(([order]) => {
            const senderCompany = order.links.sellingCompany;
            const senderPerson = order.links.sellingPerson;
            const receiverCompany = order.links.buyingCompany;
            const receiverPerson = order.links.buyingPerson;
            if (receiverCompany && senderCompany && senderPerson && receiverPerson) {
                MessagesUnseenStore.loadOne(receiverCompany);
                MessageStore.invalidateSearch({
                    otherPersonRef: senderPerson,
                    otherCompanyRef: senderCompany,
                });
                DialogStore.loadOne(
                    getDialogId({
                        otherPersonRef: senderPerson,
                        otherCompanyRef: senderCompany,
                        ownPersonRef: receiverPerson,
                        ownCompanyRef: receiverCompany,
                    }),
                );
                DialogStore.invalidateSearch({
                    ownPersonRef: senderPerson,
                    ownCompanyRef: senderCompany,
                });
            }
        }),
        map(([order]) => order),
    );
}

export default new InvoiceService();
