import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import OntofoodSelectComponent from 'components/ontofood/OntofoodSelectComponent';
import LevelOfProcessingComponent from 'components/product/LevelOfProcessingComponent';
import { StepOfferComponentProps } from 'components/marketplace/offers/dialog/OfferDialogStructure';
import moment, { Moment } from 'moment';
import 'moment/locale/de';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment as DateAdapter } from '@mui/x-date-pickers/AdapterMoment';
import StyledDatePickerComponent from 'components/shared/Popovers/StyledDatePickerComponent';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ProductType } from 'model/ProductType';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ProductTrait, { ProductTraitType, ProductTraitWithKey, updateProductTraitsWithKey } from 'model/ProductTrait';
import Autocomplete from '@mui/material/Autocomplete';
import { wrapWithTooltip } from 'util/style-helpers';
import { Info } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';
import { getOfferDialogAutoCompleteSX } from 'components/marketplace/offers/dialog/OfferDialogHelpers';
import { OfferDialogFormErrors } from 'components/marketplace/offers/dialog/OfferDialog';

export default interface OfferStepProductInformationProps {
    dateFrom?: Date | null;
    dateEnd?: Date | null;
    productRef?: string;
    productTitle?: string;
    brandName?: string;
    levelsOfProcessing?: string[];
    isPermanent?: boolean;
    productType?: ProductType;
    productTraits?: ProductTraitWithKey[];
    formErrors?: OfferDialogFormErrors;
    limitingProductRef?: string;
}

const useStyles = makeStyles((theme) => ({
    customDisabledTextField: {
        '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: theme.palette.text.primary,
            color: theme.palette.text.primary,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            color: theme.palette.text.primary,
        },
        '& .MuiInputLabel-root.Mui-disabled': {
            color: theme.palette.primary.dark,
        },
        '& .MuiInputLabel-shrink.Mui-disabled': {
            color: theme.palette.primary.dark,
        },
        '& .MuiOutlinedInput-root.Mui-error': {
            '& fieldset': {
                borderColor: theme.palette.error.main,
            },
        },
        '& .MuiFormHelperText-root.Mui-error': {
            color: theme.palette.error.main,
            backgroundColor: 'transparent',
            fontWeight: 'bold',
        },
        '& .MuiInputBase-input.Mui-error': {
            color: theme.palette.error.main,
        },
        '& .MuiInputLabel-root.Mui-error': {
            color: theme.palette.error.main,
        },
    },
    showAllChips: {
        '& .MuiSelect-multiple': {
            whiteSpace: 'normal',
        },
    },
}));
export const OfferStepProductInformation = (props: StepOfferComponentProps<OfferStepProductInformationProps>) => {
    const theme = useTheme();
    const { t } = useTranslation(['offerDialog', 'productTrait', 'common', 'dialogs', 'productDataSheet']);
    const classes = useStyles();

    const momentFrom = props.data.dateFrom === undefined ? null : moment(props.data.dateFrom);
    const momentEnd = props.data.dateEnd === undefined ? null : moment(props.data.dateEnd);

    useEffect(() => {
        if (!moment(props.data.dateFrom).isValid()) {
            props.setData('OfferStepProductInformation', { dateFrom: undefined });
        }
        if (!moment(props.data.dateEnd).isValid()) {
            props.setData('OfferStepProductInformation', { dateEnd: undefined });
        }
    }, []);

    const handleProductTraitTypeChange = (value: ProductTraitType | null, key: number) => {
        const trait = value ?? undefined;
        const productTraits = updateProductTraitsWithKey(props.data.productTraits, key, { trait });
        props.setData('OfferStepProductInformation', { productTraits });
    };

    const handleProductTraitDescriptionChange = (value: string, key: number) => {
        const description = value ?? '';
        const productTraits = updateProductTraitsWithKey(props.data.productTraits, key, { description });
        props.setData('OfferStepProductInformation', { productTraits });
    };

    const handleTraitDelete = (key: number) => {
        const productTraits = props.data.productTraits?.filter((pn) => pn.key !== key);
        props.setData('OfferStepProductInformation', { productTraits });
    };

    const onDateEndChange = (value?: Moment) => {
        props.setData('OfferStepProductInformation', { dateEnd: value?.toDate() });
    };

    const onDateFromChange = (value?: Moment) => {
        props.setData('OfferStepProductInformation', { dateFrom: value?.toDate() });
    };

    const showTitleAndMandatoryHint = (): ReactNode => (
        <Grid container sx={{ paddingBottom: theme.spacing(4) }} justifyContent="space-between">
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('offerDialog:OFFER_STEP_PRODUCT_INFORMATION')}
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>{t('offerDialog:mandatory')}</Typography>
        </Grid>
    );

    const showIsPermanentOfferSwitch = (): ReactNode => {
        function getDefaultDateEndDate() {
            return moment(props.data.dateFrom).add(10, 'days').toDate();
        }

        return (
            <Grid container item justifyContent="flex-end">
                <Grid item>
                    <FormControlLabel
                        labelPlacement="end"
                        control={
                            <Switch
                                onChange={(event) => {
                                    props.setData('OfferStepProductInformation', { isPermanent: event.target.checked });
                                    if (event.target.checked) {
                                        if (
                                            props.data.dateEnd?.toLocaleDateString() ==
                                            getDefaultDateEndDate().toLocaleDateString()
                                        ) {
                                            props.setData('OfferStepProductInformation', { dateEnd: undefined });
                                        }
                                    } else {
                                        if (!props.data.dateEnd) {
                                            props.setData('OfferStepProductInformation', {
                                                dateEnd: getDefaultDateEndDate(),
                                            });
                                        }
                                    }
                                }}
                                checked={props.data.isPermanent}
                                color="primary"
                                data-testid={'suitableForPermanent'}
                                sx={{
                                    transform: 'scale(0.8)',
                                    marginRight: '-10px',
                                }}
                            />
                        }
                        label={t('offerDialog:suitableForPermanent')}
                        sx={{ justifyContent: 'flex-end', width: '100%', marginLeft: '1px' }}
                        componentsProps={{
                            typography: {
                                variant: 'body1',
                                sx: { fontSize: '12px' },
                            },
                        }}
                    />
                </Grid>
            </Grid>
        );
    };

    const showProductTypeSwitch = (): ReactNode => {
        return (
            <FormControlLabel
                labelPlacement="end"
                control={
                    <Switch
                        onChange={(event) =>
                            props.setData('OfferStepProductInformation', {
                                productType: event.target.checked ? ProductType.PROCESSED : ProductType.RAW,
                            })
                        }
                        checked={props.data.productType === ProductType.PROCESSED}
                        color="primary"
                        data-testid={'productType'}
                        sx={{
                            transform: 'scale(0.8)',
                            marginRight: '-10px',
                        }}
                    />
                }
                label={t('offerDialog:productType')}
                sx={{ justifyContent: 'flex-end', width: '100%', marginLeft: '-2px' }}
                componentsProps={{
                    typography: {
                        variant: 'body1',
                        sx: { fontSize: '12px' },
                    },
                }}
            />
        );
    };

    const showDateHint = (): ReactNode => <Typography>{t('offerDialog:dateHint')}</Typography>;

    const showDateFromPicker = (momentFrom: Moment | null): ReactNode => {
        const { showError, helperText } = props.data?.formErrors?.dateFrom || {};
        return (
            <LocalizationProvider dateAdapter={DateAdapter}>
                <StyledDatePickerComponent
                    value={momentFrom}
                    disablePast
                    label={t('offerDialog:dateFrom')}
                    onChange={onDateFromChange}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            error={showError}
                            helperText={showError ? helperText : undefined}
                            sx={{
                                button: {
                                    backgroundColor: 'transparent',
                                },
                                width: '100%',
                            }}
                            required
                        />
                    )}
                />
            </LocalizationProvider>
        );
    };

    const showDateEndPicker = (momentEnd: Moment | null, momentFrom: Moment | null): ReactNode => {
        const { showError, helperText } = props.data?.formErrors?.dateEnd || {};
        return (
            <LocalizationProvider dateAdapter={DateAdapter}>
                <StyledDatePickerComponent
                    value={momentEnd}
                    label={`${t('offerDialog:dateUntil')}`}
                    minDate={momentFrom}
                    onChange={onDateEndChange}
                    renderInput={(properties) => (
                        <TextField
                            {...properties}
                            error={showError}
                            helperText={showError ? helperText : undefined}
                            sx={{
                                button: {
                                    backgroundColor: 'transparent',
                                },
                                width: '100%',
                            }}
                            required={!props.data.isPermanent}
                        />
                    )}
                    disablePast
                />
            </LocalizationProvider>
        );
    };

    const showDatePicker = (): ReactNode => {
        return (
            <Grid item container xs={12}>
                <Grid
                    item
                    container
                    sx={{ marginBottom: theme.spacing(1) }}
                    xs={10.5}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={6}>
                        <Typography sx={{ fontWeight: 600 }}>{t('offerDialog:availability')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {process.env.REACT_APP_NEARBUY_FEATURE_SHOW_RECURRING_ORDER_FEATURE === 'true' &&
                            showIsPermanentOfferSwitch()}
                    </Grid>
                </Grid>
                <Grid item container md={10.5} spacing={1}>
                    <Grid item xs={5.5} sm={6} md={6} lg={6}>
                        {showDateFromPicker(momentFrom)}
                    </Grid>
                    <Grid item xs={5.5} sm={6} md={6} lg={6}>
                        {showDateEndPicker(momentEnd, momentFrom)}
                    </Grid>
                </Grid>
                <Grid item md={10.5} sx={{ marginTop: '12px' }}>
                    {showDateHint()}
                </Grid>
            </Grid>
        );
    };

    const showProductSelect = (): ReactNode => {
        const { showError, helperText } = props.data.formErrors?.category || {};
        return (
            <OntofoodSelectComponent
                autoCompleteStyles={{ width: '80%' }}
                value={props.data.productRef ?? null}
                onChange={(productRef) => {
                    props.setData('OfferStepProductInformation', { productRef });
                }}
                isReadOnly={!props.isNewOffer}
                productType={'market'}
                calledFrom={'OfferStepProductInformation'}
                hasErrors={showError}
                helperText={showError ? helperText : undefined}
                limitingProductRef={props.limitingProductRef}
            />
        );
    };

    const showProductTitle = (): ReactNode => (
        <Grid item md={10.5}>
            <TextField
                inputProps={{ maxLength: 64 }}
                sx={{ width: '100%' }}
                value={props.data.productTitle ?? ''}
                onChange={(event) =>
                    props.setData('OfferStepProductInformation', {
                        productTitle: event.target.value,
                    })
                }
                label={t('offerDialog:productTitleLabel')}
            />
        </Grid>
    );

    const showBrandNameInput = (): ReactNode => (
        <Grid item md={10.5}>
            <TextField
                inputProps={{ maxLength: 56 }}
                sx={{ width: '100%' }}
                value={props.data.brandName ?? ''}
                onChange={(event) =>
                    props.setData('OfferStepProductInformation', {
                        brandName: event.target.value,
                    })
                }
                label={t('offerDialog:brandName')}
            />
        </Grid>
    );

    const showProductDetailsInputs = (): ReactNode => {
        return (
            <Grid item container xs={11}>
                <Grid item xs={11} sx={{ marginTop: '30px', marginBottom: theme.spacing(2) }}>
                    <Typography sx={{ fontWeight: 600 }}>{t('offerDialog:product')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {showProductSelect()}
                </Grid>
                {showProductTitle()}
                {showBrandNameInput()}
            </Grid>
        );
    };
    const showLevelsOfProcessingSelect = (): ReactNode => (
        <Grid item container xs={10.5} sx={{ mt: -2 }}>
            <LevelOfProcessingComponent
                className={classes.showAllChips}
                selectedLopRefs={props.data.levelsOfProcessing ? props.data.levelsOfProcessing : []}
                remove={(ref: string) =>
                    props.setData('OfferStepProductInformation', {
                        levelsOfProcessing: props.data.levelsOfProcessing?.filter((lopRef) => lopRef !== ref),
                    })
                }
                update={(lopRefs: string[]) =>
                    props.setData('OfferStepProductInformation', { ...props.data, levelsOfProcessing: lopRefs })
                }
                helperText={t('offerDialog:processingHint')}
            />
        </Grid>
    );

    const showLevelsOfProcessingSection = (): ReactNode => {
        return (
            <Grid item container sx={{ marginTop: '20px' }}>
                <Grid
                    item
                    container
                    sx={{ marginBottom: theme.spacing(2) }}
                    xs={10.5}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography sx={{ fontWeight: 600 }}>{t('offerDialog:processing')}</Typography>
                    <Grid item>{showProductTypeSwitch()}</Grid>
                </Grid>
                {showLevelsOfProcessingSelect()}
            </Grid>
        );
    };

    const showProductTraitsSection = (): ReactNode => {
        return (
            <Grid item container xs={11}>
                <Grid item xs={11} sx={{ marginTop: '30px', marginBottom: theme.spacing(2) }}>
                    <Typography sx={{ fontWeight: 600 }}>{t('offerDialog:productTraitTitle')}</Typography>
                </Grid>
                {showProductTraitSelectAndInputFields()}
                {showProductTraitAddButton()}
            </Grid>
        );
    };

    const showProductTraitSelectAndInputFields = (): ReactNode => {
        return props.data.productTraits?.map((it, index) => showProductTraitSelectAndInput(it, index));
    };

    const hasIncompleteTrait =
        props.data.productTraits &&
        props.data.productTraits.some((pn) => !pn.productTrait.trait || !pn.productTrait.description);

    const areAllTraitTypesUsed = (props.data.productTraits?.length ?? 0) >= Object.keys(ProductTraitType).length;

    const showProductTraitAddButton = (): ReactNode => (
        <Grid item sx={{ paddingTop: theme.spacing(1) }}>
            <Button
                startIcon={<AddIcon />}
                variant={'contained'}
                data-testid={'addTraitButton'}
                disabled={hasIncompleteTrait || areAllTraitTypesUsed}
                onClick={() => {
                    const productTraits = props.data.productTraits ?? [];
                    productTraits.push({
                        key: Math.random(),
                        productTrait: new ProductTrait(undefined, ''),
                    });
                    props.setData('OfferStepProductInformation', { productTraits });
                    props.setShowAllFormErrors(false);
                }}
            >
                {t('dialogs:ADD')}
            </Button>
        </Grid>
    );

    const showProductTraitSelectAndInput = (traitWithKey: ProductTraitWithKey, index: number): ReactNode => {
        const selectedTraitType: ProductTraitType | null =
            traitWithKey.productTrait.trait !== undefined ? traitWithKey.productTrait.trait : null;
        const options = Object.values(ProductTraitType);
        const isPlantVariety = traitWithKey.productTrait.trait === ProductTraitType.PLANT_VARIETY;

        const formErrorKey = `productTrait_${index}`;
        const { showError, helperText } = props.data.formErrors?.[formErrorKey] || {};

        return (
            <Grid container xs={12} sx={{ alignItems: 'flex-start', alignContent: 'center' }} key={traitWithKey.key}>
                <Grid item md={5} sx={{ marginRight: 3.7 }}>
                    <Autocomplete
                        sx={getOfferDialogAutoCompleteSX()}
                        getOptionLabel={(option) => (option ? t('productTrait:' + option.toString()) : '')}
                        filterOptions={(options) =>
                            options.filter((option) => {
                                const dataIncludesOption =
                                    props.data.productTraits?.map((it) => it.productTrait.trait)?.includes(option) ??
                                    false;
                                return !dataIncludesOption;
                            })
                        }
                        noOptionsText={t('common:noOptions')}
                        options={options}
                        value={selectedTraitType}
                        onChange={(_, newValue) => handleProductTraitTypeChange(newValue, traitWithKey.key)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className={classes.customDisabledTextField}
                                disabled
                                label={t('offerDialog:chooseProductTrait')}
                                fullWidth
                                sx={getOfferDialogAutoCompleteSX()}
                                error={showError && !traitWithKey.productTrait.trait}
                                helperText={showError && !traitWithKey.productTrait.trait ? helperText : undefined}
                            />
                        )}
                    />
                </Grid>
                <Grid
                    item
                    md={isPlantVariety ? 4.5 : 5}
                    sx={isPlantVariety ? { paddingLeft: theme.spacing(0.45) } : undefined}
                >
                    <TextField
                        label={t('offerDialog:productTrait')}
                        value={traitWithKey.productTrait.description ?? ''}
                        onChange={(event) => handleProductTraitDescriptionChange(event.target.value, traitWithKey.key)}
                        fullWidth
                        error={showError && !traitWithKey.productTrait.description}
                        helperText={showError && !traitWithKey.productTrait.description ? helperText : undefined}
                    />
                </Grid>
                {isPlantVariety ? (
                    <Grid item container xs={0.5} height={'100%'} alignItems={'center'} justifyContent={'center'}>
                        {wrapWithTooltip(
                            <Info sx={{ marginLeft: 1.5, color: theme.palette.primary.dark }} />,
                            t('productDataSheet:DATASTEP_INGREDIENTS_TOOLTIP'),
                            undefined,
                            'bottom',
                            t('offerDialog:plantVarietyTooltip'),
                        )}
                    </Grid>
                ) : null}
                <Grid item xs={0.5} sx={{ marginLeft: 2.8, paddingTop: theme.spacing(0.5) }}>
                    <IconButton onClick={() => handleTraitDelete(traitWithKey.key)}>
                        <Delete />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    return props.data ? (
        <Grid container direction={'column'} sx={{ paddingLeft: theme.spacing(5), paddingRight: theme.spacing(5) }}>
            {showTitleAndMandatoryHint()}
            {showDatePicker()}
            {showProductDetailsInputs()}
            {showLevelsOfProcessingSection()}
            {showProductTraitsSection()}
        </Grid>
    ) : null;
};
