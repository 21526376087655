import AddIcon from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import * as React from 'react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ProductTrait, { ProductTraitType, ProductTraitWithKey, updateProductTraitsWithKey } from 'model/ProductTrait';
import makeStyles from '@mui/styles/makeStyles';
import {
    getAutoCompleteTextFieldSX,
    getProductDataSheetDialogAutoCompleteSX,
} from 'components/productdatasheet/ProductDataSheetHelpers';
import { wrapWithTooltip } from 'util/style-helpers';
import { Info } from '@mui/icons-material';

export interface StepProductTraitProps {
    productTraits?: ProductTraitWithKey[];
}

export const StepProductTrait = (props: StepComponentProps<StepProductTraitProps>) => {
    const theme = useTheme();
    const { t } = useTranslation(['dialogs', 'productDataSheet', 'common', 'productTrait']);

    const useStyles = makeStyles({
        customDisabledTextField: {
            '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.text.primary,
                color: theme.palette.text.primary,
            },
            '& .MuiInputBase-root.Mui-disabled': {
                color: theme.palette.text.primary,
            },
            '& .MuiInputLabel-root.Mui-disabled': {
                color: theme.palette.primary.dark,
            },
            '& .MuiInputLabel-shrink.Mui-disabled': {
                color: theme.palette.primary.dark,
            },
        },
    });
    const classes = useStyles();

    const handleTraitTypeChange = (value: ProductTraitType | null, key: number) => {
        const trait = value ?? undefined;
        const productTraits = updateProductTraitsWithKey(props.data.productTraits, key, { trait });
        props.setData('StepProductTrait', { productTraits });
    };

    const handleDescriptionChange = (value: string, key: number) => {
        const description = value ?? '';
        const productTraits = updateProductTraitsWithKey(props.data.productTraits, key, { description });
        props.setData('StepProductTrait', { productTraits });
    };

    const handleDelete = (key: number) => {
        const productTraits = props.data.productTraits?.filter((pn) => pn.key !== key);
        props.setData('StepProductTrait', { productTraits });
    };

    const showTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_PRODUCT_TRAIT_TITLE')}
            </Typography>
        </Grid>
    );

    const hasIncompleteTrait =
        props.data.productTraits &&
        props.data.productTraits.some((pn) => !pn.productTrait.trait || !pn.productTrait.description);

    const areAllTraitTypesUsed = (props.data.productTraits?.length ?? 0) >= Object.keys(ProductTraitType).length;

    const showAddButton = (): ReactNode => (
        <Grid item sx={{ paddingTop: theme.spacing(1) }}>
            <Button
                startIcon={<AddIcon />}
                variant={'contained'}
                disabled={hasIncompleteTrait || areAllTraitTypesUsed}
                onClick={() => {
                    const productTraits = props.data.productTraits ?? [];
                    productTraits.push({
                        key: Math.random(),
                        productTrait: new ProductTrait(undefined, ''),
                    });
                    props.setData('StepProductTrait', { productTraits });
                }}
            >
                {t('dialogs:ADD')}
            </Button>
        </Grid>
    );

    const showText = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography>{t('productDataSheet:DATASTEP_PRODUCT_TRAIT_TEXT')}</Typography>
        </Grid>
    );

    const getSelectAndInput = (traitWithKey: ProductTraitWithKey): ReactNode => {
        const value: ProductTraitType | null =
            traitWithKey.productTrait.trait !== undefined ? traitWithKey.productTrait.trait : null;
        const options = Object.values(ProductTraitType);
        const isPlantVariety = traitWithKey.productTrait.trait === ProductTraitType.PLANT_VARIETY;

        return (
            <Grid
                container
                sx={{ justifyContent: 'space-between', alignItems: 'flex-start', alignContent: 'center' }}
                key={traitWithKey.key}
            >
                <Grid item md={5.5}>
                    <Autocomplete
                        sx={getProductDataSheetDialogAutoCompleteSX()}
                        getOptionLabel={(option) => (option ? t('productTrait:' + option.toString()) : '')}
                        filterOptions={(options) =>
                            options.filter((option) => {
                                const dataIncludesOption =
                                    props.data.productTraits?.map((it) => it.productTrait.trait)?.includes(option) ??
                                    false;
                                return !dataIncludesOption;
                            })
                        }
                        noOptionsText={t('common:noOptions')}
                        options={options}
                        value={value}
                        onChange={(_, newValue) => handleTraitTypeChange(newValue, traitWithKey.key)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className={classes.customDisabledTextField}
                                disabled
                                label={t('productTrait:chooseProductTrait')}
                                fullWidth
                                sx={getAutoCompleteTextFieldSX()}
                            />
                        )}
                    />
                </Grid>
                <Grid
                    item
                    md={isPlantVariety ? 5 : 5.5}
                    sx={
                        isPlantVariety
                            ? {
                                  paddingLeft: theme.spacing(0.45),
                              }
                            : undefined
                    }
                >
                    <TextField
                        label={t('productTrait:productTrait')}
                        value={traitWithKey.productTrait.description ?? ''}
                        onChange={(event) => handleDescriptionChange(event.target.value, traitWithKey.key)}
                        fullWidth
                    />
                </Grid>
                {isPlantVariety ? (
                    <Grid item container xs={0.5} height={'100%'} alignItems={'center'} justifyContent={'center'}>
                        {wrapWithTooltip(
                            <Info sx={{ color: theme.palette.primary.dark }} />,
                            t('productDataSheet:DATASTEP_INGREDIENTS_TOOLTIP'),
                            undefined,
                            'bottom',
                            t('productTrait:plantVarietyTooltip'),
                        )}
                    </Grid>
                ) : null}
                <Grid item sx={{ paddingTop: theme.spacing(0.5) }}>
                    <IconButton onClick={() => handleDelete(traitWithKey.key)}>
                        <Delete />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    const showSelectAndInputFields = (): ReactNode => {
        return props.data.productTraits?.map((it) => getSelectAndInput(it));
    };

    return (
        <Grid item container md={12} sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
            {showTitle()}
            {showText()}
            {showSelectAndInputFields()}
            {showAddButton()}
        </Grid>
    );
};
