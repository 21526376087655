import { StepIconProps } from '@mui/material';
import * as React from 'react';
import { useTheme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import CheckItem from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { OfferStep } from 'components/marketplace/offers/dialog/OfferDialogStructure';

export const OfferDialogVerticalStepComponent = (
    props: { offerStep: OfferStep; idx: number; hasMissingMandatories?: boolean } & StepIconProps,
) => {
    const theme = useTheme();
    const { t } = useTranslation('offerDialog');

    const getIcon = (): React.ReactElement => (
        <Box
            data-testid={props.idx}
            sx={{
                backgroundColor:
                    props.completed && !props.active
                        ? theme.palette.primary.main
                        : props.active
                          ? theme.palette.primary.dark
                          : props.error
                            ? theme.palette.error.main
                            : theme.palette.grey['300'],
                color:
                    props.completed && !props.active
                        ? theme.palette.primary.dark
                        : props.active || props.error
                          ? theme.palette.common.white
                          : theme.palette.grey['500'],
                borderRadius: '50%',
                height: 50,
                width: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {props.completed && !props.active && <CheckItem sx={{ fontSize: 25 }} />}
            {(!props.completed || props.active) && <Typography fontWeight="600">{props.idx}</Typography>}
        </Box>
    );
    return (
        <Grid container alignItems={'center'} gap={3} sx={{ cursor: 'pointer' }}>
            <Grid item>
                <Box display={{ xs: 'block', lg: 'none' }}>
                    <Tooltip title={t(`offerDialog:${props.offerStep.title}`)}>{getIcon()}</Tooltip>
                </Box>
                <Box display={{ xs: 'none', lg: 'block' }}>{getIcon()}</Box>
            </Grid>
            <Grid item display={{ xs: 'none', lg: 'block' }}>
                <Typography fontWeight="600">
                    <Typography fontWeight="600">{t(`offerDialog:${props.offerStep.title}`)}</Typography>
                </Typography>
                {props.completed && (
                    <Typography fontWeight="600" color={theme.palette.primary.dark} fontSize={13}>
                        {t('offerDialog:completed')}
                    </Typography>
                )}
                {props.error && (
                    <Typography fontWeight="600" color={theme.palette.error.main} fontSize={13}>
                        {props.hasMissingMandatories ? t('offerDialog:error') : t('offerDialog:formValidationError')}
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};
