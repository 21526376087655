import { Warehouse } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { RefObject } from 'react';
import { ComponentBase } from 'resub';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { Marker, Popup } from 'react-leaflet';
import { Company } from 'model/Company';
import { CompanyStore, SearchFilterStore, SearchResultStore } from 'store';
import PlaceIcon from '@mui/icons-material/Place';
import { divIcon, Marker as MarkerClass } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { NearbuyRole } from 'model/NearbuyRole';
import MediationIcon from '@mui/icons-material/Mediation';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Theme } from '@mui/material';
import CompanyPopupAdressContent from 'components/search/result-items/company/CompanyPopupAdressContent';
import CompanyProductChips from 'components/search/result-items/company/CompanyProductChips';
import { ResultItem } from 'model/ResultItem';
import { ReactComponent as ProcessorIcon } from 'icons/Windmill_24px.svg';
import { captureWebEvent } from 'util/AnalyticUtils';

const styles = (theme: Theme) =>
    createStyles({
        popup: {
            minWidth: 300,
        },
        productChips: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            alignContent: 'flex-start',
            flexWrap: 'wrap-reverse',
        },
        markerVerified: {
            stroke: theme.palette.common.black,
            strokeWidth: 0.5,
            color: theme.palette.primary.dark,
            borderColor: theme.palette.common.white,
        },
        marker: {
            stroke: theme.palette.common.black,
            strokeWidth: 0.5,
            color: theme.palette.secondary.light,
        },
        markerOwn: {
            stroke: theme.palette.common.black,
            strokeWidth: 0.5,
            color: theme.palette.secondary.main,
        },
        markerConsolidator: {
            stroke: theme.palette.common.black,
            strokeWidth: 0.5,
            color: theme.palette.primary.dark,
        },
        divIcon: {
            border: 'none',
        },
        roleIcon: {
            transform: 'scale(1.2)',
            marginLeft: '0.35em',
            backgroundColor: theme.palette.primary.light,
            borderRadius: '50%',
            '& path': {
                fill: theme.palette.primary.dark,
            },
        },
    });

interface CompanyMapMarkerProperties extends WithStyles<typeof styles> {
    result: ResultItem;
    isInteractive?: boolean;
}

interface CompanyMapMarkerState {
    ownCompany?: Company;
    markerSize: number;
    markerRef: RefObject<MarkerClass<any>>;
    markerRefCurrent: MarkerClass<any> | undefined;
    registered: boolean;
    isOpened: boolean;
}

class CompanyMapMarker extends ComponentBase<CompanyMapMarkerProperties, CompanyMapMarkerState> {
    protected _buildState(
        props: CompanyMapMarkerProperties,
        initialBuild: boolean,
    ): Partial<CompanyMapMarkerState> | undefined {
        const newState: Partial<CompanyMapMarkerState> = {
            ownCompany: CompanyStore.getSelected(),
            markerRef: React.createRef<MarkerClass>(),
        };
        if (initialBuild) {
            newState.registered = false;
        }
        const hoveredItem = SearchResultStore.getHoveredItem();
        newState.markerSize = hoveredItem && this.belongsToMapMarker(hoveredItem) ? 50 : 40;
        return newState;
    }

    shouldComponentUpdate(
        _nextProps: Readonly<CompanyMapMarkerProperties>,
        nextState: Readonly<CompanyMapMarkerState>,
        _nextContext: any,
    ): boolean {
        if (this.state.markerRef.current && !this.state.markerRefCurrent) {
            this.setState({ markerRefCurrent: this.state.markerRef.current });
        }
        if (!this.state.registered) {
            if (this.props.result.company.id && this.state.markerRefCurrent) {
                SearchResultStore.registerResultMapMarker(this.props.result.links.company, this.state.markerRefCurrent);
                this.setState({ registered: true });
            }
        }
        if (this.state.markerRefCurrent?.isPopupOpen()) {
            return false;
        }
        for (const prop of ['company', 'address', 'ownCompany', 'roles'] as (keyof CompanyMapMarkerState)[]) {
            if (!this.state[prop] && nextState[prop]) {
                return true;
            }
        }
        return this.state.markerSize !== nextState.markerSize;
    }

    componentDidMount() {
        super.componentDidMount();
        if (this.state.markerRef.current && !this.state.markerRefCurrent) {
            this.setState({ markerRefCurrent: this.state.markerRef.current });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        if (this.props.result && this.state.registered) {
            SearchResultStore.deRegisterResultMapMarker(this.props.result.links.company);
        }
    }

    belongsToMapMarker(resultItem: ResultItem): boolean {
        return resultItem.links.company === this.props.result.links.company;
    }

    onPopupClose(): void {
        SearchResultStore.setSelectedCompany(undefined);
    }

    getClassName(): string {
        if (!this.props.result || !this.state.ownCompany) {
            return '';
        }

        if (this.state.ownCompany.links.self === this.props.result.links.company) {
            return this.props.classes.markerOwn;
        }

        if (
            this.props.result.roles &&
            this.props.result.roles.includes(NearbuyRole.CONSOLIDATOR) &&
            this.props.result.company.verified
        ) {
            return this.props.classes.markerConsolidator;
        }

        if (this.props.result.company.verified) {
            return this.props.classes.markerVerified;
        }

        return this.props.classes.marker;
    }

    render(): React.ReactElement | null {
        if (!this.props.result || !this.props.result.address || !this.state.ownCompany) {
            return null;
        }
        const marker = renderToStaticMarkup(
            <PlaceIcon
                key={this.props.result.company.id}
                className={this.getClassName()}
                sx={{
                    fontSize: `${this.state.markerSize}px !important`,
                }}
            />,
        );
        const markerIcon = divIcon({
            html: marker,
            iconSize: [40, 40],
            iconAnchor: [this.state.markerSize / 2, this.state.markerSize],
            popupAnchor: [0, -30],
            className: this.props.classes.divIcon,
        });

        return (
            <Marker
                interactive={this.props.isInteractive !== false}
                key={this.props.result.company.id}
                position={[this.props.result.address.lat, this.props.result.address.lon]}
                zIndexOffset={this.state.markerSize === 50 ? 1000 : 0}
                icon={markerIcon}
                ref={this.state.markerRef}
                eventHandlers={{
                    click: () => {
                        captureWebEvent(`click-company-map-marker-from-${SearchFilterStore.getSearchArea()}`);
                        SearchResultStore.setSelectedCompany(this.props.result.links.company);
                        const div = document.getElementsByClassName(this.props.result.company.id);
                        div?.item(0)?.scrollIntoView({ behavior: 'smooth' });
                        if (this.state.markerRefCurrent && !this.state.markerRefCurrent.isPopupOpen()) {
                            SearchResultStore.setSelectedCompany(undefined);
                        }
                    },
                }}
            >
                <Popup
                    key={this.props.result.company.id}
                    onClose={() => this.onPopupClose()}
                    className={this.props.classes.popup}
                >
                    <Typography variant={'h6'}>
                        <Box
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            data-testid="company-marker-popup"
                        >
                            <Grid container>
                                <Grid item md={6}>
                                    {this.props.result.company.name}
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    sx={{
                                        paddingTop: '5px',
                                        justifyContent: 'end',
                                        display: 'flex',
                                    }}
                                >
                                    {this.props.result.roles.includes(NearbuyRole.CONSOLIDATOR) && (
                                        <MediationIcon viewBox="-5 0 30 24" className={this.props.classes.roleIcon} />
                                    )}
                                    {this.props.result.roles.includes(NearbuyRole.SHIPPER) && (
                                        <LocalShippingIcon
                                            viewBox="-3 0 30 24"
                                            className={this.props.classes.roleIcon}
                                        />
                                    )}
                                    {this.props.result.roles.includes(NearbuyRole.PROCESSOR) && (
                                        <ProcessorIcon viewBox="-3 0 30 24" className={this.props.classes.roleIcon} />
                                    )}
                                    {this.props.result.roles.includes(NearbuyRole.WHOLESALER) && (
                                        <Warehouse viewBox="-3 0 30 24" className={this.props.classes.roleIcon} />
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Typography>
                    {this.props.result.links.address && (
                        <CompanyPopupAdressContent address={this.props.result.links.address} />
                    )}
                    <Box className={this.props.classes.productChips}>
                        <CompanyProductChips companyRef={this.props.result.links.company} />
                    </Box>
                </Popup>
            </Marker>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CompanyMapMarker);
