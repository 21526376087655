import { addRequestMessageItemData } from 'components/messaging/chat/messagefactory/messageitemdata/RequestMessageItemData';
import { Request } from 'model/Request';
import React from 'react';
import { ComponentBase } from 'resub';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Person } from 'model/Person';
import { Offer } from 'model/Offer';
import { Product } from 'model/Product';
import MessageItemRenderer from 'components/messaging/chat/messagefactory/MessageItemRenderer';
import { PersonStore, ProductStore, RequestStore } from 'store';
import { MessageItemData } from 'components/messaging/chat/messagefactory/MessageItemData';
import { PreOrderTranslationMapping } from 'components/marketplace/shared/PreOrderTranslationMapping';

interface RequestMessageItemFactoryProps extends WithTranslation {
    baseData: MessageItemData;
}

interface RequestMessageItemFactoryState {
    request?: Request;
    preOrderTranslationMapping?: PreOrderTranslationMapping;
    offer?: Offer;
    sellingPerson?: Person;
    buyingPerson?: Person;
    product?: Product;
}

class RequestMessageItemFactory extends ComponentBase<RequestMessageItemFactoryProps, RequestMessageItemFactoryState> {
    protected _buildState(
        props: RequestMessageItemFactoryProps,
        initialBuild: boolean,
        incomingState: Readonly<RequestMessageItemFactoryState> | undefined,
    ): Partial<RequestMessageItemFactoryState> | undefined {
        const newState: Partial<RequestMessageItemFactoryState> = {
            ...incomingState,
        };

        if (props.baseData.message.links.request) {
            newState.request = RequestStore.getOne(props.baseData.message.links.request);
        }

        if (newState.request && !newState.product)
            newState.product = ProductStore.getOne(newState.request.links.category);

        if (newState.request && !newState.sellingPerson && props.baseData.message.links.senderPerson) {
            newState.sellingPerson = PersonStore.getOne(props.baseData.message.links.senderPerson);
        }

        if (newState.request && !newState.buyingPerson && props.baseData.message.links.receiverPerson) {
            newState.buyingPerson = PersonStore.getOne(props.baseData.message.links.receiverPerson);
        }

        if (
            newState.request &&
            newState.sellingPerson &&
            newState.buyingPerson &&
            newState.product &&
            !newState.preOrderTranslationMapping
        ) {
            newState.preOrderTranslationMapping = {
                firstNameSeller: newState.sellingPerson.firstname,
                lastNameSeller: newState.sellingPerson.lastname,
                firstNameBuyer: newState.buyingPerson.firstname,
                lastNameBuyer: newState.buyingPerson.lastname,
                product: this.props.t(`ontofood:${newState.product.label}`),
            };
        }
        return newState;
    }

    render(): React.ReactElement | null {
        return !this.props.baseData.message.links.request ? (
            <MessageItemRenderer data={{ ...this.props.baseData, isLoaded: true }} />
        ) : (
            (this.state.request && this.state.preOrderTranslationMapping && (
                <MessageItemRenderer
                    data={{
                        ...addRequestMessageItemData(
                            this.props.baseData,
                            this.props.t,
                            this.state.preOrderTranslationMapping,
                        ),
                        isLoaded: true,
                    }}
                />
            )) ?? <MessageItemRenderer data={this.props.baseData} />
        );
    }
}

export default withTranslation('ontofood')(RequestMessageItemFactory);
