import { Grid, Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import RequestService from 'api/RequestService';
import RequestDialog from 'components/marketplace/requests/dialog/RequestDialog';
import DeleteDialog from 'components/shared/DeleteDialog';
import { DeleteIcon, EditIcon } from 'components/shared/Icons';
import { Product } from 'model/Product';
import { Request } from 'model/Request';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { ProductStore, RequestStore } from 'store';
import { captureWebEvent } from 'util/AnalyticUtils';

const styles = (theme: Theme) =>
    createStyles({
        tableRow: {
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.background.default,
            },
        },
        tableCell: {
            whiteSpace: 'pre-wrap',
            border: 'none',
        },
        roundedBtn: {
            padding: theme.spacing(0.5),
            margin: theme.spacing(0.5),
        },
    });

interface RequestTableRowProperties extends WithStyles<typeof styles>, WithTranslation {
    requestRef: string;
    readOnly?: boolean;
    onClick?: () => void;
}

interface RequestTableRowState {
    request?: Request;
    product?: Product;
    isRequestViewOpen: boolean;
    deleteOpen: boolean;
}

class RequestTableRow extends ComponentBase<RequestTableRowProperties, RequestTableRowState> {
    protected _buildState(): Partial<RequestTableRowState> | undefined {
        if (this.props.requestRef) {
            const request = RequestStore.getOne(this.props.requestRef);
            return {
                request: request,
                product: request ? ProductStore.getOne(request.links.category) : undefined,
            };
        }
    }

    deleteRequest(): void {
        captureWebEvent('my_market-confirm-delete-request-button');
        if (this.state.request) {
            this.setState({ isRequestViewOpen: false });
            RequestService.deleteRequest(
                this.state.request.links.self,
                this.state.request.links.remove,
                this.state.request.links.company,
            ).subscribe();
        }
    }

    render(): React.ReactElement | null {
        if (!this.state.request) {
            return null;
        }

        return (
            <>
                <DeleteDialog
                    isOpen={this.state.deleteOpen}
                    quickRemovePhrase
                    onClose={(remove) => {
                        this.setState({ deleteOpen: false });
                        if (remove) {
                            this.deleteRequest();
                        }
                    }}
                    title={this.props.t('offer:removeRequestQuestion')}
                    calledFrom={'RequestTableRow'}
                />
                {this.state.isRequestViewOpen && (
                    <RequestDialog
                        isOpen
                        onClose={(): void => this.setState({ isRequestViewOpen: false })}
                        companyRef={this.state.request.links.company}
                        requestRef={this.state.request.links.self}
                        isReadOnly={false}
                        onDelete={(): void => this.deleteRequest()}
                        calledFrom={'RequestTableRow'}
                    />
                )}
                <TableRow
                    key={this.state.request.links.self}
                    className={this.props.classes.tableRow}
                    sx={
                        this.props.onClick
                            ? {
                                  '&:hover': {
                                      cursor: 'pointer',
                                  },
                              }
                            : undefined
                    }
                >
                    <TableCell className={this.props.classes.tableCell} onClick={this.props.onClick}>
                        <Typography fontWeight={'bold'}>
                            {this.state.product ? this.props.t('ontofood:' + this.state.product.label) : ''}
                        </Typography>
                    </TableCell>
                    <TableCell className={this.props.classes.tableCell} onClick={this.props.onClick}>
                        <Typography>
                            {this.state.request?.totalAmount.toLocaleString('de-DE') +
                                ' ' +
                                this.props.t('shortunits:' + this.state.request.unit)}
                        </Typography>
                    </TableCell>
                    <TableCell className={this.props.classes.tableCell} onClick={this.props.onClick}>
                        <Typography>
                            {(this.state.request.dateFrom
                                ? this.state.request.dateFrom.format('DD.MM.YY')
                                : this.props.t('offer:invalidDate')) +
                                ' - ' +
                                (this.state.request.dateEnd
                                    ? this.state.request.dateEnd.format('DD.MM.YY')
                                    : this.props.t('offer:invalidDate'))}
                        </Typography>
                    </TableCell>
                    <TableCell className={this.props.classes.tableCell} onClick={this.props.onClick}>
                        <Typography>{this.state.request.description ? this.state.request.description : ''}</Typography>
                    </TableCell>
                    <TableCell className={this.props.classes.tableCell}>
                        {!this.props.readOnly && (
                            <Grid container wrap={'nowrap'} justifyContent={'flex-end'}>
                                <IconButton
                                    className={this.props.classes.roundedBtn}
                                    onClick={() => {
                                        captureWebEvent('my_market-edit-requests-button');
                                        this.setState({
                                            isRequestViewOpen: true,
                                        });
                                    }}
                                    size="large"
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    onClick={(
                                        event:
                                            | React.MouseEvent<HTMLAnchorElement>
                                            | React.MouseEvent<HTMLButtonElement>,
                                    ) => {
                                        captureWebEvent('my_market-delete-request-button');
                                        event.ctrlKey || event.metaKey
                                            ? this.deleteRequest()
                                            : this.setState({
                                                  deleteOpen: true,
                                              });
                                    }}
                                    className={this.props.classes.roundedBtn}
                                    size="large"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        )}
                    </TableCell>
                </TableRow>
            </>
        );
    }
}

export default withTranslation(['offer', 'shortunits', 'ontofood'])(
    withStyles(styles, { withTheme: true })(RequestTableRow),
);
