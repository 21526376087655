import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { WithStyles, WithTheme } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import GraduatedPricesComponent from 'components/product/GraduatedPricesComponent';
import { Amount } from 'model/Amount';
import { GraduatedPrice } from 'model/Offer';
import * as React from 'react';
import { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { ComponentBase } from 'resub';
import { FormError } from 'util/FormErrors';
import { OfferStepPriceEditFieldsHandlers } from 'components/marketplace/offers/dialog/steps/OfferStepPricingAndContactDetails';
import HintComponent from 'components/hint/HintComponent';
import { AddRounded } from '@mui/icons-material';
import { theme } from 'style/NearbuyStyle';
import { wrapButtonWithTooltip } from 'util/style-helpers';
import { findDuplicatePositions } from 'components/marketplace/offers/dialog/OfferDialogHelpers';

const styles = (theme: Theme) =>
    createStyles({
        priceUponRequestToggle: {
            paddingLeft: theme.spacing(1),
            marginTop: theme.spacing(1.5),
        },
        hintBox: {
            backgroundColor: '#F3E1AA',
            borderRadius: '18px',
        },
        textField: {
            maxWidth: 200,
        },
    });

export interface PriceEditFieldsProperties extends WithStyles<typeof styles>, WithTheme, WithTranslation {
    offerDialogHandlers: OfferStepPriceEditFieldsHandlers;
    totalAmount: Amount;
    pricePerUnit: number | null;
    pricePerUnitFormError?: FormError;
    graduatedPrices: GraduatedPrice[];
    isPriceHintVisible: boolean;
    isReadOnly?: boolean;
}

class PriceEditFieldsComponent extends ComponentBase<PriceEditFieldsProperties> {
    showAddButton(pricePerUnit: number | null, handlers: OfferStepPriceEditFieldsHandlers): ReactNode {
        return wrapButtonWithTooltip(
            <Button
                onClick={handlers.addGraduatedPrices.bind(handlers)}
                disabled={!pricePerUnit}
                variant="contained"
                color="primary"
                data-testid={'add-graduated-price-button'}
            >
                <AddRounded /> {this.props.t('offer:addGraduatedPrices')}
            </Button>,
            pricePerUnit ? undefined : this.props.t('tooltips:firstEnterBasePrice'),
        );
    }

    showPricePerUnitInput(pricePerUnit: number | null, handlers: OfferStepPriceEditFieldsHandlers): ReactNode {
        return (
            <NumberFormat
                required={true}
                id="pricePerUnit"
                label={this.props.t('offerDialog:basePrice')}
                value={
                    pricePerUnit === 0 || pricePerUnit === null
                        ? undefined
                        : pricePerUnit.toLocaleString('de-DE', {
                              style: 'currency',
                              currency: 'EUR',
                          })
                }
                thousandSeparator={'.'}
                decimalSeparator={','}
                decimalScale={2}
                customInput={TextField}
                InputProps={{ startAdornment: '€\u00A0' }}
                onValueChange={handlers.onPricePerUnitChange.bind(handlers)}
                {...this.props.pricePerUnitFormError}
                inputProps={{
                    readOnly: this.props.isReadOnly,
                }}
                {...this.props.pricePerUnitFormError}
            />
        );
    }

    showPerUnitText(unit: string): ReactNode {
        return <Typography>€ {this.props.t('offer:per') + ' ' + this.props.t(`units:${unit}`)}</Typography>;
    }

    showGraduatedPrices(unit: string, handlers: OfferStepPriceEditFieldsHandlers): ReactNode {
        return this.props.graduatedPrices.map((gp, index) => {
            return (
                <GraduatedPricesComponent
                    graduatedPrice={gp}
                    selectedUnit={unit}
                    onRemove={handlers.removeGraduatedPrice.bind(handlers)}
                    onUpdate={handlers.updateGraduatedPrice.bind(handlers)}
                    readOnly={this.props.isReadOnly ?? false}
                    key={index}
                    isDuplicate={findDuplicatePositions(this.props.graduatedPrices, 'amount').includes(index)}
                />
            );
        });
    }

    showPriceUponRequestSwitch(pricePerUnit: number | null, handlers: OfferStepPriceEditFieldsHandlers): ReactNode {
        return (
            <FormGroup row>
                <FormControlLabel
                    value={pricePerUnit === null}
                    control={
                        <Switch
                            sx={{ transform: 'scale(0.8)' }}
                            checked={pricePerUnit === null}
                            onChange={handlers.priceOnRequestChange.bind(handlers)}
                        />
                    }
                    label={this.props.t('offer:priceUponRequest') as string}
                    labelPlacement="end"
                    className={this.props.classes.priceUponRequestToggle}
                />
            </FormGroup>
        );
    }

    showPriceUponRequestHint(): ReactNode {
        return this.props.isPriceHintVisible ? (
            <div style={{ marginTop: '-20px' }}>
                <HintComponent
                    margin={'0'}
                    isInformationSymbolShown={true}
                    text={this.props.t('offer:priceUponRequestHint')}
                />
            </div>
        ) : null;
    }

    render(): React.ReactElement {
        const offerDialogHandlers = this.props.offerDialogHandlers;
        const pricePerUnit = this.props.pricePerUnit;
        const totalAmount = this.props.totalAmount;

        return (
            <>
                <Grid item container xs={12} justifyContent="space-between" alignItems="center">
                    <Grid item xs={6}>
                        <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
                            {this.props.t('offerDialog:priceDetails')}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={5}
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                        sx={{ marginBottom: 1, marginRight: 2.8 }}
                    >
                        {this.showPriceUponRequestSwitch(pricePerUnit, offerDialogHandlers)}
                    </Grid>
                </Grid>

                {pricePerUnit !== undefined && pricePerUnit !== null && !!offerDialogHandlers && (
                    <>
                        <Grid marginTop={-2} container item>
                            <Grid
                                container
                                spacing={1}
                                style={{
                                    margin: 0,
                                    padding: 0,
                                    alignItems: 'center',
                                }}
                            >
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems={'top'}
                                    spacing={theme.spacing(1)}
                                >
                                    <Grid
                                        item
                                        xs={3}
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                        }}
                                    >
                                        {this.showPricePerUnitInput(pricePerUnit, offerDialogHandlers)}
                                    </Grid>
                                    <Grid item xs={6} marginTop={0.8}>
                                        {this.showPerUnitText(totalAmount.unit)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {!!this.props.graduatedPrices.length &&
                            this.showGraduatedPrices(totalAmount.unit, offerDialogHandlers)}
                        <Grid item md={6} sx={{ marginTop: 1 }}>
                            {this.showAddButton(pricePerUnit, offerDialogHandlers)}
                        </Grid>
                    </>
                )}
                <Grid container item style={{ margin: 0, padding: 0 }} md={12} alignItems="flex-start">
                    <Grid
                        container
                        item
                        md={12}
                        alignItems="flex-start"
                        style={!this.props.isPriceHintVisible ? { marginTop: '0' } : { marginTop: '1rem' }}
                        className={this.props.classes.hintBox}
                    >
                        {this.showPriceUponRequestHint()}
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withTranslation(['offer', 'units', 'offerDialog', 'tooltips'])(
    withStyles(styles, { withTheme: true })(PriceEditFieldsComponent),
);
