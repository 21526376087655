import * as React from 'react';
import { ComponentBase } from 'resub';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ExpiringRequestSystemMessage } from 'model/SystemMessage';
import SystemMessageItem from 'components/systemmessage/items/standard/SystemMessageItem';
import { ProductStore, RequestStore } from 'store';
import { Request } from 'model/Request';
import RequestService from 'api/RequestService';
import RequestDialog from 'components/marketplace/requests/dialog/RequestDialog';

interface ExpiringRequestSystemMessageItemProps extends WithTranslation {
    systemMessage: ExpiringRequestSystemMessage;
}

interface ExpiringRequestSystemMessageItemMapping extends Record<string, string> {
    request: string;
}

interface ExpiringRequestSystemMessageItemState {
    mapping?: ExpiringRequestSystemMessageItemMapping;
    request?: Request;
    editDialogOpen?: boolean;
}

class ExpiringRequestSystemMessageItem extends ComponentBase<
    ExpiringRequestSystemMessageItemProps,
    ExpiringRequestSystemMessageItemState
> {
    protected _buildState(
        props: ExpiringRequestSystemMessageItemProps,
        initialBuild: boolean,
        incomingState: Readonly<ExpiringRequestSystemMessageItemState>,
    ): Partial<ExpiringRequestSystemMessageItemState> | undefined {
        const request = RequestStore.getOne(props.systemMessage.links.request);
        const product = request ? ProductStore.getOne(request.links.category) : undefined;

        if (product) {
            return {
                request: RequestStore.getOne(props.systemMessage.links.request),
                mapping: {
                    request: props.t('ontofood:' + product.label),
                },
            };
        }
    }

    openEditDialog(): void {
        this.setState({ editDialogOpen: true });
    }

    closeEditDialog(): void {
        this.setState({ editDialogOpen: false });
    }

    render(): React.ReactElement | null {
        if (!this.state.mapping || !this.state.request) return null;

        return (
            <>
                {this.state.editDialogOpen && (
                    <RequestDialog
                        companyRef={this.state.request.links.company}
                        requestRef={this.state.request.links.self}
                        isOpen
                        onClose={() => {
                            this.closeEditDialog();
                        }}
                        onDelete={() => {
                            if (this.state.request) {
                                RequestService.deleteRequest(
                                    this.state.request.links.remove,
                                    this.state.request.links.self,
                                    this.state.request.links.company,
                                ).subscribe(() => {
                                    this.closeEditDialog();
                                });
                            }
                        }}
                        isReadOnly={false}
                        calledFrom={'systemMessage'}
                    />
                )}
                <SystemMessageItem
                    onClick={() => this.openEditDialog()}
                    systemMessage={this.props.systemMessage}
                    mapping={this.state.mapping}
                />
            </>
        );
    }
}

export default withTranslation('ontofood')(ExpiringRequestSystemMessageItem);
