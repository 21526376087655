import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import UnitSelectComponent from 'components/marketplace/shared/UnitSelectComponent';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import { AmountRange } from 'model/AmountRange';
import { getUnitFromString, Unit, UnitType } from 'model/Unit';
import * as React from 'react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { wrapButtonWithTooltip } from 'util/style-helpers';

export default interface StepDimensionAndWeightProps {
    productUnit?: Unit;
    caliber?: AmountRange;
    weight?: AmountRange;
    areContainersSet?: boolean;
}

export const StepDimensionAndWeight = (props: StepComponentProps<StepDimensionAndWeightProps>) => {
    const theme = useTheme();
    const { t } = useTranslation(['units', 'productDataSheet', 'tooltips']);

    const showTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_DIMENSION_AND_WEIGHT_TITLE')}
            </Typography>
        </Grid>
    );

    const showUnitSelect = (): ReactNode => (
        <Grid container>
            {wrapButtonWithTooltip(
                <UnitSelectComponent
                    selectedUnit={props.data.productUnit}
                    onChange={(unit: Unit) => {
                        props.setData('StepDimensionAndWeight', {
                            productUnit: unit,
                        });
                    }}
                    isReadOnly={props.data.areContainersSet ?? false}
                />,
                props.data.areContainersSet ? t('tooltips:noUnitEditIfContainersAreSet') : '',
                'top-start',
                'unit-select',
                { width: '100%' },
            )}
        </Grid>
    );

    const showCaliberInput = (): ReactNode => {
        return props.data.productUnit && props.data.productUnit.unitType !== UnitType.VOLUME ? (
            <Grid item container md={12}>
                <Grid item md={12} sx={{ paddingTop: theme.spacing(1) }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '16' }}>
                        {t('productDataSheet:DATASTEP_DIMENSION_AND_WEIGHT_CALIBER')}
                    </Typography>
                </Grid>
                <Grid item md={4} sx={{ display: 'grid', paddingRight: theme.spacing(1.5) }}>
                    <NumberFormat
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={2}
                        customInput={TextField}
                        value={props.data.caliber?.min}
                        onValueChange={({ floatValue }) =>
                            props.setData('StepDimensionAndWeight', {
                                caliber: {
                                    ...props.data.caliber,
                                    min: floatValue,
                                },
                            })
                        }
                        label={t('productDataSheet:from')}
                    />
                </Grid>
                <Grid item md={4} sx={{ display: 'grid', paddingRight: theme.spacing(1.5) }}>
                    <NumberFormat
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={2}
                        customInput={TextField}
                        value={props.data.caliber?.max}
                        onValueChange={({ floatValue }) =>
                            props.setData('StepDimensionAndWeight', {
                                caliber: {
                                    ...props.data.caliber,
                                    max: floatValue,
                                },
                            })
                        }
                        label={t('productDataSheet:until')}
                    />
                </Grid>
                <Grid item md={4}>
                    <UnitSelectComponent
                        selectedUnit={getUnitFromString(props.data.caliber?.unit)}
                        onChange={({ unit }) => {
                            props.setData('StepDimensionAndWeight', {
                                caliber: {
                                    ...props.data.caliber,
                                    unit,
                                },
                            });
                        }}
                        conversationFactorToLimitTo={getUnitFromString('Centimeter')?.conversionFactor}
                        typeToLimitTo={UnitType.LENGTH}
                    />
                </Grid>
            </Grid>
        ) : null;
    };

    const showWeightInput = (): ReactNode => {
        return props.data.productUnit && props.data.productUnit.unitType !== UnitType.VOLUME ? (
            <Grid item container md={12}>
                <Grid item md={12} sx={{ paddingTop: theme.spacing(1) }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '16' }}>
                        {t('productDataSheet:DATASTEP_DIMENSION_AND_WEIGHT_WEIGHT_PER_PIECE')}
                    </Typography>
                </Grid>
                <Grid item md={4} sx={{ display: 'grid', paddingRight: theme.spacing(1.5) }}>
                    <NumberFormat
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={2}
                        customInput={TextField}
                        value={props.data.weight?.min}
                        onValueChange={({ floatValue }) =>
                            props.setData('StepDimensionAndWeight', {
                                weight: {
                                    ...props.data.weight,
                                    min: floatValue,
                                },
                            })
                        }
                        label={t('productDataSheet:from')}
                    />
                </Grid>
                <Grid item md={4} sx={{ display: 'grid', paddingRight: theme.spacing(1.5) }}>
                    <NumberFormat
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={2}
                        customInput={TextField}
                        value={props.data.weight?.max}
                        onValueChange={({ floatValue }) =>
                            props.setData('StepDimensionAndWeight', {
                                weight: {
                                    ...props.data.weight,
                                    max: floatValue,
                                },
                            })
                        }
                        label={t('productDataSheet:until')}
                    />
                </Grid>
                <Grid item md={4}>
                    <UnitSelectComponent
                        selectedUnit={getUnitFromString(props.data.weight?.unit)}
                        onChange={({ unit }) => {
                            props.setData('StepDimensionAndWeight', {
                                weight: {
                                    ...props.data.weight,
                                    unit,
                                },
                            });
                        }}
                        conversationFactorToLimitTo={getUnitFromString('Kilogram')?.conversionFactor}
                        typeToLimitTo={UnitType.WEIGHT}
                    />
                </Grid>
            </Grid>
        ) : null;
    };

    return (
        <Grid container sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
            {showTitle()}
            {showUnitSelect()}
            {showCaliberInput()}
            {showWeightInput()}
        </Grid>
    );
};
