import * as React from 'react';
import { ReactElement } from 'react';
import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ComponentBase } from 'resub';
import { Message } from 'model/Message';
import { CompanyStore, MessageStore, PersonImagesStore, PersonStore, SubstitutePersonStore } from 'store';
import { Company } from 'model/Company';
import { Person } from 'model/Person';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import MessageHeader from 'components/messaging/MessageHeader';
import { getUuidFromString } from 'util/helpers';
import { Subscription } from 'rxjs';
import { SubstitutePerson } from 'model/SubstitutePerson';
import { DialogStore } from 'store/DialogStore';
import { classNames } from 'util/style-helpers';
import { Dialog, getDialogId } from 'model/Dialog';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = (theme: Theme) =>
    createStyles({
        avatar: {
            flexBasis: 0,
            paddingRight: theme.spacing(1.5),
        },
        container: {
            borderRadius: '8px',
            cursor: 'pointer',
            padding: theme.spacing(1.5),
            '&:hover': {
                color: theme.palette.getContrastText(theme.palette.primary.light),
                backgroundColor: theme.palette.primary.light,
            },
        },
        flexChild: {
            minWidth: 0,
        },
        selected: {
            color: theme.palette.getContrastText(theme.palette.primary.dark),
            borderRadius: '8px',
            backgroundColor: theme.palette.primary.dark,
        },
    });

interface MessagePreviewItemProps extends WithStyles<typeof _styles> {
    dialog: Dialog;
}

interface MessagePreviewItemState {
    isDialogSelected?: boolean;
    isDialogUnseen?: boolean;
    message?: Message;
    otherCompany?: Company | null;
    otherPerson?: Person | SubstitutePerson | null;
    seenSubscription?: Subscription;
    avatarSrc?: string;
}

export class MessagePreviewItem extends ComponentBase<MessagePreviewItemProps, MessagePreviewItemState> {
    protected _buildState(
        props: MessagePreviewItemProps,
        initialBuild: boolean,
        incomingState?: Readonly<MessagePreviewItemState>,
    ): Partial<MessagePreviewItemState> | undefined {
        const newState: Partial<MessagePreviewItemState> = {};

        const dialogSelected = DialogStore.getSelected();

        if (dialogSelected) {
            newState.isDialogSelected =
                dialogSelected.otherCompanyRef === this.props.dialog.otherCompanyRef &&
                dialogSelected.otherPersonRef === this.props.dialog.otherPersonRef &&
                dialogSelected.ownCompanyRef === this.props.dialog.ownCompanyRef &&
                dialogSelected.ownCompanyRef === this.props.dialog.ownCompanyRef;
        }

        if (this.props.dialog.latestMessageRef) {
            newState.isDialogUnseen = MessageStore.getOne(this.props.dialog.latestMessageRef)?.seen === false;
        }

        newState.otherCompany = this.props.dialog.otherCompanyRef
            ? CompanyStore.getOne(this.props.dialog.otherCompanyRef)
            : null;
        if (!this.props.dialog.otherPersonRef) {
            newState.otherPerson = null;
        } else {
            // TODO: this doesn't work, as not all substitute persons are loaded all the time.
            //  But it will be changed if substitute messages will be handled fully seperated
            // The above to do is regarding this solution: SubstitutePersonStore.hasOne(this.props.dialog.otherPersonRef)
            newState.otherPerson = this.props.dialog.otherPersonRef.includes('substitute')
                ? SubstitutePersonStore.getOne(this.props.dialog.otherPersonRef)
                : PersonStore.getOne(this.props.dialog.otherPersonRef);
        }
        //Load last message with entity
        if (newState.otherCompany !== undefined && newState.otherPerson !== undefined) {
            if (newState.otherCompany != null && newState.otherPerson != null && this.props.dialog.latestMessageRef) {
                newState.message = MessageStore.getOne(this.props.dialog.latestMessageRef);
            }
        }

        //Load Information about entity
        const entity = newState.otherPerson ?? newState.otherCompany;
        const id = getUuidFromString(entity?.links.self);

        if (id) {
            newState.avatarSrc = PersonImagesStore.getOne(id)?.getLatestImage()?.source;
        }

        return newState;
    }

    getProfileImage(): ReactElement {
        if (this.state.avatarSrc) {
            return <Avatar src={this.state.avatarSrc} style={{ height: '40px', width: '40px' }} />;
        }

        return (
            <Avatar style={{ height: '40px', width: '40px' }}>
                <PersonIcon />
            </Avatar>
        );
    }

    navigateToMessage(): void {
        DialogStore.setSelected(getDialogId(this.props.dialog));
    }

    render(): React.ReactElement | null {
        if (!this.state.message) {
            return null;
        }
        return (
            <Grid
                container
                onClick={() => {
                    captureWebEvent('switch-chat');
                    this.navigateToMessage();
                }}
                className={classNames(
                    this.props.classes.container,
                    this.state.isDialogSelected ? this.props.classes.selected : undefined,
                )}
                wrap={'nowrap'}
            >
                <Grid container item alignItems={'center'} className={this.props.classes.avatar}>
                    {this.getProfileImage()}
                </Grid>
                <Grid item container className={this.props.classes.flexChild}>
                    <MessageHeader
                        company={this.state.otherCompany ?? undefined}
                        person={this.state.otherPerson ?? undefined}
                        isUnseen={this.state.isDialogUnseen}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(MessagePreviewItem);
